import React from "react";
import { View, Text, StyleSheet } from "react-native";
import { Ionicons, FontAwesome5, MaterialCommunityIcons, MaterialIcons, Fontisto, SimpleLineIcons, Feather, FontAwesome } from '@expo/vector-icons';

const getAmenityName = (amenity) => {
        switch (amenity) {
          case 'Wifi':
            return 'WIFI';
          case 'TV':
            return 'TV';
          case 'AC':
            return 'AC';
          case 'GPS':
            return 'GPS';
          case 'RESTROOM':
            return 'Toilet';
          case 'POWER_OUTLET':
            return 'Charging Point';
          case 'RECLINING_SEATS':
            return 'Recliners';
          case 'USB_CHARGING':
            return 'USB';
          case 'EAR_PHONE':
            return 'Ear Phone';
          case 'HEAD_SET':
            return 'Head Set';
          case 'FAN':
            return 'Fan';
          case 'READING_LIGHTS':
            return 'Reading Lights';
          case 'SEAT_POCKETS':
            return 'Seat Pockets';
          case 'CUP_HOLDER':
            return 'Cup Holder';
          case 'OVERHEAD_STORAGE':
            return 'Overhead Storage';
          case 'LUGGAGE_COMPARTMENT':
            return 'Luggage Compartment';
          case 'ENTERTAINMENT_SYSTEM':
            return 'Entertainment';
          case 'FIRE_EXTINGUISHER':
            return 'Fire Extinguisher';
          case 'FOOD_BEVERAGE':
            return 'Food & Beverage';
          case 'LARGE_WINDOWS':
            return 'Large Windows';
          case 'PRIVACY_CURTAINS':
            return 'Privacy Curtains';
          case 'EMERGENCY_EXIT':
            return 'Emergency Exit';
          case 'MAGAZINE_RACKS':
            return 'Magzine Racks';
          case 'PILLOW':
            return 'pillow';
          case 'BLANKET':
            return 'Blanket';
          default:
            return amenity;
        }
};

const AmenityIcon = ({ amenity, size, color  }) => {
  const getIconName = (amenity) => {
    switch (amenity) {
        case 'WIFI':
          return { name: 'wifi', library: 'Ionicons' };
        case 'TV':
          return { name: 'tv', library: 'Ionicons' };
        case 'AC':
          return { name: 'snow', library: 'Ionicons' };
        case 'GPS':
          return { name: 'gps-fixed', library: 'Materialicons' };
        case 'RESTROOM':
          return { name: 'restroom', library: 'FontAwesome5' };
        case 'POWER_OUTLET':
          return { name: 'outlet', library: 'Materialicons' };
        case 'RECLINING_SEATS':
          return { name: 'seat-recline-extra', library: 'MaterialCommunityicons' };
        case 'USB_CHARGING':
          return { name: 'usb', library: 'Fontiso' };
        case 'EAR_PHONE':
          return { name: 'earphones', library: 'SimpleLineicons' };
        case 'HEAD_SET':
          return { name: 'headset', library: 'Ionicons' };
        case 'FAN':
          return { name: 'fan', library: 'FontAwesome5' };
        case 'READING_LIGHTS':
          return { name: 'lightbulb-on', library: 'MaterialCommunityicons' };
        case 'SEAT_POCKETS':
          return { name: 'pocket', library: 'Feather' };
        case 'CUP_HOLDER':
          return { name: 'cup', library: 'MaterialCommunityicons' };
        case 'OVERHEAD_STORAGE':
          return { name: 'navigate-circle', library: 'Ionicons' };
        case 'LUGGAGE_COMPARTMENT':
          return { name: 'navigate-circle', library: 'Ionicons' };
        case 'ENTERTAINMENT_SYSTEM':
          return { name: 'navigate-circle', library: 'Ionicons' };
        case 'FIRE_EXTINGUISHER':
          return { name: 'fire-extinguisher', library: 'FontAwesome' };
        case 'FOOD_BEVERAGE':
          return { name: 'fast-food-outline', library: 'Ionicons' };
        case 'LARGE_WINDOWS':
          return { name: 'navigate-circle', library: 'Ionicons' };
        case 'PRIVACY_CURTAINS':
          return { name: 'curtains', library: 'MaterialCommunityicons' };
        case 'EMERGENCY_EXIT':
          return { name: 'exit-run', library: 'MaterialCommunityicons' };
        case 'MAGAZINE_RACKS':
          return { name: 'book-open', library: 'Feather' };
        default:
          return { name: 'information-circle', library: 'Ionicons' };
      }
  };

  const getIconComponent = (amenity) => {
    const { name, library } = getIconName(amenity);
    switch (library) {
      case 'FontAwesome5':
        return <FontAwesome5 name={name} size={size} color={color}/>;
      case 'Ionicons':
        return <Ionicons name={name} size={size} color={color}/>;
      case 'MaterialCommunityicons':
        return <MaterialCommunityIcons name={name} size={size} color={color} />;
      case 'Materialicons':
        return <MaterialIcons name={name} size={size} color={color}/>;
      case 'Fontiso':
        return <Fontisto name={name} size={size} color={color} />;
      case 'SimpleLineicons':
        return <SimpleLineIcons name={name} size={size} color={color}/>;
      case 'Feather':
        return <Feather name={name} size={size} color={color} />;
      case 'FontAwesome':
        return <FontAwesome name={name} size={size} color={color}/>;
      default:
        return null;
    }
  };

  return (
    <View style={styles.shortFeature}>
      {getIconComponent(amenity)}
      <Text style={styles.amenityShortName}>{getAmenityName(amenity)}</Text>
    </View>
  );
};

const AmenityName = ({ amenity }) => {
  return <Text style={styles.amenityName}>{getAmenityName(amenity)}</Text>;
};

const styles = StyleSheet.create({
  shortFeature: {
    alignItems: 'center', // Center items horizontally
    margin: 5,
  },
  amenityShortName: {
    fontSize: 8,
    textAlign: 'center',
    //fontFamily: 'Aldrich'
  },
  amenityName: {
    margin: 5,
    fontSize: 8,
    //fontFamily: 'Aldrich'
  },
});

export { AmenityIcon, AmenityName };
