import React, { useState, useEffect, useRef } from "react";
import { View, FlatList, Text, StyleSheet, TouchableOpacity, Animated, Modal, Dimensions } from "react-native";
import RatingStars from "./RatingStars";
import BusDetailsModal from "../modals/BusDetailsModal";
import { AmenityIcon } from "../common/FeatureComponent";
import SeatArrangement from "./SeatArrangement";
import { MaterialCommunityIcons, SimpleLineIcons } from '@expo/vector-icons';
import moment from 'moment';
import axios from "axios";
import { isMobile, isBrowser } from 'react-device-detect';
import { format } from 'date-fns';
import Constants from 'expo-constants';

const BUS_AVAILABILITY_URL = Constants.expoConfig.extra.apiUrl;
const API_TOKEN = Constants.expoConfig.extra.apiToken;

const BusAvailabilityResults = ({ busAvailabilities, bookSummary, handleBookSummaryChange, onBook }) => {
    const [seatArrangement, setSeatArrangement] = useState(null);
    const [selectedBus, setSelectedBus] = useState(null);
    const [selectedSeats, setSelectedSeats] = useState([]);
    const [showBusDetails, setShowBusDetails] = useState(false);
    const [activeTab, setActiveTab] = useState('Features');
    const [showSeatArrangement, setShowSeatArrangement] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showTotalCostForItem, setShowTotalCostForItem] = useState({});
    const [ratings, setRatings] = useState({});
    const [serviceInfo, setSericeInfo] = useState({});

    const colorValue = useRef(new Animated.Value(0)).current;

    useEffect(() => {
      const animateColor = () => {
        Animated.loop(
          Animated.sequence([
            Animated.timing(colorValue, {
              toValue: 1,
              duration: 1000,
              useNativeDriver: false,
            }),
            Animated.timing(colorValue, {
              toValue: 0,
              duration: 1000,
              useNativeDriver: false,
            }),
          ])
        ).start();
      };
  
      animateColor();
    }, [colorValue]);
  
    const interpolatedColor = colorValue.interpolate({
      inputRange: [0, 1],
      outputRange: ['white', 'red'], // Change 'red' to your desired color
    });

    const handleCloseBusDetailModal = () => {
        setActiveTab(null);
        setShowBusDetails(false);
    }

    const handleDetailClickButton = (item, detail) => {
        setActiveTab(detail);
        setSelectedBus(item);
        setShowBusDetails(true);
    }

    const handleShowSeatArrangement = (busAvailability) => {
        setLoading(true);
        setShowSeatArrangement(true);
        setSelectedBus(busAvailability);
        setSelectedSeats([]);
        const updatedBookSummary = { ...bookSummary, busAvailability: busAvailability, seats:[] };
        handleBookSummaryChange(updatedBookSummary);
        handleSeatAvailability(busAvailability); // Fetch seat arrangement data
        setLoading(false);
    }

    const getServiceInfo = async (serviceIdentifier) => {
        try {
            const response = await axios.get(BUS_AVAILABILITY_URL + '/businfo/' + serviceIdentifier , {
                headers: {
                    'x-api-token': API_TOKEN
                }});
            return response.data;
        } catch (error) {
            console.log(error);
            return null;
        }
    };

    const fetchAllServiceInfo = async () => {
        const serviceInfoMap = {};
        for (const item of busAvailabilities) {
            if (item.serviceIdentifier) {
                const serviceInfo = await getServiceInfo(item.serviceIdentifier);
                serviceInfoMap[item.serviceIdentifier] = serviceInfo;
            }
        }
        setSericeInfo(serviceInfoMap);
    };

    useEffect(() => {
        fetchAllServiceInfo();
    }, [busAvailabilities]);

    const handleSeatAvailability = async (busAvailability) => {
        setSeatArrangement(null);
        console.log("Fetching Seat Availability");

        try {
            const formattedDate = format(bookSummary.travelDate, 'yyyy-MM-dd');
            const response = await axios.get(BUS_AVAILABILITY_URL + '/seatavailability', {
                params: {
                    tripIdentifier: busAvailability.tripIdentifier,
                    origin: bookSummary.origin,
                    destination: bookSummary.destination,
                    travelDate: formattedDate,
                    operator: busAvailability.vendorCode,
                },  
                headers: {
                    'x-api-token': API_TOKEN
                }
            });
            console.log(response);
            setSeatArrangement(response.data);
        } catch (error) {
            console.error(error);
        }
    };

    const handleCloseSeatAlignment = () => {
        setSelectedBus(null);
        setSelectedSeats([]);
        setShowSeatArrangement(false);

        const updatedBookSummary = { ...bookSummary, busAvailability: null, seats: [] };
        handleBookSummaryChange(updatedBookSummary);
    }

    const toggleSeatAlignment = (busAvailability) => {
        if(showSeatArrangement) {
           handleCloseSeatAlignment();
        } else {
            handleShowSeatArrangement(busAvailability);
        }
    }

    const handleSeatClick = (selectedSeat) => {
        setSelectedSeats((prevSelectedSeats) => {
            const isSeatSelected = prevSelectedSeats.some((seat) => seat.seatNo === selectedSeat.seatNo);
            let updatedSelectedSeats;
            if (isSeatSelected) {
                updatedSelectedSeats = prevSelectedSeats.filter((seat) => seat.seatNo !== selectedSeat.seatNo);
            } else {
                updatedSelectedSeats = [...prevSelectedSeats, selectedSeat];
            }

            const updatedBookSummary = { ...bookSummary, seats: updatedSelectedSeats , totalCost: calculateEstimatedTotalCost(updatedSelectedSeats)};
            handleBookSummaryChange(updatedBookSummary);

            console.log("selected Seats:", bookSummary.seats);

            return updatedSelectedSeats;
        });
    };

    const calculateEstimatedTotalCost = (seats) => {
        let totalCost = 0;
        seats.forEach((seat) => {
            totalCost += seat.seatRate.totalCost;
        });
        return totalCost;
    };

    const getSeatType = (seatType) => {
        switch (seatType) {
            case 'REGULAR':
                return 'Regular Seat';
            case 'SINFLE_REGULAR':
                return 'Single Regular Seat';
            case 'PUSHBACK':
                return 'Pushback';
            case 'SEMI_SLEEPER':
                return 'Semi Sleeper';
            case 'SLEEPER':
                return 'Sleeper';
            case 'SINGLE_LOWER_SLEEPER':
                return 'Single Lower Sleeper';
            case 'SINGLE_UPPER_SLEEPER':
                return 'Single Upper Sleeper';
            case 'LOWER_SLEEPER':
                return 'Lower Sleeper';
            case 'UPPER_SLEEPER':
                return 'Upper Sleeper';
            default:
                return 'Seat';
        }
    };

    const formatTime = (timeArray) => {
        const arrivalTime = new Date(timeArray[0], timeArray[1] - 1, timeArray[2], timeArray[3], timeArray[4]);
        return arrivalTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    };

    const handleToggleTotalCost = (item) => {
        setShowTotalCostForItem(prevState => ({
            ...prevState,
            [item.tripIdentifier]: !prevState[item.tripIdentifier]
        }));
    };

    const renderItem = ({ item }) => (

        <View>
            <View style={styles.busAvailabilityContainer}>
            {serviceInfo[item.serviceIdentifier] && serviceInfo[item.serviceIdentifier].isPopular && (
                <View style={styles.popularBand}>
                <Text style={styles.popularText}>Popular</Text>
                </View>
             )}
                <View style={styles.leftContainer}>
                    <Text style={styles.operator}>{item.operator}</Text>
                    <Text style={styles.bus}>{item.bus.name}</Text>
                    {item.serviceIdentifier && (
                        <RatingStars rating={serviceInfo[item.serviceIdentifier] ? serviceInfo[item.serviceIdentifier].overallRating : 0} />
                    )}
                    <View style={styles.amenitiesContainer}>
                        {item.amenities && item.amenities.slice(0, 4).map((amenity, index) => (
                            <AmenityIcon key={index} amenity={amenity} size={12} color="black"/>
                        ))}
                        {item.amenities && item.amenities.length > 4 && (
                            <TouchableOpacity onPress={() => handleDetailClickButton(item, 'Features')}>
                                <Text style={styles.showAllFeatures}>Show All </Text>
                            </TouchableOpacity>
                        )}
                    </View>
                </View>
                <View style={styles.middleContainer}>
                    <View style={styles.originDestinationContainer}>
                        <View style={styles.originContainer}>
                            <Text style={styles.locationText}>{item.origin}</Text>
                            <Text style={styles.timeText}>{formatTime(item.departureTime)}</Text>
                        </View>
                        <View style={styles.durationContainer}>
                            <MaterialCommunityIcons name="bus-side" size={16} color="#5EBC67" />
                            <Text style={styles.dottedLine}>-----</Text>
                            <Text style={styles.durationText}> {item.travelTime}</Text>
                            <Text style={styles.dottedLine}>-----</Text>
                            <MaterialCommunityIcons name="bus-side" size={16} color="#5EBC67" />
                        </View>
                        <View style={styles.destinationContainer}>
                            <Text style={styles.locationText}>{item.destination}</Text>
                            <Text style={styles.timeText}>{formatTime(item.arrivalTime)}</Text>
                        </View>
                    </View>
                    <View style={styles.detailsContainer}>
                        <TouchableOpacity style={styles.detailButton} onPress={() => handleDetailClickButton(item, 'Cancel Policy')}>
                            <Text style={styles.text}>Cancel Policy</Text>
                        </TouchableOpacity>
                        <TouchableOpacity style={styles.detailButton} onPress={() => handleDetailClickButton(item, 'Travel Policy')}>
                            <Text style={styles.text}>Travel Policy</Text>
                        </TouchableOpacity>
                        <TouchableOpacity style={styles.detailButton} onPress={() => handleDetailClickButton(item, 'Boarding DropOff Points')}>
                            <Text style={styles.text}>Boarding/DropOff Points</Text>
                        </TouchableOpacity>
                    </View>
                </View>
                <View style={styles.rightContainer}>
                   
                    <View style={styles.seatTicketFares}>
                        {item.seatTicketFares && item.seatTicketFares.length > 0 && (
                            item.seatTicketFares.map((seatTicketFare, index) => (
                                <View style={styles.priceContainer} key={index}>
                                    <Text style={styles.seatType}>{getSeatType(seatTicketFare.seatType)}</Text>
                                    <Text style={styles.availableCount}>Available {seatTicketFare.availableSeatCount}</Text>
                                    <Text style={styles.ticketFare}>
                                       @ ₹ {seatTicketFare.ticketFare.baseFare}
                                    </Text>
                                    {seatTicketFare.ticketFare.discount && (
                                         <Text style={styles.discountFare}>
                                          ₹ {seatTicketFare.ticketFare.discountedBaseFare}
                                      </Text>
                                    )}
                                    {showTotalCostForItem[item.tripIdentifier] && (
                                        <Text style={styles.taxInclusiveExclusive}>
                                             ₹ {seatTicketFare.ticketFare.totalCost} Incl Taxes
                                      </Text>
                                    )}
                                </View>
                            ))
                        )}
                    </View>
                    {!showTotalCostForItem[item.tripIdentifier] && (
                    <View>
                        <TouchableOpacity onPress={() => handleToggleTotalCost(item)}>
                                            <Text style={styles.showTaxInclusiveText}>
                                                Show Cost Including Taxes
                                            </Text>
                                        </TouchableOpacity>
                    </View>
                    )}
                    <View style={styles.showSeatsContainer}>
                        {showSeatArrangement && selectedBus != null && selectedBus.tripIdentifier == item.tripIdentifier ? (
                            <TouchableOpacity onPress={() => handleCloseSeatAlignment()}>
                                <SimpleLineIcons name="arrow-up" size={16} color="white">
                                    <Text style={styles.showSeatAvailability}> Hide Seat Availability</Text>
                                </SimpleLineIcons>
                            </TouchableOpacity>
                        ) : (
                            <TouchableOpacity onPress={() => handleShowSeatArrangement(item)}>
                                <SimpleLineIcons name="arrow-down" size={16} color="white">
                                    <Text style={styles.showSeatAvailability}> Show Seat Availability</Text>
                                </SimpleLineIcons>
                            </TouchableOpacity>
                        )}
                    </View>
                </View>
            </View>
            {loading && (
                <View style={styles.loadingContainer}>
                    <ActivityIndicator size="large" color="#5EBC67" />
                    <FontAwesome5 name="bus" size={24} color="#5EBC67" style={styles.busIcon} />
                </View>
            )}
            {showSeatArrangement && seatArrangement && selectedBus === item && (
                <View style={styles.seatArrangementContainer}>
                    {/* Display your seat arrangement grid here */}
                    <SeatArrangement
                        levels={seatArrangement.levels}
                        onSeatClick={handleSeatClick}
                        selectedSeats={selectedSeats}
                        onCancel={handleCloseSeatAlignment}
                        onBook={onBook}
                    />
                </View>
            )}
            {showBusDetails && selectedBus === item && (
                <BusDetailsModal activatedTab={activeTab} closeModal={handleCloseBusDetailModal} busDetails={selectedBus} />
            )}
        </View>
    );

    return (
        <View style={styles.resultsContainer}>
            <FlatList
                style={styles.busAvailabilities}
                data={busAvailabilities}
                keyExtractor={(item, index) => index.toString()}
                showsVerticalScrollIndicator={false}
                renderItem={renderItem}
            />
        </View>
    );
};

const styles = StyleSheet.create({
    resultsContainer: {
        marginHorizontal: '5%',
        marginTop: 10,
        width: '90%',
        flexGrow: 1,
    },
    busAvailabilities: {
        width: '100%'
    },
    busAvailabilityContainer: {
        width: "100%",
        borderRadius: 5,
        borderWidth: 1,
        borderColor: "#ccc",
        marginVertical: 5,
        flexDirection: 'row',
        flex: 1,
        flexWrap: "wrap",
        justifyContent: "center",
        backgroundColor: 'white'
    },
    leftContainer: {
        alignItems: "center",
        justifyContent: 'center',
        padding: 5,
        margin: 5,
        minWidth: '30%',
    },
    middleContainer: {
        alignItems: "center",
        padding: 5,
        margin: 5,
        minWidth: '30%',
    },
    originDestinationContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        padding: 5,
    },
    detailsContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        padding: 5,
        margin: 5
    },
    originContainer: {
        flexDirection: 'column',
        marginHorizontal: 5,
    },
    destinationContainer: {
        flexDirection: 'column',
        marginHorizontal: 5,
    },
    durationContainer: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    rightContainer: {
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: '#679EBC',
        paddingVertical: 5,
        minWidth: '30%',
        flex: 'auto',
    },
    seatTicketFares: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
    },
    priceContainer: {
        alignItems: "center",
        backgroundColor: '#679EBC',
        margin: 5,
        padding: 5,
    },
    totalAvailableSeatsText: {
        fontSize: 16,
        //fontFamily: 'Aldrich'
    },
    operator: {
        fontSize: 16,
        //fontFamily: 'Aldrich'
    },
    bus: {
        fontSize: 12,
        //fontFamily: 'Aldrich'
    },
    ticketFare: {
        fontSize: 16,
        //fontFamily: 'Aldrich'
    },
    taxInclusiveExclusive: {
        fontSize: 12,
        fontStyle: "italic"
    },
    showTaxInclusiveText: {
        fontSize: 12,
        fontStyle: "italic",
        textDecorationLine: "underline",
        color: 'greenyellow'
    },
    title: {
        fontSize: 16,
        //fontFamily: 'Aldrich'
    },
    amenitiesContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: 10,
    },
    shortFeature: {
        alignItems: 'center',
        margin: 5,
    },
    featureShortName: {
        fontSize: 8,
        textAlign: 'center',
        //fontFamily: 'Aldrich'
    },
    feature: {
        width: '30%',
        alignItems: 'center',
        marginVertical: 5,
    },
    featureName: {
        marginLeft: 5,
        fontSize: 8,
        //fontFamily: 'Aldrich'
    },
    timeText:{
        //fontFamily: 'Aldrich'
    },
    modalBackground: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    detailButton: {
        color: "black",
        textDecorationLine: "underline",
        marginHorizontal: 5,
    },
    seatArrangementContainer: {
        backgroundColor: 'white',
        padding: 5,
        marginBottom: 5,
        borderRadius: 5,
        borderWidth: 1,
        borderColor: "#ccc",
    },
    showSeatsContainer: {
        margin: 5,
        paddingHorizontal: 5,
        paddingVertical: 5,
    },
    seatType: {
        color: 'white',
        //fontFamily: 'Aldrich'
    },
    showSeatAvailability:{
        color: 'white',
        textDecorationLine: "underline",
    },
    availableCount: {
        fontSize: 16,
        //fontFamily: 'Aldrich'
    },
    showAllFeatures: {
        fontSize: 10,
        color: "black",
        //fontFamily: 'Aldrich',
        textDecorationLine: "underline",
    },
    locationText: {
        fontSize: 16,
        //fontFamily: 'Aldrich',
    },
    text: {
        fontSize: 12,
        color: 'brown',
        //fontFamily: 'Aldrich',
    },
    loadingContainer: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Optional: Adds a semi-transparent background
    },
    busIcon: {
        marginTop: 10,
    },
    popularBand: {
        position: 'absolute',
        top: 20,
        left: 0,
        backgroundColor: '#ff6347',
        paddingVertical: 2,
        paddingHorizontal: 10,
        transform: [{ rotate: '-45deg' }],
        zIndex: 1, // Ensure the band is above other content
      },
      popularText: {
        color: '#fff',
        fontSize: 12,
      },
});

export default BusAvailabilityResults;