import React from 'react';
import { TouchableOpacity, Text, StyleSheet, View } from 'react-native';

const Seat = React.memo(({ seat, onSeatClick, isSelected }) => {
  const handleSeatPress = () => {
    if (seat.seatType !== 'FREESPACE' && (seat.seatStatus === 'AVAILABLE_ALL' || seat.seatStatus === 'AVAILABLE_MALE' || seat.seatStatus === 'AVAILABLE_FEMALE')) {
      onSeatClick(seat);
      console.log('Seat Clicked:', seat);
    }
  };

  const renderSeatContent = () => {
    if (seat.seatType === 'RESTROOM') {
      return (
        <Text style={{ color: 'white' }}>{'\u{1F6BB}'}</Text> // Unicode for restroom symbol
      );
    }

    if (seat.seatType === 'FREESPACE') {
      return null; // Empty content for free space
    }

    return (
      <Text style={{ color: isSelected || seat.seatStatus === 'BOOKED' || seat.seatStatus === 'BLOCKED' ? 'white' : 'black',
      fontSize: 12 }}>
        {seat.seatNo}
      </Text>
    );
  };

  return (
    <TouchableOpacity
      style={[
        styles.seat,
        seat.seatType === 'FREESPACE' && styles.freeSpace, // Apply freeSpace style if seat type is FREESPACE
      ]}
      onPress={handleSeatPress}
    >
      {renderSeatContent()}
    </TouchableOpacity>
  );
});

const styles = StyleSheet.create({
  seat: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  freeSpace: {
    backgroundColor: 'transparent', // Ensure free space seats are transparent
  },
});

export default Seat;
