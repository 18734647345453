// PrivacyPolicyModal.js

import React from 'react';
import { View, Text, StyleSheet, TouchableOpacity, Modal, ScrollView } from 'react-native';

const PrivacyPolicyModal = ({ isVisible, closeModal }) => {
  return (
    <Modal visible={isVisible} onRequestClose={closeModal} animationType="slide" transparent={true}>
      <View style={styles.modalContainer}>
        <View style={styles.modalContent}>
          <ScrollView showsVerticalScrollIndicator={false}>
            <Text style={styles.title}>Privacy Policy</Text>
            
            <SectionHeader title="A. INTRODUCTION" />
            <Paragraph>
              BYGBUS Private Limited (hereinafter "BYGBUS") recognizes the importance of privacy of its users and the need to maintain confidentiality of information provided by its users as a responsible data controller and data processor.
            </Paragraph>
            <Paragraph>
              This Privacy Policy outlines the practices for handling and securing users' Personal Information by BYGBUS and its subsidiaries and affiliates.
            </Paragraph>

            <SectionHeader title="B. INFORMATION WE COLLECT AND ITS USE" />
            <Paragraph>
              1. Personal Information:
              We collect personally identifiable information when you set up an account or make a transaction with us. This may include:
            </Paragraph>
            <BulletList items={[
              "Name",
              "Gender",
              "Age",
              "Contact details (phone number, email address, mailing address)",
              "Travel details (routes, bus/flight preferences, hotel preferences)",
              "Payment information"
            ]} />

            <Paragraph>
              2. Non-Personal Information:
              We also collect non-personal information such as IP address, browser type, and operating system.
            </Paragraph>

            <Paragraph>
              3. Use of Information:
              We use your information to:
            </Paragraph>
            <BulletList items={[
              "Process your bookings and transactions",
              "Provide customer support",
              "Send booking confirmations and updates",
              "Personalize your experience on our platform",
              "Improve our services and develop new features",
              "Conduct research and analysis",
              "Comply with legal obligations"
            ]} />

            <SectionHeader title="C. SHARING OF INFORMATION" />
            <Paragraph>
              We may share your information with:
            </Paragraph>
            <BulletList items={[
              "Service Providers: To fulfill your bookings",
              "Payment Processors: To process your payments securely",
              "Business Partners: For co-branded services or promotions",
              "Legal Authorities: When required by law or to protect our rights"
            ]} />

            <Paragraph>
              We do not sell your personal information to third parties.
            </Paragraph>

            <SectionHeader title="D. DATA SECURITY" />
            <Paragraph>
              We implement appropriate measures to protect your personal information against unauthorized processing, accidental loss, destruction or damage.
            </Paragraph>

            <SectionHeader title="E. COOKIES AND TRACKING TECHNOLOGIES" />
            <Paragraph>
              We use cookies and similar tracking technologies to enhance your experience on our website.
            </Paragraph>

            <SectionHeader title="F. YOUR RIGHTS" />
            <Paragraph>
              You have the right to:
            </Paragraph>
            <BulletList items={[
              "Access your personal information",
              "Correct inaccurate information",
              "Delete your account and personal information",
              "Object to or restrict processing of your information",
              "Data portability"
            ]} />

            <SectionHeader title="G. RETENTION OF INFORMATION" />
            <Paragraph>
              We retain your information for as long as necessary to fulfill the purposes outlined in this policy, unless a longer retention period is required by law.
            </Paragraph>

            <SectionHeader title="H. CHANGES TO THIS POLICY" />
            <Paragraph>
              We may update this Privacy Policy from time to time. We will notify you of any significant changes.
            </Paragraph>

            <SectionHeader title="I. CONTACT US" />
            <Paragraph>
              If you have any questions, please contact us at:
              Email: privacy@bygbus.com
              Address: FLAT NO B 214,SUBISHI GOWTHAMI,KOMPALLY,SECUNDERABAD HYDERABAD TELANGANA 500014
            </Paragraph>

            <SectionHeader title="J. ELIGIBILITY" />
            <Paragraph>
              Use of BYGBUS services is available only to persons who can form a legally binding contract under the Indian Contract Act, 1872.
            </Paragraph>

            <Paragraph>
              Last Updated: Jul 6 2024
            </Paragraph>
            </ScrollView>
            <TouchableOpacity onPress={closeModal} style={styles.closeButtonContainer}>
              <Text style={styles.closeButton}>Close</Text>
            </TouchableOpacity>
          
        </View>
      </View>
    </Modal>
  );
};

const SectionHeader = ({ title }) => (
  <Text style={styles.sectionHeader}>{title}</Text>
);

const Paragraph = ({ children }) => (
  <Text style={styles.paragraph}>{children}</Text>
);

const BulletList = ({ items }) => (
  <View style={styles.bulletList}>
    {items.map((item, index) => (
      <Text key={index} style={styles.bulletItem}>• {item}</Text>
    ))}
  </View>
);

const styles = StyleSheet.create({
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  modalContent: {
    backgroundColor: '#FFFFFF',
    borderRadius: 20,
    padding: 20,
    width: '90%',
    maxHeight: '80%',
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 20,
    textAlign: 'center',
  },
  sectionHeader: {
    fontSize: 18,
    fontWeight: 'bold',
    marginTop: 15,
    marginBottom: 10,
  },
  paragraph: {
    fontSize: 14,
    marginBottom: 10,
    lineHeight: 20,
  },
  bulletList: {
    marginLeft: 10,
    marginBottom: 10,
  },
  bulletItem: {
    fontSize: 14,
    marginBottom: 5,
    lineHeight: 20,
  },
  closeButtonContainer: {
    marginTop: 20,
    alignItems: 'center',
  },
  closeButton: {
    color: '#007AFF',
    fontSize: 16,
    fontWeight: 'bold',
  }
});

export default PrivacyPolicyModal;