import React, { useRef, useState, useEffect } from 'react';
import { View, Image, Text, StyleSheet, TouchableOpacity, Modal, Pressable, Alert, Dimensions, useWindowDimensions } from 'react-native';
import { Ionicons, AntDesign, FontAwesome, FontAwesome6, MaterialIcons } from '@expo/vector-icons';
import { signOut } from 'aws-amplify/auth';
import { useUser } from './UserContext';
import MenuBar from './MenuBar';
import Constants from 'expo-constants';
import axios from 'axios';

const API_URL = Constants.expoConfig.extra.apiUrl;
const API_TOKEN = Constants.expoConfig.extra.apiToken;

const THEME = {
    colors: {
        primary: '#2563EB',        // Royal Blue
        secondary: '#3B82F6',      // Lighter Blue
        accent: '#1E40AF',         // Dark Blue
        success: '#059669',        // Green
        background: {
            primary: '#FFFFFF',    // White
            secondary: '#F3F4F6',  // Light Gray
        },
        text: {
            primary: '#1F2937',    // Dark Gray
            secondary: '#4B5563',  // Medium Gray
            light: '#9CA3AF',      // Light Gray
            white: '#FFFFFF',      // White
        },
        border: {
            light: '#E5E7EB',      // Light Border
            dark: '#D1D5DB',       // Dark Border
        },
        hover: {
            primary: '#1D4ED8',    // Darker Blue
            light: '#EFF6FF',      // Light Blue Hover
        },
        shadow: {
            primary: 'rgba(0, 0, 0, 0.1)',
            strong: 'rgba(0, 0, 0, 0.15)',
        }
    },
    spacing: {
        xs: 4,
        sm: 8,
        md: 12,
        lg: 16,
        xl: 20,
    },
    borderRadius: {
        sm: 4,
        md: 8,
        lg: 12,
        xl: 16,
    }
};

function Header({ navigation }) {
    const { width: screenWidth } = useWindowDimensions();
    const [showDropdown, setShowDropdown] = useState(false);
    const [isMobileView, setIsMobileView] = useState(screenWidth < 768);
    const { user, setUser, setUserAttributes, userAttributes , userPoints, setUserPoints} = useUser();
    const [loadingPoints, setLoadingPoints] = useState(false);

    useEffect(() => {
        const handleDimensionsChange = () => {
            setIsMobileView(Dimensions.get('window').width < 768);
        };

        const subscription = Dimensions.addEventListener('change', handleDimensionsChange);
        return () => subscription.remove();
    }, []);

    const onLogoPress = () => {
        navigation.navigate('Home');
    }

    const onLoginPress = () => {
        navigation.navigate('Login');
    }

    const onHomePress = () => {
        navigation.navigate('Home');
    }

    const onManageReservationPress = () => {
        navigation.navigate('ManageReservation');
    }

    const onTrackPress = () => {
        navigation.navigate('TrackBus');
    }

    const handleSignOut = async () => {
        try {
            await signOut();
            setUser(null);
            setUserAttributes(null);
            setUserPoints(0);
            setShowDropdown(false);
            navigation.navigate('Home');
            Alert.alert('Signed out successfully');
        } catch (error) {
            console.error("Error signing out:", error);
            Alert.alert('Error signing out', error.message);
            setShowDropdown(false);
        }
    };

    const handleAccount = () => {
        setShowDropdown(false);
        navigation.navigate('Account');
    }

    const handleSupport = () => {
        navigation.navigate('Support');
    }

    const handlePressOutside = (event) => {
        if (event.target === event.currentTarget) {
            setShowDropdown(false);
        }
    };

    const DropdownMenu = ({ visible, onClose, onSignOut, onAccount, onPressOutside }) => {
        if (!visible) return null;

        return (
            <Modal 
                transparent={true} 
                visible={visible} 
                animationType="fade" 
                onRequestClose={onClose}
            >
                <Pressable style={styles.modalBackground} onPress={onPressOutside}>
                    <View style={styles.dropdownMenu}>
                        <View style={styles.pointsContainer}>
                            <MaterialIcons name="stars" size={20} color={THEME.colors.primary} />
                            <Text style={styles.pointsText}>
                                {loadingPoints ? 'Loading...' : `${userPoints} Points`}
                            </Text>
                        </View>
                        <View style={styles.dropdownDivider} />
                        <TouchableOpacity 
                            onPress={onAccount}
                            style={styles.dropdownItemContainer}
                        >
                            <Ionicons name="person-outline" size={18} color={THEME.colors.text.secondary} />
                            <Text style={styles.dropdownItem}>Account</Text>
                        </TouchableOpacity>
                        <TouchableOpacity 
                            onPress={onSignOut}
                            style={styles.dropdownItemContainer}
                        >
                            <Ionicons name="log-out-outline" size={18} color={THEME.colors.text.secondary} />
                            <Text style={styles.dropdownItem}>Sign Out</Text>
                        </TouchableOpacity>
                    </View>
                </Pressable>
            </Modal>
        );
    };

    const renderRightSection = () => (
        <View style={[styles.rightContainer, isMobileView && styles.rightContainerMobile]}>
            <View style={[styles.rightBottomContainer, isMobileView && styles.rightBottomContainerMobile]}>
                <TouchableOpacity 
                    style={styles.actionButton} 
                    onPress={onHomePress}
                >
                    <View style={styles.manageContainer}>
                        <FontAwesome name="home" size={16} color={THEME.colors.text.secondary} />
                        <Text style={styles.actionButtonText}>Home</Text>
                    </View>
                </TouchableOpacity>
                <TouchableOpacity 
                    style={styles.actionButton} 
                    onPress={onManageReservationPress}
                >
                    <View style={styles.manageContainer}>
                        <FontAwesome name="ticket" size={16} color={THEME.colors.text.secondary} />
                        <Text style={styles.actionButtonText}>Manage Reservation</Text>
                    </View>
                </TouchableOpacity>
                <TouchableOpacity 
                    style={styles.actionButton} 
                    onPress={handleSupport}
                >
                    <View style={styles.support}>
                        <AntDesign name="customerservice" size={16} color={THEME.colors.text.secondary} />
                        <Text style={styles.actionButtonText}>Support</Text>
                    </View>
                </TouchableOpacity>
                {user ? (
                    <View>
                        <TouchableOpacity 
                            onPress={() => setShowDropdown(!showDropdown)}
                            style={styles.userButton}
                        >
                            <Ionicons name="person-circle" size={20} color={THEME.colors.primary} />
                            <Text style={styles.greetingText}>
                                Hello, {userAttributes.given_name}
                            </Text>
                        </TouchableOpacity>
                        <DropdownMenu 
                            visible={showDropdown} 
                            onClose={() => setShowDropdown(false)} 
                            onAccount={handleAccount} 
                            onSignOut={handleSignOut} 
                            onPressOutside={handlePressOutside} 
                        />
                    </View>
                ) : (
                    <TouchableOpacity onPress={onLoginPress} style={styles.login}>
                        <Ionicons name="person" size={16} color={THEME.colors.text.white} />
                    </TouchableOpacity>
                )}
            </View>
        </View>
    );

    return (
        <View style={[styles.headerContainer, isMobileView && styles.headerContainerMobile]}>
            <View style={[styles.header, isMobileView && styles.headerMobile]}>
                <View style={[styles.logoContainer, isMobileView && styles.logoContainerMobile]}>
                    <TouchableOpacity onPress={onLogoPress}>
                        <Image 
                            source={require('../assets/bygbus-high-resolution-logo-cropped.png')} 
                            style={[styles.logo, isMobileView && styles.logoMobile]} 
                        />
                    </TouchableOpacity>
                </View>
                {/*
                <View style={[styles.middleContainer, isMobileView && styles.middleContainerMobile]}>
                     <MenuBar navigation={navigation} /> 
                </View> */}
                {renderRightSection()}
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    headerContainer: {
        width: '100%',
        borderBottomWidth: 1,
        borderBottomColor: THEME.colors.border.light,
        paddingHorizontal: THEME.spacing.lg,
        //paddingVertical: THEME.spacing.md,
        backgroundColor: THEME.colors.background.primary,
        shadowColor: THEME.colors.shadow.primary,
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.1,
        shadowRadius: 4,
        elevation: 3,
    },
    headerContainerMobile: {
        paddingHorizontal: THEME.spacing.sm,
    },
    header: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        marginTop: 10
    },
    headerMobile: {
        flexDirection: 'column',
        alignItems: 'stretch',
    },
    logoContainer: {
        flex: 1,
        maxWidth: '30%',
        alignItems: 'flex-start',
    },
    logoContainerMobile: {
        maxWidth: '100%',
        alignItems: 'center',
        marginBottom: THEME.spacing.lg,
    },
    middleContainer: {
        flex: 2,
        alignItems: 'center',
        justifyContent: 'center',
        paddingHorizontal: THEME.spacing.lg,
    },
    middleContainerMobile: {
        width: '100%',
        paddingHorizontal: 0,
        marginBottom: THEME.spacing.lg,
    },
    rightContainer: {
        flex: 1,
        maxWidth: '25%',
        alignItems: 'flex-end',
        justifyContent: 'center',
    },
    rightContainerMobile: {
        maxWidth: '100%',
        width: '100%',
        alignItems: 'center',
    },
    logo: {
        width: 200,
        height: 60,
        resizeMode: 'contain',
    },
    logoMobile: {
        width: 150,
        height: 45,
    },
    rightTopContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
        marginBottom: THEME.spacing.sm,
    },
    rightTopContainerMobile: {
        width: '100%',
        justifyContent: 'center',
        marginBottom: THEME.spacing.md,
    },
    rightBottomContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'flex-end',
        gap: THEME.spacing.sm,
    },
    rightBottomContainerMobile: {
        justifyContent: 'center',
        gap: THEME.spacing.md,
    },
    actionButton: {
        minWidth: 80,
        alignItems: 'center',
    },
    actionButtonText: {
        fontSize: 12,
        color: THEME.colors.text.secondary,
        fontWeight: '600',
        marginLeft: THEME.spacing.xs,
    },
    userButton: {
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: THEME.colors.background.secondary,
        padding: THEME.spacing.sm,
        borderRadius: THEME.borderRadius.md,
    },
    login: {
        flexDirection: 'row',
        alignItems: 'center',
        gap: THEME.spacing.xs,
        paddingHorizontal: THEME.spacing.md,
        paddingVertical: THEME.spacing.sm,
        backgroundColor: THEME.colors.primary,
        borderRadius: THEME.borderRadius.md,
        elevation: 2,
        shadowColor: THEME.colors.shadow.primary,
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.1,
        shadowRadius: 3,
    },
    loginButton: {
        fontSize: 14,
        color: THEME.colors.text.white,
        fontWeight: '600',
    },
    greetingText: {
        fontSize: 14,
        fontWeight: '600',
        color: THEME.colors.text.primary,
        marginLeft: THEME.spacing.xs,
    },
    modalBackground: {
        flex: 1,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    dropdownMenu: {
        position: 'absolute',
        top: 20,
        right: 5,
        backgroundColor: THEME.colors.background.primary,
        width: 180,
        borderRadius: THEME.borderRadius.lg,
        elevation: 5,
        shadowColor: THEME.colors.shadow.strong,
        shadowOffset: {
            width: 0,
            height: 4,
        },
        shadowOpacity: 0.3,
        shadowRadius: 4.65,
        paddingVertical: THEME.spacing.md,
        paddingHorizontal: THEME.spacing.lg,
    },
    dropdownItemContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingVertical: THEME.spacing.sm,
    },
    dropdownItem: {
        fontSize: 14,
        color: THEME.colors.text.primary,
        fontWeight: '500',
        marginLeft: THEME.spacing.sm,
    },
    support: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    manageContainer: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    trackContainer: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    // New styles for points
    pointsContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingVertical: THEME.spacing.md,
        paddingHorizontal: THEME.spacing.sm,
        backgroundColor: THEME.colors.background.secondary,
        borderRadius: THEME.borderRadius.md,
        marginBottom: THEME.spacing.sm,
    },
    pointsText: {
        fontSize: 16,
        fontWeight: '600',
        color: THEME.colors.primary,
        marginLeft: THEME.spacing.sm,
    },
    dropdownDivider: {
        height: 1,
        backgroundColor: THEME.colors.border.light,
        marginVertical: THEME.spacing.sm,
    },
});

export default Header;