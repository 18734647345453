import React, { useState } from 'react';
import { View, Text, TextInput, Button, Alert } from 'react-native';
import { FontAwesome } from '@expo/vector-icons';
import axios from 'axios';
import { useRoute } from '@react-navigation/native';
import ScreenLayout from './ScreenLayout';
import Constants from 'expo-constants';

const API_URL = Constants.expoConfig.extra.apiUrl;
const API_TOKEN = Constants.expoConfig.extra.apiToken;

const FeedbackPage = ({ navigation }) => {
  const route = useRoute();
  const { serviceNumber } = route.params;

  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState('');

  const handleSubmit = async () => {

    const requestBody = {
      serviceNumber : serviceNumber,
        rating: rating,
        comment: comment
  };
    try {
      await axios.post(API_URL+'/ratings', requestBody, {
        headers: {
            'Content-Type': 'application/json',
            'x-api-token': API_TOKEN
        }
    } );
    navigation.navigate('Home');
    } catch (error) {
      Alert.alert('Error', 'There was an error submitting your feedback.');
    }
  };

  return (
    <ScreenLayout navigation={navigation}>
    <View style={{ padding: 20, width: '90%', justifyContent: 'center', alignItems: 'center' }}>
      <Text style={{ fontSize: 24, marginBottom: 20 }}>We Value Your Feedback</Text>

      <Text style={{ fontSize: 18, marginBottom: 10 }}>Rate your experience:</Text>
      <View style={{ flexDirection: 'row', marginBottom: 20 }}>
        {[1, 2, 3, 4, 5].map(star => (
          <FontAwesome
            key={star}
            name={star <= rating ? 'star' : 'star-o'}
            size={32}
            color="#FFD700"
            onPress={() => setRating(star)}
            style={{ marginRight: 10 }}
          />
        ))}
      </View>

      <Text style={{ fontSize: 18, marginBottom: 10 }}>Leave a comment:</Text>
      <TextInput
        style={{
          height: 100,
          borderColor: 'gray',
          borderWidth: 1,
          marginBottom: 20,
          padding: 10,
          width: '50%',
          textAlignVertical: 'top'
        }}
        multiline
        numberOfLines={4}
        onChangeText={setComment}
        value={comment}
      />

      <Button title="Submit Feedback" onPress={handleSubmit} />
    </View>
    </ScreenLayout>
  );
};

export default FeedbackPage;
