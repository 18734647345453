import React, { useState, useEffect } from 'react';
import { View, Text, TextInput, Pressable, TouchableOpacity, FlatList, StyleSheet, ScrollView, SafeAreaView, Modal, Dimensions } from 'react-native';
import axios from "axios";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { MaterialCommunityIcons, MaterialIcons } from '@expo/vector-icons';
import { format } from 'date-fns';
import Constants from 'expo-constants';

const API_URL = Constants.expoConfig.extra.apiUrl;
const API_TOKEN = Constants.expoConfig.extra.apiToken;

const BusSearchContainer = ({ onSearch, bookSummary, handleBookSummaryChange }) => {
    const { origin, destination, travelDate, promoCode } = bookSummary;

    const [originState, setOriginState] = useState('');
    const [destinationState, setDestinationState] = useState('');
    const [cities, setCities] = useState([]);
    const [originSuggestions, setOriginSuggestions] = useState([]);
    const [destinationSuggestions, setDestinationSuggestions] = useState([]);
    const [showOriginModal, setShowOriginModal] = useState(false);
    const [showDestinationModal, setShowDestinationModal] = useState(false);
    const [showCalendar, setShowCalendar] = useState(false);
    const [originError, setOriginError] = useState(false);
    const [destinationError, setDestinationError] = useState(false);
    const [quickDates, setQuickDates] = useState(getNext7Days());
    

    function getNext7Days() {
        const dates = [];
        for (let i = 0; i < 6; i++) {
            dates.push(new Date(new Date().setDate(new Date().getDate() + i)));
        }
        return dates;
    }

    useEffect(() => {
        const loadCities = async () => {
            try {
                const response = await axios.get(API_URL + "/city/loadallcities", {
                    headers: {
                        'x-api-token': API_TOKEN
                    }});
                setCities(response.data);
                setOriginSuggestions(response.data);
                setDestinationSuggestions(response.data);
            } catch (error) {
                console.error('Error fetching city suggestions:', error);
            }
        };

        if (cities.length === 0) {
            loadCities();
        }
    }, []);

    const loadSuggestions = (text, isOrigin) => {
      try {
          if (text.length > 0) {
              const lowerText = text.toLowerCase();
              const filteredCities = cities
                  .filter(city => city.cityName.toLowerCase().includes(lowerText))
                  .sort((a, b) => {
                      const aLower = a.cityName.toLowerCase();
                      const bLower = b.cityName.toLowerCase();
                      
                      // Exact match goes first
                      if (aLower === lowerText) return -1;
                      if (bLower === lowerText) return 1;
                      
                      // Starts with the search text goes second
                      if (aLower.startsWith(lowerText) && !bLower.startsWith(lowerText)) return -1;
                      if (bLower.startsWith(lowerText) && !aLower.startsWith(lowerText)) return 1;
                      
                      // Sort by how early the match appears in the string
                      return aLower.indexOf(lowerText) - bLower.indexOf(lowerText);
                  });
  
              if (isOrigin) {
                  setOriginSuggestions(filteredCities);
              } else {
                  setDestinationSuggestions(filteredCities);
              }
          } else {
              if (isOrigin) {
                  setOriginSuggestions(cities);
              } else {
                  setDestinationSuggestions(cities);
              }
          }
      } catch (error) {
          console.error('Error fetching city suggestions:', error);
      }
  };

    const handleOriginFocus = () => {
        setShowOriginModal(true);
    };

    const handleDestinationFocus = () => {
        setShowDestinationModal(true);
    };

    const handleOriginSelect = (item) => {
        const updatedBookSummary = { ...bookSummary, origin: item.cityName };
        handleBookSummaryChange(updatedBookSummary);
        setOriginState(item.state);
        setShowOriginModal(false);
        setOriginError(false);
       
    };

    const handleDestinationSelect = (item) => {
        const updatedBookSummary = { ...bookSummary, destination: item.cityName };
        handleBookSummaryChange(updatedBookSummary);
        setDestinationState(item.state);
        setShowDestinationModal(false);
        setDestinationError(false);
    };


    const handleSwap = () => {
        const updatedBookSummary = {
            ...bookSummary,
            origin: destination,
            destination: origin,
        };
        handleBookSummaryChange(updatedBookSummary);
        setOriginState(destinationState);
        setDestinationState(originState);
    };

    const handleDateChange = (selectedDate) => {
        const updatedBookSummary = { ...bookSummary, travelDate: selectedDate };
        handleBookSummaryChange(updatedBookSummary);
        setShowCalendar(false);
    };

    const handleCityChange = (text, isFromCity) => {
        const updatedBookSummary = { ...bookSummary };
        if (isFromCity) {
            updatedBookSummary.origin = text;
        } else {
            updatedBookSummary.destination = text;
        }
        handleBookSummaryChange(updatedBookSummary);
        loadSuggestions(text, isFromCity);
    };

    const searchAvailabilities = async () => {
        if (!origin || !destination) {
            if (!origin) {
                setOriginError(true);
            } else {
                setOriginError(false);
            }
            if (!destination) {
                setDestinationError(true);
            } else {
                setDestinationError(false);
            }
            return;
        }

        try {
            setOriginError(false);
            setDestinationError(false);
            onSearch();
        } catch (error) {
            throw error;
        }
    };

    return (
        <View style={styles.container}>
            <View style={styles.searchContainer}>
                <View style={styles.cityContainer}>
                    <Text style={styles.cityTypeText}>FROM <Text style={styles.asterisk}> *</Text></Text>
                    <TextInput
                        style={styles.input}
                        placeholder="FROM"
                        value={origin}
                        onChangeText={(text) => handleCityChange(text, true)}
                        onPointerDown={() => setShowOriginModal(true)}             
                    />
                    {originError && (
                        <Text style={styles.errorText}>Please type a city</Text>
                    )}
                    {originState && (
                        <Text style={styles.cityTypeText}>{originState}</Text>
                    )}
                </View>

                <Pressable style={styles.swapButton} onPress={handleSwap}>
                    <MaterialIcons name="swap-horiz" size={24} color="black" />
                </Pressable>

                <View style={styles.cityContainer}>
                    <Text style={styles.cityTypeText}>TO<Text style={styles.asterisk}> *</Text></Text>
                    <TextInput
                        style={styles.input}
                        placeholder="TO"
                        value={destination}
                        onChangeText={(text) => handleCityChange(text, false)}
                        onPointerDown={() => setShowDestinationModal(true)}
                    />
                    {destinationError && (
                        <Text style={styles.errorText}>Please type a city</Text>
                    )}
                    {destinationState && (
                        <Text style={styles.cityTypeText}>{destinationState}</Text>
                    )}
                </View>

                <View style={styles.calendarDateContainer}>
                    <Text style={styles.cityTypeText}>ON</Text>
                    <View style={styles.quickDateContainer}>
                        {quickDates.map((date, index) => (
                            <TouchableOpacity
                                key={index}
                                style={[
                                    styles.quickDateButton,
                                    travelDate.toDateString() === date.toDateString() && styles.selectedDateButton
                                ]}
                                onPress={() => handleDateChange(date)}
                            >
                                <Text style={styles.monthText}>{format(date, 'MMM')}</Text>
                                <Text style={styles.dateText}>{format(date, 'dd')}</Text>
                            </TouchableOpacity>
                        ))}
                    </View>
                    <View style={styles.calendarContainer}>
                        <Pressable onPress={() => setShowCalendar(true)}>
                            <View style={styles.calendarContainer}>
                                <Text style={styles.dateText}>{travelDate.toDateString()}</Text>
                                <MaterialIcons name="calendar-today" size={24} color="#5EBC67" />
                            </View>
                        </Pressable>
                    </View>
                </View>

                <View style={styles.cityContainer}>
                    <Text style={styles.cityTypeText}>Promo Code</Text>
                    <TextInput
                        style={styles.input}
                        placeholder="Promo Code"
                        value={promoCode}
                        onChangeText={(text) => handleBookSummaryChange({ ...bookSummary, promoCode: text })}
                    />
                </View>

                <View style={styles.searchButtonContainer}>
                    <Pressable onPress={() => searchAvailabilities()} style={styles.submitButton}>
                        <Text style={styles.submitButtonText}>Search</Text>
                    </Pressable>
                </View>
            </View>

            {/* Modal for Origin City */}
            <Modal visible={showOriginModal} transparent={true} animationType="slide" onRequestClose={() => setShowOriginModal(false)}>
                <View style={styles.modalContainer}>
                    <View style={styles.modalContent}>
                        <TextInput
                            style={styles.modalInput}
                            placeholder="Search City"
                            onChangeText={(text) => loadSuggestions(text, true)}
                        />
                        <View style={{width: '80%'}}>
                        <FlatList
                            contentContainerStyle={styles.cityListContainer}
                            data={originSuggestions}
                            keyExtractor={(item) => item.cityId}
                            renderItem={({ item }) => (
                                <Pressable onPress={() => handleOriginSelect(item)}>
                                    <Text style={styles.city}>{item.cityName}, {item.stateCode}</Text>
                                </Pressable>
                            )}
                        />
                        </View>
                        <Pressable onPress={() => setShowOriginModal(false)} style={styles.closeButton}>
                            <Text style={styles.closeButtonText}>Close</Text>
                        </Pressable>
                    </View>
                </View>
            </Modal>

            {/* Modal for Destination City */}
            <Modal visible={showDestinationModal} transparent={true} animationType="slide" onRequestClose={() => setShowDestinationModal(false)}>
                <View style={styles.modalContainer}>
                    <View style={styles.modalContent}>
                        <TextInput
                            style={styles.modalInput}
                            placeholder="Search City"
                            onChangeText={(text) => loadSuggestions(text, false)}
                        />
                        <View style={{width: '80%'}}>
                        <FlatList
                            contentContainerStyle={styles.cityListContainer}
                            data={destinationSuggestions}
                            keyExtractor={(item) => item.cityId}
                            renderItem={({ item }) => (
                                <Pressable onPress={() => handleDestinationSelect(item)}>
                                    <Text style={styles.city}>{item.cityName}, {item.stateCode}</Text>
                                </Pressable>
                            )}
                        />
                        </View>
                        <Pressable onPress={() => setShowDestinationModal(false)} style={styles.closeButton}>
                            <Text style={styles.closeButtonText}>Close</Text>
                        </Pressable>
                    </View>
                </View>
            </Modal>

            {/* Modal for Calendar */}
            <Modal visible={showCalendar} transparent={true} animationType="slide" onRequestClose={() => setShowCalendar(false)}>
                <View style={styles.modalContainer}>
                    <View style={styles.modalContent}>
                        <DatePicker
                            selected={travelDate}
                            minDate={new Date()}
                            onChange={handleDateChange}
                            inline
                        />
                        <Pressable onPress={() => setShowCalendar(false)} style={styles.closeButton}>
                            <Text style={styles.closeButtonText}>Close</Text>
                        </Pressable>
                    </View>
                </View>
            </Modal>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
        marginTop: 10
    },
    searchContainer: {
        backgroundColor: '#f2f2f2',
        borderRadius: 5,
        padding: 10,
        width: '90%',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexWrap: 'wrap'
    },
    cityContainer: {
        marginBottom: 10,
    },
    cityTypeText: {
        fontSize: 8,
        fontWeight: 'bold',
        marginBottom: 5,
    },
    input: {
        backgroundColor: 'white',
        fontSize: 16,
        padding: 5,
        borderRadius: 5,
    },
    cityListContainer: {
        maxHeight: 200,
        backgroundColor: 'white',
        borderRadius: 5,
        borderWidth: 1,
        borderColor: '#ccc',
        width: '80%'
    },
    city: {
        padding: 10,
        borderBottomWidth: 1,
        borderBottomColor: '#ccc',
        backgroundColor: 'white',
    },
    swapButton: {
        marginVertical: 10,
    },
    calendarDateContainer: {
        marginBottom: 10,
    },
    calendarContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 5,
    },
    dateText: {
        fontSize: 16,
        marginRight: 10,
    },
    quickDateContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        paddingHorizontal: 10,
        borderWidth: 1,
        borderColor: '#ccc',
    },
    quickDateButton: {
        padding: 10,
        margin: 5,
        backgroundColor: '#eee',
        borderRadius: 5,
        alignItems: 'center',
        justifyContent: 'center',
    },
    selectedDateButton: {
        backgroundColor: '#5EBC67',
    },
    monthText: {
        fontSize: 12,
        color: '#333',
    },
    dateText: {
        fontSize: 18,
        fontWeight: 'bold',
        color: '#333',
    },
    quickDateText: {
        fontSize: 16,
        color: '#000',
    },
    selectedDateText: {
        color: '#fff',
    },
    submitButton: {
        backgroundColor: '#5EBC67',
        paddingVertical: 10,
        paddingHorizontal: 20,
        borderRadius: 5,
    },
    submitButtonText: {
        color: 'white',
        fontWeight: 'bold',
        textAlign: 'center',
    },
    searchButtonContainer: {
        width: '100%',
        alignItems: 'center',
        marginTop: 10,
    },
    errorText: {
        color: 'red',
        marginTop: 5,
    },
    asterisk: {
        color: 'red',
    },
    modalContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    modalContent: {
        backgroundColor: 'white',
        padding: 20,
        borderRadius: 10,
        width: '80%',
        alignItems: 'center',
    },
    closeButton: {
        marginTop: 10,
        padding: 10,
        backgroundColor: '#5EBC67',
        borderRadius: 5,
    },
    closeButtonText: {
        color: 'white',
        fontWeight: 'bold',
    },
    modalInput: {
        backgroundColor: 'white',
        fontSize: 16,
        padding: 10,
        borderRadius: 5,
        marginBottom: 10,
        width: '80%',
    },
});

export default BusSearchContainer;