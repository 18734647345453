import React from 'react';
import { View, Text, StyleSheet, TouchableOpacity, Modal, ScrollView } from 'react-native';

const CancellationRefundPolicyModal = ({ isVisible, closeModal }) => {
  return (
    <Modal visible={isVisible} onRequestClose={closeModal} animationType="slide" transparent={true}>
      <View style={styles.modalContainer}>
        <View style={styles.modalContent}>
          <Text style={styles.title}>Cancellation and Refund</Text>
          <ScrollView style={styles.scrollView}>
            <Text style={styles.lastUpdated}>Last updated on Jul 6 2024</Text>
            <Text style={styles.paragraph}>
              BYGBUS believes in helping its customers as far as possible, and has therefore a liberal cancellation policy. Under this policy:
            </Text>
            <Text style={styles.paragraph}>
              Cancellations will be considered only if the request is made within 7 days of placing the order. However, the cancellation request may not be entertained if the orders have been communicated to the vendors/merchants and they have initiated the process of shipping them.
            </Text>
            <Text style={styles.paragraph}>
              In case you feel that the bus is not as shown on the site or as per your expectations, you must bring it to the notice of our customer service within 7 days of receiving the product. The Customer Service Team after looking into your complaint will take an appropriate decision.
            </Text>
            <Text style={styles.paragraph}>
              In case of any Refunds approved by the BYGBUS, it’ll take 6-8 days for the refund to be processed to the end customer.
            </Text>
          </ScrollView>
          <TouchableOpacity onPress={closeModal} style={styles.closeButtonContainer}>
            <Text style={styles.closeButton}>Close</Text>
          </TouchableOpacity>
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  modalContent: {
    backgroundColor: '#f2f2f2',
    padding: 20,
    borderRadius: 10,
    width: '90%',
    maxHeight: '80%',
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 10,
    textAlign: 'center',
  },
  scrollView: {
    marginBottom: 20,
  },
  lastUpdated: {
    fontSize: 14,
    color: '#666',
    marginBottom: 10,
  },
  paragraph: {
    fontSize: 16,
    marginBottom: 10,
  },
  closeButtonContainer: {
    alignItems: 'center',
  },
  closeButton: {
    color: 'blue',
    fontSize: 18,
    textDecorationLine: 'underline',
  },
});

export default CancellationRefundPolicyModal;
