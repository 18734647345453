import React, { useState } from 'react';
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native';
import PrivacyPolicyModal from '../modals/PrivacyPolicyModal';
import ShippingAndDeliveryPolicyModal from '../modals/ShippingAndDeliveryPolicyModal';
import TermsAndConditionsModal from '../modals/TermsAndConditionsModal';
import ContactUSModal from '../modals/ContactUsModal';
import CancellationRefundPolicyModal from '../modals/CancellationRefundPolicyModal';
import AboutUsModal from '../modals/AboutUsModal';

const Footer = () => {
  const [termsModalVisible, setTermsModalVisible] = useState(false);
  const [privacyModalVisible, setPrivacyModalVisible] = useState(false);
  const [shippingDeliveryModalVisible, setShippingDeliveryModalVisible] = useState(false);
  const [contactUSModalVisible, setContactUSModalVisible] = useState(false);
  const [CancellationRefundPolicyModalVisible, setCancellationRefundPolicyModalModal] = useState(false);
  const [aboutUsModalVisible, setAboutUsModalVisible] = useState(false);

  const openTermsModal = () => {
    setTermsModalVisible(true);
  };

  const openPrivacyModal = () => {
    setPrivacyModalVisible(true);
  };

  const openShippingDeliveryModal = () => {
    setShippingDeliveryModalVisible(true);
  };

  const openContactUSModal = () => {
    setContactUSModalVisible(true);
  };

  const openCancellationRefundPolicyModal = () => {
    setCancellationRefundPolicyModalModal(true);
  };

  const openAboutUsModal = () => {
    setAboutUsModalVisible(true);
  };

  const closeTermsModal = () => {
    setTermsModalVisible(false);
  };

  const closePrivacyModal = () => {
    setPrivacyModalVisible(false);
  };

  const closeShippingDeliveryModal = () => {
    setShippingDeliveryModalVisible(false);
  };

  const closeContactUSModal = () => {
    setContactUSModalVisible(false);
  };

  const closeCancellationRefundPolicyModal = () => {
    setCancellationRefundPolicyModalModal(false);
  };

  const closeAboutUsModal = () => {
    setAboutUsModalVisible(false);
  };

  return (
    <View style={styles.footer}>
      <TouchableOpacity onPress={openTermsModal}>
        <Text style={styles.link}>Terms and Conditions</Text>
      </TouchableOpacity>
      <TouchableOpacity onPress={openPrivacyModal}>
        <Text style={styles.link}>Privacy Policy</Text>
      </TouchableOpacity>
      <TouchableOpacity onPress={openShippingDeliveryModal}>
        <Text style={styles.link}>Shipping and Delivery</Text>
      </TouchableOpacity>
      <TouchableOpacity onPress={openContactUSModal}>
        <Text style={styles.link}>Contact Us</Text>
      </TouchableOpacity>
      <TouchableOpacity onPress={openCancellationRefundPolicyModal}>
        <Text style={styles.link}>Cancellation and Refund Policy</Text>
      </TouchableOpacity>
      <TouchableOpacity onPress={openAboutUsModal}>
        <Text style={styles.link}>About Us</Text>
      </TouchableOpacity>

      {/* Modals */}
      <TermsAndConditionsModal isVisible={termsModalVisible} closeModal={closeTermsModal} />
      <PrivacyPolicyModal isVisible={privacyModalVisible} closeModal={closePrivacyModal} />
      <CancellationRefundPolicyModal isVisible={CancellationRefundPolicyModalVisible} closeModal={closeCancellationRefundPolicyModal}/>
      <ContactUSModal isVisible={contactUSModalVisible} closeModal={closeContactUSModal}/>
      <ShippingAndDeliveryPolicyModal isVisible={shippingDeliveryModalVisible} closeModal={closeShippingDeliveryModal}/>
      <AboutUsModal isVisible={aboutUsModalVisible} closeModal={closeAboutUsModal} />
    </View>
  );
};

const styles = StyleSheet.create({
  footer: {
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    marginVertical : 10,
    paddingVertical: 10,
    backgroundColor: '#f0f0f0', // Customize as per your design
  },
  link: {
    color: 'black', // Customize link color
    textDecorationLine: 'underline',
    fontSize: 12
    //fontFamily: 'Aldrich',
  },
});

export default Footer;