import React from 'react';
import { View, Text, StyleSheet } from 'react-native';

const getSeatType = (seat) => {
    switch (seat.seatType) {
        case 'REGULAR':
            return 'Seat';
        case 'SINGLE_REGULAR':
            return 'Seat';
        case 'PUSHBACK':
            return 'Pushback';
        case 'SEMI_SLEEPER':
            return 'Semi Sleeper';
        case 'SLEEPER':
            return 'Sleeper';
        case 'SINGLE_LOWER_SLEEPER':
            return 'Sleeper';
        case 'SINGLE_UPPER_SLEEPER':
            return 'Sleeper';
        case 'LOWER_SLEEPER':
            return 'Sleeper';
        case 'UPPER_SLEEPER':
            return 'Sleeper';
        case 'FREESPACE':
        case 'RESTROOM':
            return '';
        default:
            return 'Seat';
    }
};

const Tooltip = ({ seat }) => (
    <View style={styles.tooltipContainer}>
        <Text style={styles.tooltipText}>Seat: {seat.seatNo}</Text>
        <Text style={styles.tooltipText}>Seat Type: {getSeatType(seat)}</Text>
        <Text style={styles.tooltipText}>Base Fare: ₹ {seat.seatRate.baseFare.toFixed(2)}</Text>
        <Text style={styles.tooltipText}>Tax: ₹ {seat.seatRate.totalTax.toFixed(2)}</Text>
        <Text style={styles.tooltipText}>Total Cost: ₹ {seat.seatRate.totalCost.toFixed(2)}</Text>
    </View>
);

const styles = StyleSheet.create({
    tooltipContainer: {
        position: 'absolute',
        backgroundColor: '#2E3A59', // A dark blue shade
        padding: 10,
        borderRadius: 5,
        top: -30, // Adjust based on your layout
        right: 40,
        zIndex: 9,
        width: 120,
        flexDirection: 'column',
        flexWrap: 'wrap'
    },
    tooltipText: {
        color: '#F5F5F5', // A light gray shade for better contrast
        fontSize: 8
    },
});

export default Tooltip;
