import React, { useRef, useState, useEffect } from 'react';
import { View, Image, Text, StyleSheet, TouchableOpacity, Modal, Pressable, Alert , Dimensions} from 'react-native';
import { Ionicons, AntDesign, FontAwesome, FontAwesome6, MaterialIcons } from '@expo/vector-icons';
import { signOut } from 'aws-amplify/auth';
import { useUser } from './UserContext';

function Header({ navigation }) {
    const [showDropdown, setShowDropdown] = useState(false);
    const { user, setUser, setUserAttributes, userAttributes } = useUser();

    const onLogoPress = () => {
        navigation.navigate('Home');
    }

    const onLoginPress = () => {
        navigation.navigate('Login');
    }

    const onManageReservationPress = () => {
        navigation.navigate('ManageReservation');
    }

    const onTrackPress = () => {
        navigation.navigate('TrackBus');
    }

    const handleSignOut = async () => {
        try {
            await signOut();
            setUser(null);
            setUserAttributes(null);
            setShowDropdown(false);
            navigation.navigate('Home');
            Alert.alert('Signed out successfully');
        } catch (error) {
            console.error("Error signing out:", error);
            Alert.alert('Error signing out', error.message);
            setShowDropdown(false);
        }
    };

    const handleAccount = () => {
        setShowDropdown(false);
        navigation.navigate('Account');
    }

    const handleSupport= () => {
        navigation.navigate('Support');
    }

    // Handler to close dropdown when clicking outside
    const handlePressOutside = (event) => {
        if (event.target === event.currentTarget) {
            setShowDropdown(false);
        }
    };


    return (
        <View style={styles.headerContainer}>
            <View style={styles.header}>
                <View style={styles.logoContainer}>
                    <TouchableOpacity onPress={onLogoPress}>
                        <Image source={require('../assets/bygbus-high-resolution-logo-cropped.png')} style={styles.logo} />
                    </TouchableOpacity>
                </View>
                <View style={styles.rightContainer}>
                    <View style={styles.rightTopContainer}>
                        {user ? (
                            <View>
                            <TouchableOpacity onPress={() => setShowDropdown(!showDropdown)} >
                                <Text style={styles.greetingText}>Hello, {userAttributes.given_name}</Text>
                            </TouchableOpacity>
                            <DropdownMenu visible={showDropdown} onClose={() => setShowDropdown(false)} onAccount={handleAccount} onSignOut={handleSignOut} onPressOutside={handlePressOutside} />
                            </View>
                        ) : (
                            <TouchableOpacity onPress={onLoginPress}>
                                <View style={styles.login}>
                                    <Ionicons name="person" size={12} color="black" style={styles.icon} />
                                    <Text style={styles.loginButton}>Login</Text>
                                </View>
                            </TouchableOpacity>
                        )}
                    </View>
                    <View style={styles.rightBottomContainer}>
                        <TouchableOpacity onPress={onManageReservationPress}>
                            <View style={styles.manageContainer}>
                                <FontAwesome name="ticket" size={12} color="black" />
                                <Text style={styles.loginButton}>Manage Reservation</Text>
                            </View>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={onTrackPress}>
                            <View style={styles.trackContainer}>
                                <FontAwesome6 name="map-location-dot" size={12} color="black" />
                                <Text style={styles.loginButton}>Track</Text>
                            </View>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={() => console.log('Offers pressed')}>
                            <View style={styles.offersContainer}>
                                <MaterialIcons name="local-offer" size={12} color="black" />
                                <Text style={styles.loginButton}>Offers</Text>
                            </View>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={handleSupport}>
                            <View style={styles.support}>
                                <AntDesign name="customerservice" size={12} color="black" />
                                <Text style={styles.loginButton}>Support</Text>
                            </View>
                        </TouchableOpacity>
                    </View>
                </View>
            </View>
        </View>
    );
}

const DropdownMenu = ({ visible, onClose, onSignOut, onAccount, onPressOutside }) => {
    if (!visible) return null;

    return (
        <Modal transparent={true} visible={visible} animationType="fade" onRequestClose={onClose}>
            <Pressable style={styles.modalBackground} onPress={onPressOutside}>
                <View style={[styles.dropdownMenu]} >
                    <TouchableOpacity onPress={onAccount}>
                        <Text style={styles.dropdownItem}>Account</Text>
                    </TouchableOpacity>
                    <TouchableOpacity onPress={onSignOut}>
                        <Text style={styles.dropdownItem}>Sign Out</Text>
                    </TouchableOpacity>
                </View>
            </Pressable>
        </Modal>
    );
};

const styles = StyleSheet.create({
    headerContainer: {
        flexDirection: 'row',
        width: '100%',
        borderBottomWidth: 1,
        borderBottomColor: 'lightgray',
        paddingHorizontal: 10,
        paddingVertical: 5,
    },
    header: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
    },
    logoContainer: {
        flex: 1,
        alignItems: 'flex-start',
    },
    logo: {
        width: 200,
        height: 60,
        resizeMode: 'contain',
    },
    rightContainer: {
        flex: 1,
        flexDirection: 'column',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
    },
    rightTopContainer: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    login: {
        flexDirection: 'row',
        alignItems: 'center',
        marginRight: 5,
    },
    support: {
        flexDirection: 'row',
        alignItems: 'center',
        marginRight: 5,
    },
    rightBottomContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'center',
        marginTop: 5,
    },
    loginButton: {
        fontSize: 12,
    },
    manageContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        marginRight: 5,
    },
    trackContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        marginRight: 5,
    },
    offersContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        marginRight: 5,
    },
    modalBackground: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'flex-end',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    dropdownMenu: {
        backgroundColor: 'white',
        width: 150,
        borderRadius: 5,
        elevation: 5,
        paddingVertical: 10,
        paddingHorizontal: 15,
        position: 'absolute',
        top: 20,
        right: 5,  
    },
    dropdownItem: {
        fontSize: 14,
        paddingVertical: 10,
    },
});

export default Header;
