import React from 'react';
import { View, Text, StyleSheet, TouchableOpacity, Modal, ScrollView } from 'react-native';

const TermsAndConditionsModal = ({ isVisible, closeModal }) => {
  return (
    <Modal visible={isVisible} onRequestClose={closeModal} animationType="slide" transparent={true}>
      <View style={styles.modalContainer}>
        <View style={styles.modalContent}>
          <Text style={styles.title}>Terms and Conditions</Text>
          <ScrollView style={styles.scrollView}>
            <Text style={styles.lastUpdated}>Last updated on Jul 6 2024</Text>

            <Text style={styles.sectionTitle}>1. Role of BYGBUS</Text>
            <Text style={styles.paragraph}>
              1.1. BYGBUS ("we," "us," or "our") operates the BYGBUS platform (the "Platform"), which provides a technology platform for online booking of bus tickets, flight tickets, and hotel accommodations in India.
            </Text>
            <Text style={styles.paragraph}>
              1.2. BYGBUS does not own, operate, or offer transportation or accommodation services directly.
            </Text>
            <Text style={styles.paragraph}>
              1.3. BYGBUS is not an agent of any service provider (bus operators, airlines, or hotels) in the process of providing our platform services.
            </Text>

            <Text style={styles.sectionTitle}>2. Bookings and Tickets</Text>
            <Text style={styles.paragraph}>
              2.1. All information regarding availability, pricing, and schedules on BYGBUS is based solely on data provided by service providers.
            </Text>
            <Text style={styles.paragraph}>
              2.2. Arrival and departure times mentioned on tickets are tentative. Delays may occur due to unforeseen circumstances.
            </Text>
            <Text style={styles.paragraph}>
              2.3. A booking is considered confirmed when the booking confirmation page is shown to the customer on BYGBUS, even if SMS/Email confirmation fails to deliver.
            </Text>

            <Text style={styles.sectionTitle}>3. User Responsibilities</Text>
            <Text style={styles.paragraph}>
              3.1. Users of BYGBUS must provide the following at the time of boarding/check-in:
              {'\n'}a) SMS or email confirmation of the ticket from BYGBUS
              {'\n'}b) Valid proof of identity (e.g., Driving License, Aadhar Card, Passport, PAN Card, Voter Card)
            </Text>
            <Text style={styles.paragraph}>
              3.2. Users should reach the boarding point/check-in location at least 30 minutes prior to the scheduled departure/check-in time.
            </Text>
            <Text style={styles.paragraph}>
              3.3. Users are responsible for verifying all booking details on BYGBUS and contacting service providers to confirm exact boarding points, departure times, and other travel information.
            </Text>

            <Text style={styles.sectionTitle}>4. Limitation of Liability</Text>
            <Text style={styles.paragraph}>
              4.1. BYGBUS is not responsible for:
              {'\n'}a) Delays, cancellations, or changes made by service providers
              {'\n'}b) Conduct of service provider staff or other passengers
              {'\n'}c) Loss or damage to baggage
              {'\n'}d) Quality or condition of vehicles or accommodations
              {'\n'}e) Incorrect information provided by service providers
              {'\n'}f) Users missing their transport due to late arrival or waiting at incorrect locations
            </Text>
            <Text style={styles.paragraph}>
              4.2. BYGBUS's maximum liability in any claim is limited to two times the value of the booking amount.
            </Text>

            <Text style={styles.sectionTitle}>5. Cancellations and Refunds</Text>
            <Text style={styles.paragraph}>
              5.1. Cancellation policies are set by individual service providers and may vary.
            </Text>
            <Text style={styles.paragraph}>
              5.2. Online bookings made on BYGBUS must be cancelled online through our platform.
            </Text>
            <Text style={styles.paragraph}>
              5.3. BYGBUS charges a cancellation fee of [amount] per seat/room cancelled, in addition to the service provider's cancellation charges.
            </Text>
            <Text style={styles.paragraph}>
              5.4. Cancellation charges are applicable on the original fare, not the discounted fare.
            </Text>
            <Text style={styles.paragraph}>
              5.5. Partial cancellations may be allowed for some bookings; refer to specific cancellation terms for each booking on BYGBUS.
            </Text>
            <Text style={styles.paragraph}>
              5.6. Rescheduling (postponement or preponing) may not be available after a booking is confirmed on BYGBUS.
            </Text>

            <Text style={styles.sectionTitle}>6. Payments and Refunds</Text>
            <Text style={styles.paragraph}>
              6.1. BYGBUS does not charge additional payment gateway or service fees unless specified in the booking details.
            </Text>
            <Text style={styles.paragraph}>
              6.2. Failed transactions where your account is debited without generating a ticket on BYGBUS will be fully refunded within 24 hours.
            </Text>
            <Text style={styles.paragraph}>
              6.3. Refunds may take 2-7 working days to reflect in your account, depending on your bank/card/wallet provider.
            </Text>

            <Text style={styles.sectionTitle}>7. Customer Support and Complaints</Text>
            <Text style={styles.paragraph}>
              7.1. Any complaints or grievances related to BYGBUS bookings should be reported within one week from the date of journey/stay.
            </Text>
            <Text style={styles.paragraph}>
              7.2. BYGBUS provides voice/email support for booking, cancellation, and refund issues.
            </Text>

            <Text style={styles.sectionTitle}>8. Additional Services</Text>
            <Text style={styles.paragraph}>
              8.1. BYGBUS may offer additional services such as travel insurance. Terms for these services are governed by the respective service providers.
            </Text>

            <Text style={styles.sectionTitle}>9. Privacy and Communication</Text>
            <Text style={styles.paragraph}>
              9.1. By making a booking on BYGBUS, you agree to receive calls, emails, SMS, or other alerts regarding your bookings.
            </Text>
            <Text style={styles.paragraph}>
              9.2. Our use of your personal information is governed by the BYGBUS Privacy Policy.
            </Text>

            <Text style={styles.sectionTitle}>10. Intellectual Property</Text>
            <Text style={styles.paragraph}>
              All content on the BYGBUS Platform is our property and protected by Indian copyright laws.
            </Text>

            <Text style={styles.sectionTitle}>11. Modifications to Terms</Text>
            <Text style={styles.paragraph}>
              BYGBUS reserves the right to modify these Terms and Conditions at any time without notice.
            </Text>

            <Text style={styles.sectionTitle}>12. Governing Law and Jurisdiction</Text>
            <Text style={styles.paragraph}>
              These terms are governed by Indian law. All disputes are subject to the exclusive jurisdiction of competent courts in India.
            </Text>
          </ScrollView>
          <TouchableOpacity onPress={closeModal} style={styles.closeButtonContainer}>
            <Text style={styles.closeButton}>Close</Text>
          </TouchableOpacity>
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  modalContent: {
    backgroundColor: '#f2f2f2',
    padding: 20,
    borderRadius: 10,
    width: '90%',
    maxHeight: '80%',
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 10,
    textAlign: 'center',
  },
  scrollView: {
    marginBottom: 20,
  },
  lastUpdated: {
    fontSize: 14,
    color: '#666',
    marginBottom: 10,
  },
  sectionTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    marginTop: 15,
    marginBottom: 5,
  },
  paragraph: {
    fontSize: 16,
    marginBottom: 10,
  },
  closeButtonContainer: {
    alignItems: 'center',
  },
  closeButton: {
    color: 'blue',
    fontSize: 18,
    textDecorationLine: 'underline',
  },
});

export default TermsAndConditionsModal;