import React, { useState, useEffect } from 'react';
import { View, Text, TextInput, Button, Alert, StyleSheet, ScrollView, CheckBox , TouchableOpacity} from 'react-native';
import axios from 'axios'; // Import axios if not already imported
import ScreenLayout from './ScreenLayout';
import { useRoute } from '@react-navigation/native';
import Constants from 'expo-constants';
import * as Print from 'expo-print';

const API_URL = Constants.expoConfig.extra.apiUrl;
const API_TOKEN = Constants.expoConfig.extra.apiToken;
const CANCELLATION_ALLOWED = 'CANCELLATION_ALLOWED';

const ManageReservation = ({ navigation }) => {
    const route = useRoute();
    const { pnr: initialPnr } = route.params || {};
    const [pnr, setPnr] = useState(initialPnr || '');
    const [reservationDetails, setReservationDetails] = useState(null);
    const [cancellationDetails, setCancellationDetails] = useState(null);
    const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [selectedPassengers, setSelectedPassengers] = useState([]);

    useEffect(() => {
        if (initialPnr) {
            fetchReservationDetails();
        }
    }, [initialPnr]);

    const handlePrint = async () => {
        try {
            const response = await axios.get(API_URL + '/bus-reservation/print/' + reservationDetails.confirmationCode , {
                headers: {
                    'x-api-token': API_TOKEN
                }
            });
            await Print.printAsync({
                html: response.data
            });
        } catch (error) {
            console.error(error);
        }
    };

    const formatTime = (timeArray) => {
        const arrivalTime = new Date(timeArray[0], timeArray[1] - 1, timeArray[2], timeArray[3], timeArray[4]);
        return arrivalTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    };

    const convertTo12HourFormat = (dateTime) => {
        const date = new Date(dateTime.replace(' ', 'T'));

        // Extract date components
        const day = date.getDate();
        const month = date.getMonth() + 1; // Months are zero-based
        const year = date.getFullYear();

        // Extract time components
        let hours = date.getHours();
        const minutes = date.getMinutes();
        const ampm = hours >= 12 ? 'PM' : 'AM';

        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        const minutesStr = minutes < 10 ? '0' + minutes : minutes;

        const formattedDate = `${day}/${month}/${year}`;
        const formattedTime = `${hours}:${minutesStr} ${ampm}`;

        return `${formattedDate} ${formattedTime}`;
    };

    const fetchReservationDetails = async () => {
        try {
            const response = await axios.get(`${API_URL}/bus-reservation/${pnr}`, {
                headers: {
                    'x-api-token': API_TOKEN
                }
            });
            const payload = response.data;

            console.log('Parsed Payload', payload);
            console.log('Operator', payload.busAvailability);

            // Set the parsed reservation details
            setReservationDetails(payload);
        } catch (error) {
            console.error('Error fetching reservation:', error);
            Alert.alert('Error', 'Failed to fetch reservation details. Please try again.');
        }
    };

   const cancelReservation = async () => {
        try {
            // Perform cancel reservation request
            const response = await axios.post(`${API_URL}/cancel/${pnr}`, {},{
                headers: {
                    'x-api-token': API_TOKEN
                }
            });
            const cancellationResponse = response.data;

            // Clear reservation details after cancellation
            setReservationDetails(null);
            // Clear input field
            setCancellationDetails(cancellationResponse);
            setSelectedPassengers([]);
            setShowCancelConfirmation(true);
        } catch (error) {
            console.error('Error cancelling reservation:', error);
            Alert.alert('Error', 'Failed to cancel reservation. Please try again.');
        }
    };

    const confirmCancelSeats = async () => {
        try {

            const requestBody = {
                pnr: pnr,
                ticketCode: cancellationDetails.ticketCode,
                cancelPassengers: selectedPassengers
            };

            const response = await axios.post(API_URL + '/cancel-confirm', requestBody, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-token': API_TOKEN
                }
            }); 
            setShowConfirmation(true);
            console.log(response.data);

        } catch (error) {
            console.error('Error cancelling reservation:', error);
            Alert.alert('Error', 'Failed to cancel Confirm reservation. Please try again.');
        }
    }
 
    const togglePassengerSelection = (passenger) => {
        setSelectedPassengers((prevSelected) =>
            prevSelected.find((p) => p.seat.seatNo === passenger.seat.seatNo)
                ? prevSelected.filter((p) => p.seat.seatNo !== passenger.seat.seatNo)
                : [...prevSelected, passenger]
        );
    };

    const calculateCancellationCharges = () => {
        let totalCancellationCharge = 0;
        let totalRefundAmount = 0;
       
        selectedPassengers.forEach((selectedPassenger) => {
            totalCancellationCharge += selectedPassenger.seat.seatRate.cancellationCharges;
            totalRefundAmount += selectedPassenger.seat.seatRate.refundAmount;
        });
        return  totalCancellationCharge ;
    }

    const isCancellable = () => {
        let totalCancellationCharge = 0;
        let totalRefundAmount = 0;
       
        selectedPassengers.forEach((selectedPassenger) => {
            totalCancellationCharge += selectedPassenger.seat.seatRate.cancellationCharges;
            totalRefundAmount += selectedPassenger.seat.seatRate.refundAmount;
        });
        return  totalCancellationCharge ;
    }


    return (
        <ScreenLayout navigation={navigation}>
        <ScrollView contentContainerStyle={styles.scrollContainer}>
            <View style={styles.container}>
            {!showCancelConfirmation && (
                <View>
                    <Text style={styles.title}>Manage Reservation</Text>
                    <TextInput
                        style={styles.input}
                        placeholder="Enter PNR"
                        value={pnr}
                        onChangeText={setPnr}
                    />

                    <View style={styles.buttonContainer}>
                    <TouchableOpacity onPress={fetchReservationDetails} style={styles.retrieveButton} >
                        <Text style={styles.buttonText}>Retrieve Reservation</Text>
                    </TouchableOpacity>
                </View>
                    {reservationDetails && (
                        <View style={styles.detailsContainer}>
                            <Text style={styles.detailsTitle}>Reservation Details:</Text>
                            <Text style={styles.detailsText}>BYGBUS PNR: {reservationDetails.transactionPNR}</Text>
                            <Text style={styles.detailsText}>Operator PNR: {reservationDetails.operatorPNR}</Text>
                            <Text style={styles.detailsText}>Service: {reservationDetails.busAvailability.serviceIdentifier}</Text>
                            {reservationDetails.status === "BOOKED" && (
                            <Text style={[{ color: 'green', fontWeight: 'bold' }, styles.detailsText]}>Confirmed</Text>
                        )}

                        {reservationDetails.status === "CANCELLED" && (
                            <Text style={[{ color: 'red', fontWeight: 'bold' }, styles.detailsText]}>Cancelled</Text>
                        )}

                        {reservationDetails.status != "CANCELLED" && reservationDetails.status != "BOOKED" && (
                           <Text style={styles.detailsText}>Status: {reservationDetails.status}</Text>
                        )}

                            {/* Display other reservation details as needed */}

                            <View style={styles.section}>
                                <Text style={styles.sectionHeader}>Travel/Operator</Text>
                                <Text style={styles.detailText}>Operator: {reservationDetails.busAvailability.operator} ({reservationDetails.busAvailability.bus.name})</Text>
                                <Text style={styles.detailText}>From City: {reservationDetails.origin}</Text>
                                <Text style={styles.detailText}>To City: {reservationDetails.destination}</Text>
                                <Text style={styles.detailText}>On: {reservationDetails.travelDate}</Text>
                                <Text style={styles.detailText}>Departure: {formatTime(reservationDetails.busAvailability.departureTime)}</Text>
                                <Text style={styles.detailText}>Arrival: {formatTime(reservationDetails.busAvailability.arrivalTime)}</Text>
                            </View>

                            <View style={styles.section}>
                                <Text style={styles.sectionHeader}>Contact Details</Text>
                                <Text style={styles.detailText}>Email: {reservationDetails.contactEmail}</Text>
                                <Text style={styles.detailText}>Phone: {reservationDetails.contactNumber}</Text>
                            </View>

                            <View style={styles.section}>
                                <Text style={styles.sectionHeader}>Passenger Details</Text>
                                <View style={styles.passengersContainer}>
                                    {reservationDetails.passengersInfo.map((passenger, index) => (
                                        <View key={index} style={styles.passengerContainer}>
                                            <Text style={styles.passengerHeader}>Passenger {index + 1}</Text>
                                            <Text style={styles.detailText}>Seat: {passenger.seat.seatNo}</Text>
                                            <Text style={styles.detailText}>First Name: {passenger.firstName}</Text>
                                            <Text style={styles.detailText}>Last Name: {passenger.lastName}</Text>
                                            <Text style={styles.detailText}>Age: {passenger.age}</Text>
                                            <Text style={styles.detailText}>Gender: {passenger.gender}</Text>
                                            {passenger.seat.seatStatus === "BOOKED" && (
                                                <Text style={{ color: 'green' , fontWeight: 'bold'}}>Booked</Text>
                                            )}

                                            {passenger.seat.seatStatus === "CANCELLED" && (
                                                <Text style={{ color: 'red', fontWeight: 'bold' }}>Cancelled</Text>
                                            )}
                                        </View>
                                    ))}
                                </View>
                            </View>

                            <View style={styles.section}>
                                <Text style={styles.sectionHeader}>Boarding Point</Text>
                                <Text style={styles.detailText}>Boarding At: {reservationDetails.boardingPoint.pointName}</Text>
                                <Text style={styles.detailText}>Landmark: {reservationDetails.boardingPoint.landMark}</Text>
                                <Text style={styles.detailText}>Contact : {reservationDetails.boardingPoint.contactNumber}</Text>
                                <Text style={styles.detailText}>
                                    Arrival Time: {new Date(
                                        reservationDetails.boardingPoint.arrivalTime[0],
                                        reservationDetails.boardingPoint.arrivalTime[1] - 1,
                                        reservationDetails.boardingPoint.arrivalTime[2],
                                        reservationDetails.boardingPoint.arrivalTime[3],
                                        reservationDetails.boardingPoint.arrivalTime[4]
                                    ).toLocaleTimeString([], {
                                        hour: '2-digit',
                                        minute: '2-digit',
                                    })}
                                </Text>
                            </View>

                            <View style={styles.section}>
                                <Text style={styles.sectionHeader}>Drop-Off Point</Text>
                                <Text style={styles.detailText}>DropOff At: {reservationDetails.droppingPoint.pointName}</Text>
                                <Text style={styles.detailText}>Landmark: {reservationDetails.droppingPoint.landMark}</Text>
                                <Text style={styles.detailText}>Contact : {reservationDetails.droppingPoint.contactNumber}</Text>
                                <Text style={styles.detailText}>
                                    Arrival Time: {new Date(
                                        reservationDetails.droppingPoint.arrivalTime[0],
                                        reservationDetails.droppingPoint.arrivalTime[1] - 1,
                                        reservationDetails.droppingPoint.arrivalTime[2],
                                        reservationDetails.droppingPoint.arrivalTime[3],
                                        reservationDetails.droppingPoint.arrivalTime[4]
                                    ).toLocaleTimeString([], {
                                        hour: '2-digit',
                                        minute: '2-digit',
                                    })}
                                </Text>
                            </View>

                            <View style={styles.section}>
                                <Text style={styles.sectionHeader}>Cancellation Policy</Text>
                                {reservationDetails.busAvailability.cancellationPolicy && reservationDetails.busAvailability.cancellationPolicy.cancellationPenalty.map((cancelPenalty, index) => (
                                    <View key={index} style={styles.penaltyContainer}>
                                        <Text style={styles.detailText}>Between {cancelPenalty.from ? convertTo12HourFormat(cancelPenalty.from) : 'Departure'} and {cancelPenalty.to ? convertTo12HourFormat(cancelPenalty.to) : 'Booked Date/time'}:</Text>
                                        <Text style={styles.detailText}>
                                            {cancelPenalty.refundable ?
                                                `${cancelPenalty.chargeType === 'AMOUNT' ? '₹' : ''} ${cancelPenalty.cancellationCharge} ${cancelPenalty.chargeType === 'PERCENTAGE' ? '%' : ''} Charged`
                                                : 'Non Refundable'}
                                        </Text>
                                    </View>
                                ))}
                            </View>

                            <View style={styles.section}>
                                <Text style={styles.sectionHeader}>Cost Breakdown</Text>
                                {reservationDetails.seats.map((seat, index) => (
                                    <View key={index} style={styles.costContainer}>
                                        <Text style={styles.detailText}>Seat {seat.seatNo}: (Base ₹{seat.seatRate.discountedBaseFare} + Tax ₹{seat.seatRate.totalTax}) ₹{seat.seatRate.totalCost}</Text>
                                    </View>
                                ))}
                            </View>

                            <View style={styles.buttonContainer}>


<TouchableOpacity style={styles.button} onPress={cancelReservation}>
                    <Text style={styles.buttonText}>Cancel Reservation</Text>
                </TouchableOpacity>

<TouchableOpacity style={styles.button} onPress={handlePrint}>
                    <Text style={styles.buttonText}>Print</Text>
                </TouchableOpacity>
                            </View>
                        </View>
                    )}
                </View>
            )}

            {showCancelConfirmation && !showConfirmation && (
                <View>
                    <Text style={styles.title}>Cancel Ticket</Text>
                    <View style={styles.detailsContainer}>
                        <Text style={styles.detailsTitle}>Cancellation Details:</Text>

                        <View style={styles.section}>
                                <Text style={styles.sectionHeader}>Travel Information</Text>
                                <Text style={styles.detailText}>Operator : {cancellationDetails.operator}</Text>                           
                                <Text style={styles.detailText}>From City: {cancellationDetails.origin}</Text>
                                <Text style={styles.detailText}>To City: {cancellationDetails.destination}</Text>
                                <Text style={styles.detailText}>On: {cancellationDetails.travelDate}</Text>
                        </View>

                        <View style={styles.section}>
                                <Text style={styles.sectionHeader}>Contact Details</Text>
                                <Text style={styles.detailText}>Email: {cancellationDetails.contactEmail}</Text>
                                <Text style={styles.detailText}>Phone: {cancellationDetails.contactNumber}</Text>
                        </View>

                        <View style={styles.section}>
                                <Text style={styles.sectionHeader}>Cancellation Policy</Text>
                                {cancellationDetails.cancellationPolicy && cancellationDetails.cancellationPolicy.cancellationPenalty.map((cancelPenalty, index) => (
                                    <View key={index} style={styles.penaltyContainer}>
                                        <Text style={styles.detailText}>Between {cancelPenalty.from ? convertTo12HourFormat(cancelPenalty.from) : 'Departure'} and {cancelPenalty.to ? convertTo12HourFormat(cancelPenalty.to) : 'Booked Date/time'}:</Text>
                                        <Text style={styles.detailText}>
                                            {cancelPenalty.refundable ?
                                                `${cancelPenalty.chargeType === 'AMOUNT' ? '₹' : ''} ${cancelPenalty.cancellationCharge} ${cancelPenalty.chargeType === 'PERCENTAGE' ? '%' : ''} Charged`
                                                : 'Non Refundable'}
                                        </Text>
                                    </View>
                                ))}
                        </View>
                        <View style={styles.section}>
                                <Text style={styles.sectionHeader}>Passenger Details</Text>
                                <View style={styles.passengersContainer}>
                                {cancellationDetails.passengersInfo.map((passenger, index) => (
                                    <View key={index} style={styles.passengerContainer}>
                                    {passenger.seat.seatStatus === CANCELLATION_ALLOWED && (
                                        <>
                                            <CheckBox
                                                value={selectedPassengers.includes(passenger)}
                                                onValueChange={() => togglePassengerSelection(passenger)}
                                                style={styles.checkbox}
                                            />
                                            <Text style={{ color: 'green' }}>Cancellable</Text>
                                        </>
                                    )}
                                    {passenger.seat.seatStatus !== CANCELLATION_ALLOWED && (
                                        <Text style={{ color: 'red' }}>Non Refundable</Text>
                                    )}
                                    <Text style={styles.passengerHeader}>Passenger {index + 1}</Text>
                                    <Text style={styles.detailText}>Seat: {passenger.seat.seatNo}</Text>
                                    <Text style={styles.detailText}>First Name: {passenger.firstName}</Text>
                                    <Text style={styles.detailText}>Last Name: {passenger.lastName}</Text>
                                    <Text style={styles.detailText}>Age: {passenger.age}</Text>
                                    <Text style={styles.detailText}>Gender: {passenger.gender}</Text>
                                </View>
                                ))}
                                </View>
                            </View>

                            <View style={styles.section}>
                                <Text style={styles.sectionHeader}>Cancellation Charges</Text>
                                {selectedPassengers && selectedPassengers.map( (passenger, index) => (
                                    <View style={styles.passengerCharges}>
                                        <Text style={styles.detailText}>Seat: {passenger.seat.seatNo}</Text>
                                        <Text style={styles.detailText}>Fare: ₹ {passenger.seat.seatRate.baseFare}</Text>
                                        <Text style={styles.detailText}>Service Tax: ₹ {passenger.seat.seatRate.serviceTax}</Text>
                                        <Text style={styles.detailText}>Cancellation Charge: ₹ {passenger.seat.seatRate.cancellationCharges}</Text>
                                        <Text style={styles.detailText}>Refund Amount: ₹ {passenger.seat.seatRate.refundAmount}</Text>
                                    </View>
                                ))}
                                <Text style={[styles.detailText, styles.totalChargeText]}>Total Charges: ₹ {calculateCancellationCharges()}</Text>
                            </View>
                            <View style={styles.buttonContainer}>
                                <Button
                                    title="Confirm Cancel"
                                    onPress={() => confirmCancelSeats()}
                                />
                                
                            </View>
                    </View>
                </View>
            )}

            {showCancelConfirmation && showConfirmation && (
                <View>
                    <Text style={styles.title}>Cancel Confirmation</Text>
                    <View style={styles.detailsContainer}>
                        <Text style={styles.detailsTitle}>Cancelled</Text>
                        <Text style={styles.detailText}>Your refund will be processed in 5-7 business days.</Text>
                    </View>
                </View>
            )}

</View>

        </ScrollView>
        </ScreenLayout>
    );
}

const styles = StyleSheet.create({
    scrollContainer: {
        flexGrow: 1,
        alignItems: 'center',
    },
    container: {
        width: '90%',
        padding: 20,
    },
    title: {
        fontSize: 24,
        fontWeight: 'bold',
        marginBottom: 20,
        //fontFamily: 'Aldrich',
        textAlign: 'center',
    },
    input: {
        height: 40,
        width: '100%',
        borderColor: 'gray',
        borderWidth: 1,
        marginBottom: 20,
        paddingHorizontal: 10,
        borderRadius: 5,
       // fontFamily: 'Aldrich',
    },
    buttonContainer: {
        marginBottom: 20,
        flexDirection: 'row',
        justifyContent: 'space-evenly'
    },
    detailsContainer: {
        marginTop: 20,
        width: '100%',
    },
    button: {
        backgroundColor: '#007BFF',
        paddingVertical: 10,
        paddingHorizontal: 20,
        marginHorizontal: 10,
        borderRadius: 5,
    },
    buttonText: {
        fontSize: 16,
        color: 'white',
    },
    detailsTitle: {
        fontSize: 20,
        fontWeight: 'bold',
        marginBottom: 10,
       // fontFamily: 'Aldrich',
        textAlign: 'center',
    },
    detailsText: {
        marginBottom: 10,
        fontSize: 16,
       // fontFamily: 'Aldrich',
    },
    section: {
        marginBottom: 20,
        padding: 10,
        backgroundColor: '#f9f9f9',
        borderRadius: 5,
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.1,
        shadowRadius: 5,
        elevation: 2,
    },
    sectionHeader: {
        fontSize: 18,
        fontWeight: 'bold',
        marginBottom: 10,
        //fontFamily: 'Aldrich',
        borderBottomWidth: 1,
        borderBottomColor: 'lightgray',
        paddingBottom: 5,
    },
    detailText: {
        fontSize: 16,
        marginBottom: 5,
        //fontFamily: 'Aldrich',
    },
    totalChargeText:{
        borderTopWidth: 1,
        borderTopColor: 'lightgray',
    },
    passengersContainer:{
        flexDirection: 'row',
        flexWrap: 'wrap'
    },
    passengerContainer: {
        marginBottom: 10,
        marginHorizontal: 5
    },
    passengerHeader: {
        fontSize: 16,
        fontWeight: 'bold',
        marginBottom: 5,
        //fontFamily: 'Aldrich',
    },
    costContainer: {
        marginBottom: 5,
    },
    penaltyContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginVertical: 5,
    },
    passengerCharges:{
        marginBottom: 5,
    },
    retrieveButton: {
        paddingVertical: 10,
        paddingHorizontal: 20,
        borderRadius: 5,
        alignItems: 'center',
        marginVertical: 10,
        backgroundColor: '#5EBC67',
    },
    buttonText: {
        fontSize: 16,
        color: 'white',
    },
});

export default ManageReservation;