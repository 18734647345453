import React, { useState, useEffect, useRef } from "react";
import { View, FlatList, Text, StyleSheet, TouchableOpacity, Animated, Modal, Dimensions, ActivityIndicator, Platform } from "react-native";
import RatingStars from "./RatingStars";
import BusDetailsModal from "../modals/BusDetailsModal";
import { AmenityIcon } from "../common/FeatureComponent";
import SeatArrangement from "./SeatArrangement";
import { MaterialCommunityIcons, SimpleLineIcons, FontAwesome5, MaterialIcons } from '@expo/vector-icons';
import moment from 'moment';
import axios from "axios";
import { isMobile, isBrowser } from 'react-device-detect';
import { format } from 'date-fns';
import Constants from 'expo-constants';
import { useUser } from './UserContext';
import defaultBusLogo from '../assets/bygbus-icon.png';
const windowWidth = Dimensions.get('window').width;

const BUS_AVAILABILITY_URL = Constants.expoConfig.extra.apiUrl;
const API_TOKEN = Constants.expoConfig.extra.apiToken;

const COLORS = {
    primary: '#0066cc',            // Bright blue for CTAs and highlights
    success: '#34c759',            // Green for prices and availability
    accent: '#5856d6',             // Purple for accents
    warning: '#ff9500',            // Orange for warnings
    danger: '#ff3b30',             // Red for errors/alerts
    background: '#FFFFFF',
    text: {
        primary: '#1a1a1a',
        secondary: '#4A5568',
        light: '#718096',
        white: '#FFFFFF'
    },
    border: '#E5E7EB'
};

// Create a cross-platform Image component
const CrossPlatformImage = ({ source, style, onError }) => {
    if (Platform.OS === 'web') {
        return (
            <img
                src={typeof source === 'string' ? source : source.uri || defaultBusLogo}
                style={{
                    ...style,
                    objectFit: 'cover'
                }}
                onError={(e) => {
                    e.target.src = defaultBusLogo;
                    onError && onError(e);
                }}
            />
        );
    } else {
        const { Image } = require('react-native');
        return (
            <Image
                source={typeof source === 'string' ? { uri: source } : source}
                style={style}
                defaultSource={defaultBusLogo}
                onError={onError}
            />
        );
    }
};


const BusAvailabilityResults = ({ busAvailabilities, bookSummary, handleBookSummaryChange, onBook, onLoginRequest }) => {
    const [seatArrangement, setSeatArrangement] = useState(null);
    const [selectedBus, setSelectedBus] = useState(null);
    const [selectedSeats, setSelectedSeats] = useState([]);
    const [showBusDetails, setShowBusDetails] = useState(false);
    const [activeTab, setActiveTab] = useState('Features');
    const [showSeatArrangement, setShowSeatArrangement] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showTotalCostForItem, setShowTotalCostForItem] = useState({});
    const [serviceInfo, setSericeInfo] = useState({});
    const { user, userPoints } = useUser();

    const colorValue = useRef(new Animated.Value(0)).current;

    useEffect(() => {
        const animateColor = () => {
            Animated.loop(
                Animated.sequence([
                    Animated.timing(colorValue, {
                        toValue: 1,
                        duration: 1000,
                        useNativeDriver: false,
                    }),
                    Animated.timing(colorValue, {
                        toValue: 0,
                        duration: 1000,
                        useNativeDriver: false,
                    }),
                ])
            ).start();
        };
        animateColor();
    }, [colorValue]);


    const calculateRewardPointsRedemption = (basePrice) => {
        if (!bookSummary.useRewardPoints || !user?.userId) return null;

        // Convert price to paise (1 rupee = 100 paise)
        const basePricePaise = basePrice * 100;
        // Use all available points up to the total price in paise
        const pointsToUse = Math.min(basePricePaise, userPoints);
        // Convert points value to rupees (1 point = 1 paise = 0.01 rupees)
        const pointsDiscount = pointsToUse / 100;

        return {
            pointsToUse,
            pointsDiscount,
            remainingPrice: Math.max(0, basePrice - pointsDiscount)
        };
    };

    const calculateRewardPoints = (basePrice, discountedPrice, pointsUsed = 0) => {
        if (!user?.userId) return null;

        // Calculate price in paise, only on the amount actually paid
        const priceForReward = pointsUsed
            ? ((discountedPrice || basePrice) * 100) - pointsUsed  // Subtract points used (1 point = 1 paise)
            : ((discountedPrice || basePrice) * 100);

        return Math.max(0, Math.round(priceForReward * 0.01)); // 1% of price in paise
    };

    const getTotalPriceWithTaxes = (basePrice, totalPrice, priceDetails) => {
        if (!basePrice || !totalPrice) return null;

        const taxAmount = Number((totalPrice - basePrice).toFixed(2));

        if (priceDetails) {
            return Number((priceDetails.finalPrice + taxAmount).toFixed(2));
        }

        return Number(totalPrice.toFixed(2));
    };

    const calculateDiscount = (price) => {
        if (!bookSummary.validatedPromo?.valid || !price) return null;

        const promoDetails = bookSummary.validatedPromo;

        if (promoDetails.discountDetails.minimumBookingAmount &&
            price < promoDetails.discountDetails.minimumBookingAmount) {
            return null;
        }

        let discountAmount = 0;
        switch (promoDetails.discountDetails.discountType) {
            case 'PERCENTAGE':
                discountAmount = (price * promoDetails.discountDetails.discountValue) / 100;
                break;
            case 'FIXED':
                discountAmount = promoDetails.discountDetails.discountValue;
                break;
            default:
                return null;
        }

        if (promoDetails.discountDetails.maximumDiscount) {
            discountAmount = Math.min(discountAmount, promoDetails.discountDetails.maximumDiscount);
        }

        return {
            originalPrice: price,
            discountAmount: discountAmount,
            finalPrice: Math.max(0, price - discountAmount)
        };
    };

    const getLowestPrice = (seatTicketFares) => {
        if (!seatTicketFares || seatTicketFares.length === 0) return null;
        return seatTicketFares.reduce((lowest, current) => {
            const currentPrice = current.ticketFare.discountedBaseFare || current.ticketFare.baseFare;
            return !lowest || currentPrice < lowest ? currentPrice : lowest;
        }, null);
    };

    const getLowestTotalPrice = (seatTicketFares) => {
        if (!seatTicketFares || seatTicketFares.length === 0) return null;
        return seatTicketFares.reduce((lowest, current) => {
            const currentPrice = current.ticketFare.totalCost;
            return !lowest || currentPrice < lowest ? currentPrice : lowest;
        }, null);
    };

    const getTotalAvailableSeats = (seatTicketFares) => {
        if (!seatTicketFares) return 0;
        return seatTicketFares.reduce((total, fare) => total + fare.availableSeatCount, 0);
    };

    const handleCloseBusDetailModal = () => {
        setActiveTab(null);
        setShowBusDetails(false);
    };

    const handleDetailClickButton = (item, detail) => {
        setActiveTab(detail);
        setSelectedBus(item);
        setShowBusDetails(true);
    };

    const handleShowSeatArrangement = (busAvailability) => {
        setLoading(true);
        setShowSeatArrangement(true);
        setSelectedBus(busAvailability);
        setSelectedSeats([]);
        const updatedBookSummary = { ...bookSummary, busAvailability: busAvailability, seats: [] };
        handleBookSummaryChange(updatedBookSummary);
        handleSeatAvailability(busAvailability);
        setLoading(false);
    };

    const handleCloseSeatAlignment = () => {
        setSelectedBus(null);
        setSelectedSeats([]);
        setShowSeatArrangement(false);
        const updatedBookSummary = { ...bookSummary, busAvailability: null, seats: [] };
        handleBookSummaryChange(updatedBookSummary);
    };

    const handleToggleTotalCost = (item) => {
        setShowTotalCostForItem(prevState => ({
            ...prevState,
            [item.tripIdentifier]: !prevState[item.tripIdentifier]
        }));
    };

    const formatTime = (timeArray) => {
        const arrivalTime = new Date(timeArray[0], timeArray[1] - 1, timeArray[2], timeArray[3], timeArray[4]);
        return arrivalTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    };

    const handleSeatClick = (selectedSeat) => {
        setSelectedSeats((prevSelectedSeats) => {
            const isSeatSelected = prevSelectedSeats.some((seat) => seat.seatNo === selectedSeat.seatNo);
            let updatedSelectedSeats;
            if (isSeatSelected) {
                updatedSelectedSeats = prevSelectedSeats.filter((seat) => seat.seatNo !== selectedSeat.seatNo);
            } else {
                updatedSelectedSeats = [...prevSelectedSeats, selectedSeat];
            }

            const totalCost = calculateEstimatedTotalCost(updatedSelectedSeats);
            const updatedBookSummary = {
                ...bookSummary,
                seats: updatedSelectedSeats,
                totalCost: totalCost
            };
            handleBookSummaryChange(updatedBookSummary);
            return updatedSelectedSeats;
        });
    };

    const calculateEstimatedTotalCost = (seats) => {
        let totalCost = 0;
        seats.forEach((seat) => {
            totalCost += seat.seatRate.totalCost;
        });
        return totalCost;
    };

    const handleSeatAvailability = async (busAvailability) => {
        setSeatArrangement(null);
        try {
            const formattedDate = format(bookSummary.travelDate, 'yyyy-MM-dd');
            const response = await axios.get(`${BUS_AVAILABILITY_URL}/seatavailability`, {
                params: {
                    tripIdentifier: busAvailability.tripIdentifier,
                    serviceIdentifier: busAvailability.serviceIdentifier,
                    origin: bookSummary.origin,
                    destination: bookSummary.destination,
                    travelDate: formattedDate,
                    userId: user?.userId,
                    operator: busAvailability.operator,
                    vendorCode: busAvailability.vendorCode,
                    promoCode: bookSummary.validatedPromo ? bookSummary.validatedPromo.offerCode : null
                },
                headers: { 'x-api-token': API_TOKEN }
            });
            setSeatArrangement(response.data);
        } catch (error) {
            console.error(error);
        }
    };

    const getServiceInfo = async (serviceIdentifier) => {
        try {
            const response = await axios.get(`${BUS_AVAILABILITY_URL}/businfo/${serviceIdentifier}`, {
                headers: { 'x-api-token': API_TOKEN }
            });
            return response.data;
        } catch (error) {
            console.log(error);
            return null;
        }
    };

    const fetchAllServiceInfo = async () => {
        const serviceInfoMap = {};
        for (const item of busAvailabilities) {
            if (item.serviceIdentifier) {
                const serviceInfo = await getServiceInfo(item.serviceIdentifier);
                serviceInfoMap[item.serviceIdentifier] = serviceInfo;
            }
        }
        setSericeInfo(serviceInfoMap);
    };

    useEffect(() => {
        fetchAllServiceInfo();
    }, [busAvailabilities]);

    const renderRewardsSection = (basePrice, discountedPrice) => {
        const points = calculateRewardPoints(basePrice, discountedPrice);

        if (!user) {
            return (
                <TouchableOpacity
                    style={styles.rewardsContainer}
                    onPress={onLoginRequest}
                >
                    <MaterialIcons name="loyalty" size={16} color="#0066cc" />
                    <Text style={styles.rewardsText}>
                        Login to earn {points} reward points
                    </Text>
                </TouchableOpacity>
            );
        }

        return (
            <View style={styles.rewardsContainer}>
                <MaterialIcons name="loyalty" size={16} color="#34c759" />
                <Text style={styles.rewardsEarnText}>
                    Earn {points} reward points
                </Text>
            </View>
        );
    };

    const renderPriceSection = (basePrice, totalPrice, item) => {
        const priceDetails = calculateDiscount(basePrice);
        const pointsRedemption = calculateRewardPointsRedemption(priceDetails ? priceDetails.finalPrice : basePrice);
        const finalBasePrice = pointsRedemption ? pointsRedemption.remainingPrice : (priceDetails ? priceDetails.finalPrice : basePrice);
        const finalTotalPrice = getTotalPriceWithTaxes(basePrice, totalPrice, priceDetails);
        const earnablePoints = calculateRewardPoints(basePrice, priceDetails?.finalPrice, pointsRedemption?.pointsToUse);
    
        return (
            <View style={styles.priceContainer}>
                <View style={styles.priceAndSeatsRow}>
                    <View style={styles.priceInfoContainer}>
                        <Text style={[
                            styles.availableCount,
                            item.totalAvailableSeats === 0 && styles.soldOut
                        ]}>
                            {item.totalAvailableSeats === 0 ? 'Sold Out' : `Available ${item.totalAvailableSeats}`}
                        </Text>
    
                        {bookSummary.useRewardPoints && pointsRedemption ? (
                            <View>
                                <View style={styles.priceRow}>
                                    <Text style={styles.originalPrice}>₹{basePrice}</Text>
                                    <View style={styles.pointsRedemptionBadge}>
                                        <Text style={styles.pointsRedemptionText}>
                                            {pointsRedemption.pointsToUse} Points Used
                                        </Text>
                                    </View>
                                </View>
                                <Text style={styles.pointsValueText}>
                                    Points Value: ₹{pointsRedemption.pointsDiscount.toFixed(2)}
                                </Text>
                                <Text style={styles.discountedPrice}>
                                    Pay: ₹{finalBasePrice.toFixed(2)}
                                </Text>
                            </View>
                        ) : (
                            <View>
                                {priceDetails ? (
                                    <View>
                                        <View style={styles.priceRow}>
                                            <Text style={styles.originalPrice}>₹{basePrice}</Text>
                                            <View style={styles.discountBadge}>
                                                <Text style={styles.discountText}>Promo Applied</Text>
                                            </View>
                                        </View>
                                        <Text style={styles.discountedPrice}>₹{priceDetails.finalPrice}</Text>
                                    </View>
                                ) : (
                                    <Text style={styles.ticketFare}>Starting from ₹{basePrice}</Text>
                                )}
                            </View>
                        )}
    
                        {showTotalCostForItem[item.tripIdentifier] && (
                            <Text style={styles.taxInclusiveExclusive}>
                                ₹{finalTotalPrice} Incl Taxes
                            </Text>
                        )}
                    </View>
    
                </View>
    
                {!showTotalCostForItem[item.tripIdentifier] && (
                    <TouchableOpacity onPress={() => handleToggleTotalCost(item)}>
                        <Text style={styles.showTaxInclusiveText}>Show Cost Including Taxes</Text>
                    </TouchableOpacity>
                )}
    
                {renderRewardsSection(basePrice, priceDetails?.finalPrice)}

                {item.totalAvailableSeats > 0 && (
                        <TouchableOpacity
                            style={styles.checkSeatsButton}
                            onPress={() => showSeatArrangement && selectedBus?.tripIdentifier === item.tripIdentifier
                                ? handleCloseSeatAlignment()
                                : handleShowSeatArrangement(item)
                            }
                        >
                            <Text style={styles.showSeatAvailability}>
                                {showSeatArrangement && selectedBus?.tripIdentifier === item.tripIdentifier
                                    ? 'Hide Seats' : 'Check Seats'
                                }
                            </Text>
                        </TouchableOpacity>
                    )}
            </View>
        );
    };

    const renderOperatorInfo = (item) => {
        return (
            <View style={styles.operatorInfoContainer}>
                <View style={styles.operatorDetailsContainer}>
                    <Text style={styles.operator}>{item.operator}</Text>
                    <Text style={styles.bus}>{item.bus.name.length > 50
                        ? `${item.bus.name.slice(0, 50)}...`
                        : item.bus.name}</Text>
                    {item.serviceIdentifier && (
                        <RatingStars rating={serviceInfo[item.serviceIdentifier]?.overallRating || 0} />
                    )}
                </View>
            </View>
        );
    };

    const ResultsSummary = () => (
        <View style={styles.resultsSummaryContainer}>
            <View style={styles.resultsCountContainer}>
                <Text style={styles.resultsText}>
                    {busAvailabilities.length} {busAvailabilities.length === 1 ? 'Bus' : 'Buses'} found
                </Text>
                <Text style={styles.dateText}>
                    for {format(bookSummary.travelDate, 'dd MMM yyyy')}
                </Text>
            </View>
            <View style={styles.routeTextContainer}>
                <Text style={styles.routeText}>
                    {bookSummary.origin} → {bookSummary.destination}
                </Text>
            </View>
        </View>
    );

    const renderItem = ({ item }) => {
        const basePrice = getLowestPrice(item.seatTicketFares);
        const totalPrice = getLowestTotalPrice(item.seatTicketFares);

        return (
            <View style={[styles.fullBusAvailabilityContainer, { 
                backgroundColor: COLORS.background,
                borderLeftColor: COLORS.primary,
            }]}>
                <View style={[styles.busAvailabilityContainer, {
        backgroundColor: COLORS.primaryLight
    }]}>
                    {serviceInfo[item.serviceIdentifier] && serviceInfo[item.serviceIdentifier].isPopular && (
                        <View style={styles.popularBand}>
                            <Text style={styles.popularText}>Popular</Text>
                        </View>
                    )}
                    <View style={styles.leftContainer}>
                        {renderOperatorInfo(item)}
                        <View style={styles.amenitiesContainer}>
                            {item.amenities?.slice(0, 4).map((amenity, index) => (
                                <AmenityIcon key={index} amenity={amenity} size={12} color="black" />
                            ))}
                            {item.amenities?.length > 4 && (
                                <TouchableOpacity onPress={() => handleDetailClickButton(item, 'Features')}>
                                    <Text style={styles.showAllFeatures}>Show All</Text>
                                </TouchableOpacity>
                            )}
                        </View>
                    </View>
                    <View style={styles.middleContainer}>
                        <View style={styles.originDestinationContainer}>
                            <View style={styles.originContainer}>
                                <Text style={styles.locationText}>{item.origin}</Text>
                                <Text style={styles.timeText}>{formatTime(item.departureTime)}</Text>
                            </View>
                            <View style={styles.durationContainer}>
                                <MaterialCommunityIcons name="bus-side" size={16} color="#5EBC67" />
                                <Text style={styles.dottedLine}>-----</Text>
                                <Text style={styles.durationText}>{item.travelTime}</Text>
                                <Text style={styles.dottedLine}>-----</Text>
                                <MaterialCommunityIcons name="bus-side" size={16} color="#5EBC67" />
                            </View>
                            <View style={styles.destinationContainer}>
                                <Text style={styles.locationText}>{item.destination}</Text>
                                <Text style={styles.timeText}>{formatTime(item.arrivalTime)}</Text>
                            </View>
                        </View>
                        <View style={styles.detailsContainer}>
                            <TouchableOpacity style={styles.detailButton} onPress={() => handleDetailClickButton(item, 'Cancel Policy')}>
                                <Text style={styles.text}>Cancel Policy</Text>
                            </TouchableOpacity>
                            <TouchableOpacity style={styles.detailButton} onPress={() => handleDetailClickButton(item, 'Travel Policy')}>
                                <Text style={styles.text}>Travel Policy</Text>
                            </TouchableOpacity>
                            <TouchableOpacity style={styles.detailButton} onPress={() => handleDetailClickButton(item, 'Boarding DropOff Points')}>
                                <Text style={styles.text}>Boarding/DropOff Points</Text>
                            </TouchableOpacity>
                        </View>
                    </View>
                    <View style={styles.rightContainer}>
                        {renderPriceSection(basePrice, totalPrice, item)}
                    </View>
                </View>
                {loading && (
                    <View style={styles.loadingContainer}>
                        <ActivityIndicator size="large" color="#5EBC67" />
                        <FontAwesome5 name="bus" size={24} color="#5EBC67" style={styles.busIcon} />
                    </View>
                )}
                {showSeatArrangement && seatArrangement && selectedBus?.tripIdentifier === item.tripIdentifier && (
                    <View style={styles.seatArrangementContainer}>
                        <SeatArrangement
                            levels={seatArrangement.levels}
                            onSeatClick={handleSeatClick}
                            selectedSeats={selectedSeats}
                            onCancel={handleCloseSeatAlignment}
                            onBook={onBook}
                            useRewardPoints={bookSummary.useRewardPoints}
                        />
                    </View>
                )}
                {showBusDetails && selectedBus === item && (
                    <BusDetailsModal
                        activatedTab={activeTab}
                        closeModal={handleCloseBusDetailModal}
                        busDetails={selectedBus}
                    />
                )}
            </View>
        );
    };

    return (
        <View style={styles.resultsContainer}>
            <ResultsSummary />
            <FlatList
                key={`bus-list-${busAvailabilities.length}-${bookSummary.travelDate}`}
                style={styles.busAvailabilities}
                data={busAvailabilities}
                keyExtractor={(item, index) => index.toString()}
                showsVerticalScrollIndicator={false}
                renderItem={renderItem}
            />
        </View>
    );
};

const styles = StyleSheet.create({
    // Main Container Styles
    resultsContainer: {
        marginHorizontal: '2.5%',
        marginTop: 10,
        width: '95%',
        flexGrow: 1,
    },
    busAvailabilities: {
        width: '100%'
    },
    fullBusAvailabilityContainer: {
        backgroundColor: COLORS.background,
        borderRadius: 12,
        marginHorizontal: 10,
        marginVertical: 8,
        borderLeftWidth: 4,
        borderLeftColor: COLORS.primary,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.1,
        shadowRadius: 4,
        elevation: 3,
    },
    busAvailabilityContainer: {
        backgroundColor: COLORS.background,
        flexDirection: isMobile ? 'column' : 'row',
        flex: 1,
        flexWrap: "wrap",
        justifyContent: "center",
        borderRadius: 8
    },

    // Left Section Styles
    leftContainer: {
        alignItems: isMobile ? "center" : "flex-start",
        justifyContent: 'center',
        padding: 12,
        margin: 5,
        width: isMobile ? '100%' : '30%',
        borderRightWidth: isMobile ? 0 : 1,
        borderRightColor: COLORS.border,
        borderBottomWidth: isMobile ? 1 : 0,
        borderBottomColor: COLORS.border,
    },
    operatorInfoContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        marginBottom: 8,
    },
    operatorDetailsContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: isMobile ? 'center' : 'flex-start',
    },
    operator: {
        fontSize: 16,
        fontWeight: '600',
        color: COLORS.primary,
        marginBottom: 4,
        textAlign: isMobile ? 'center' : 'left',
    },
    bus: {
        fontSize: 13,
        color: COLORS.text.secondary,
        marginBottom: 4,
        textAlign: isMobile ? 'center' : 'left',
    },

    // Middle Section Styles
    middleContainer: {
        width: isMobile ? '100%' : 'auto',
        justifyContent: 'center',
        paddingHorizontal: 12,
        paddingVertical: isMobile ? 16 : 12,
        borderRightWidth: isMobile ? 0 : 1,
        borderRightColor: COLORS.border,
        borderBottomWidth: isMobile ? 1 : 0,
        borderBottomColor: COLORS.border,
    },
    originDestinationContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        padding: 5,
        flexWrap: isMobile ? 'wrap' : 'nowrap',
    },
    originContainer: {
        alignItems: 'center',
        width: isMobile ? '30%' : 'auto',
    },
    destinationContainer: {
        alignItems: 'center',
        width: isMobile ? '30%' : 'auto',
    },
    locationText: {
        fontSize: 14,
        color: COLORS.text.secondary,
        textAlign: 'center',
    },
    timeText: {
        fontSize: 14,
        color: COLORS.primary,
        fontWeight: '600',
        marginTop: 4,
    },
    durationContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingHorizontal: 10,
        width: isMobile ? '40%' : 'auto',
        justifyContent: 'center',
    },
    durationText: {
        fontSize: 13,
        color: COLORS.accent,
        marginHorizontal: 8,
    },
    dottedLine: {
        color: COLORS.border,
    },

    // Right Container Styles
    rightContainer: {
        width: isMobile ? '100%' : 'auto',
        padding: isMobile ? 16 : 12,
        justifyContent: 'center',
        alignItems: 'center',
        minWidth: isMobile ? '100%' : 200,
    },
    priceContainer: {
        padding: 12,
        width: '100%',
        backgroundColor: COLORS.background,
        alignItems: isMobile ? 'center' : 'flex-start',
    },
    priceAndSeatsRow: {
        width: '100%',
        alignItems: isMobile ? 'center' : 'flex-start',
    },
    priceInfoContainer: {
        width: '100%',
        alignItems: isMobile ? 'center' : 'flex-start',
    },
    priceRow: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 4,
        justifyContent: isMobile ? 'center' : 'flex-start',
    },
    originalPrice: {
        fontSize: 16,
        color: COLORS.text.secondary,
        textDecorationLine: 'line-through',
        marginRight: 8,
    },
    discountedPrice: {
        fontSize: 18,
        color: COLORS.success,
        fontWeight: '700',
        letterSpacing: 0.5,
        textAlign: isMobile ? 'center' : 'left',
    },
    ticketFare: {
        fontSize: 16,
        color: COLORS.text.primary,
        fontWeight: '700',
        letterSpacing: 0.5,
        textAlign: isMobile ? 'center' : 'left',
    },

    // Amenities Styles
    amenitiesContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 8,
        gap: 8,
        width: isMobile ? '100%' : 'auto',
    },
    showAllFeatures: {
        fontSize: 12,
        color: COLORS.primary,
        marginLeft: 4,
    },

    // Details Container Styles
    detailsContainer: {
        flexDirection: isMobile ? 'column' : 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        paddingTop: 10,
        borderTopWidth: 1,
        borderTopColor: COLORS.border,
        marginTop: 10,
        gap: isMobile ? 8 : 0,
    },
    detailButton: {
        marginHorizontal: isMobile ? 0 : 8,
        paddingHorizontal: 12,
        paddingVertical: 6,
        borderRadius: 16,
        backgroundColor: COLORS.background,
        borderWidth: 1,
        borderColor: COLORS.border,
        width: isMobile ? '100%' : 'auto',
    },
    text: {
        fontSize: 12,
        color: COLORS.text.secondary,
        textDecorationLine: 'underline',
        textAlign: isMobile ? 'center' : 'left',
    },

    // Check Seats Button Styles
    checkSeatsButton: {
        width: '100%',
        marginTop: 12,
        marginLeft: 0,
    },
    showSeatAvailability: {
        backgroundColor: '#34D399',
        color: COLORS.text.white,
        paddingVertical: 8,
        paddingHorizontal: 16,
        borderRadius: 8,
        textAlign: 'center',
        overflow: 'hidden',
        fontSize: 14,
        fontWeight: '600',
        elevation: 2,
        shadowColor: "#000",
        shadowOffset: { width: 0, height: 1 },
        shadowOpacity: 0.2,
        shadowRadius: 1.41,
    },

    // Badge and Status Styles
    availableCount: {
        fontSize: 14,
        marginBottom: 8,
        color: COLORS.success,
        fontWeight: '500',
        textAlign: isMobile ? 'center' : 'left',
    },
    soldOut: {
        backgroundColor: '#ffebee',
        color: COLORS.danger,
        fontWeight: '600'
    },
    discountBadge: {
        backgroundColor: `${COLORS.success}15`,
        paddingHorizontal: 8,
        paddingVertical: 4,
        borderRadius: 12,
    },
    discountText: {
        color: COLORS.success,
        fontSize: 12,
        fontWeight: '600',
    },
    pointsRedemptionBadge: {
        backgroundColor: `${COLORS.accent}15`,
        paddingHorizontal: 8,
        paddingVertical: 4,
        borderRadius: 12,
        marginLeft: 8,
    },
    pointsRedemptionText: {
        color: COLORS.accent,
        fontSize: 12,
        fontWeight: '600',
    },

    // Price Related Text Styles
    pointsValueText: {
        fontSize: 12,
        color: COLORS.text.secondary,
        marginTop: 2,
        textAlign: isMobile ? 'center' : 'left',
    },
    minBookingWarning: {
        fontSize: 11,
        color: COLORS.danger,
        marginTop: 2,
        textAlign: isMobile ? 'center' : 'left',
    },
    taxInclusiveExclusive: {
        fontSize: 12,
        color: COLORS.text.secondary,
        marginTop: 4,
        textAlign: isMobile ? 'center' : 'left',
    },
    showTaxInclusiveText: {
        fontSize: 12,
        color: COLORS.primary,
        marginTop: 8,
        fontWeight: '500',
        textAlign: isMobile ? 'center' : 'left',
    },

    // Rewards Section Styles
    rewardsContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: isMobile ? 'center' : 'flex-start',
        marginTop: 8,
        padding: 4,
        width: '100%',
    },
    rewardsText: {
        fontSize: 12,
        color: COLORS.primary,
        marginLeft: 4,
        fontWeight: '500',
    },
    rewardsEarnText: {
        fontSize: 12,
        color: COLORS.success,
        marginLeft: 4,
        fontWeight: '500',
    },

    // Loading Styles
    loadingContainer: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
    },
    busIcon: {
        marginTop: 10,
    },

    // Popular Badge Styles
    popularBand: {
        position: 'absolute',
        top: 15,
        left: -30,
        backgroundColor: COLORS.warning,
        paddingVertical: 4,
        paddingHorizontal: 30,
        transform: [{ rotate: '-45deg' }],
        zIndex: 1,
    },
    popularText: {
        color: COLORS.text.white,
        fontSize: 11,
        fontWeight: '600',
        letterSpacing: 0.5,
    },

    // Results Summary Styles
    resultsSummaryContainer: {
        backgroundColor: COLORS.background,
        padding: 16,
        borderRadius: 12,
        marginBottom: 10,
        shadowColor: "#000",
        shadowOffset: { width: 0, height: 1 },
        shadowOpacity: 0.1,
        shadowRadius: 2,
        elevation: 2,
    },
    resultsCountContainer: {
        flexDirection: isMobile ? 'column' : 'row',
        alignItems: 'center',
        marginBottom: 4,
    },
    resultsText: {
        fontSize: 16,
        fontWeight: '600',
        color: COLORS.primary,
        textAlign: isMobile ? 'center' : 'left',
    },
    dateText: {
        fontSize: 14,
        color: COLORS.text.secondary,
        marginLeft: isMobile ? 0 : 8,
        marginTop: isMobile ? 4 : 0,
        textAlign: isMobile ? 'center' : 'left',
    },
    routeTextContainer: {
        marginTop: 4,
    },
    routeText: {
        fontSize: 14,
        color: COLORS.text.secondary,
        textAlign: isMobile ? 'center' : 'left',
    },
    seatArrangementContainer: {
        width: '100%',
        backgroundColor: '#fff',
        borderBottomLeftRadius: 12,
        borderBottomRightRadius: 12,
        padding: 16,
        borderTopWidth: 1,
        borderTopColor: COLORS.border,
    }
});

export default BusAvailabilityResults;