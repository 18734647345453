import React from 'react';
import { View, Text, StyleSheet, TouchableOpacity, Modal, ScrollView } from 'react-native';

const ContactUSModal = ({ isVisible, closeModal }) => {
  return (
    <Modal visible={isVisible} onRequestClose={closeModal} animationType="slide" transparent={true}>
      <View style={styles.modalContainer}>
        <View style={styles.modalContent}>
          <Text style={styles.title}>Contact Us</Text>
          <ScrollView style={styles.scrollView}>
            <Text style={styles.text}>
              Last updated on Jul 6 2024
              {'\n\n'}
              You may contact us using the information below:
              {'\n\n'}
              Merchant Legal entity name: BYGBUS
              {'\n'}
              Registered Address: Flat No B 214,Subishi Gowthami,Kompally,Secunderabad Hyderabad Telangana 500014
              {'\n'}
              Operational Address: Flat No B 214,Subishi Gowthami,Kompally,Secunderabad Hyderabad Telangana 500014
              {'\n'}
              Telephone No: 8179757744
              {'\n'}
              E-Mail ID: support@bygbus.com
            </Text>
          </ScrollView>
          <TouchableOpacity onPress={closeModal} style={styles.closeButtonContainer}>
            <Text style={styles.closeButton}>Close</Text>
          </TouchableOpacity>
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  modalContent: {
    backgroundColor: '#f2f2f2',
    padding: 20,
    borderRadius: 10,
    width: '90%',
    maxHeight: '80%',
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 10,
    textAlign: 'center',
  },
  scrollView: {
    marginBottom: 20,
  },
  text: {
    fontSize: 16,
    marginBottom: 10,
  },
  closeButtonContainer: {
    alignItems: 'center',
  },
  closeButton: {
    color: 'blue',
    fontSize: 18,
    textDecorationLine: 'underline',
  },
});

export default ContactUSModal;
