import React, { useState } from 'react';
import { View, Text, StyleSheet, TouchableOpacity, Modal, ScrollView, useWindowDimensions } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import { AmenityIcon } from "../common/FeatureComponent";

const BusDetailsModal = ({ closeModal, busDetails, activatedTab }) => {
  const [activeTab, setActiveTab] = useState(activatedTab);
  const { width, height } = useWindowDimensions();

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  const convertTo12HourFormat = (dateTime) => {
    const date = new Date(dateTime.replace(' ', 'T'));
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12;
    const minutesStr = minutes < 10 ? '0' + minutes : minutes;
    return `${day}/${month}/${year} ${hours}:${minutesStr} ${ampm}`;
  };

  return (
    <Modal
      animationType="slide"
      transparent={true}
      visible={true}
      onRequestClose={closeModal}
    >
      <View style={styles.modalContainer}>
        <View style={[styles.modalContent, { width: width * 0.9, height: height * 0.85 }]}>
          <TouchableOpacity style={styles.closeButton} onPress={closeModal}>
            <View style={styles.closeButtonCircle}>
              <Ionicons name="close" size={24} color="#FFFFFF" />
            </View>
          </TouchableOpacity>
          
          <View style={styles.headerSection}>
            <Text style={styles.operatorText}>{busDetails.operator}</Text>
            <Text style={styles.busNameText}>{busDetails.bus.name}</Text>
          </View>

          <View style={styles.tabContainer}>
            {['Features', 'Cancel Policy', 'Travel Policy', 'Boarding DropOff Points'].map((tab) => (
              <TouchableOpacity
                key={tab}
                style={[styles.tabButton, activeTab === tab && styles.activeTab]}
                onPress={() => handleTabClick(tab)}
              >
                <Text style={[styles.tabText, activeTab === tab && styles.activeTabText]}>
                  {tab}
                </Text>
              </TouchableOpacity>
            ))}
          </View>

          <ScrollView style={styles.tabContent}>
            {activeTab === 'Features' && (
              <View style={styles.amenitiesContainer}>
                {busDetails.amenities && busDetails.amenities.map((amenity, index) => (
                  <View key={index} style={styles.amenityItem}>
                    <AmenityIcon amenity={amenity} size={36} color="#2C3E50" />
                  </View>
                ))}
              </View>
            )}

            {activeTab === 'Cancel Policy' && (
              <View style={styles.cancellationPolicyContainer}>
                <Text style={styles.sectionTitle}>Cancellation Policy</Text>
                {busDetails.cancellationPolicy ? (
                  <>
                    <View style={styles.refundableStatus}>
                      <Text style={styles.refundableText}>
                        Refundable: {busDetails.cancellationPolicy.refundable ? 'Yes' : 'No'}
                      </Text>
                    </View>
                    {busDetails.cancellationPolicy.cancellationPenalty?.length > 0 ? (
                      [...busDetails.cancellationPolicy.cancellationPenalty].reverse().map((cancelPenalty, index) => (
                        <View key={index} style={styles.penaltyCard}>
                          <Text style={styles.penaltyTimeframe}>
                            Between {cancelPenalty.to ? convertTo12HourFormat(cancelPenalty.to) : 'Booked Date/time'} and {' '}
                            {cancelPenalty.from ? convertTo12HourFormat(cancelPenalty.from) : 'Departure'}
                          </Text>
                          <Text style={styles.penaltyAmount}>
                            {cancelPenalty.refundable ?
                              `${cancelPenalty.chargeType === 'AMOUNT' ? '₹' : ''} ${cancelPenalty.cancellationCharge} ${cancelPenalty.chargeType === 'PERCENTAGE' ? '%' : ''} Charged`
                              : 'Non Refundable'}
                          </Text>
                        </View>
                      ))
                    ) : (
                      <Text style={styles.noDataText}>No penalties available.</Text>
                    )}
                  </>
                ) : (
                  <Text style={styles.noDataText}>No cancellation policy available.</Text>
                )}
              </View>
            )}

            {activeTab === 'Travel Policy' && (
              <View style={styles.travelPolicyContainer}>
                <Text style={styles.sectionTitle}>Travel Policy</Text>
                <View style={styles.policyWrapper}>
                  <View style={styles.policyIconContainer}>
                    <Ionicons name="id-card-outline" size={20} color="#3498DB" />
                  </View>
                  <Text style={styles.policyText}>Please carry a valid ID proof during the journey.</Text>
                </View>
                <View style={styles.policyDivider} />
                <View style={styles.policyWrapper}>
                  <View style={styles.policyIconContainer}>
                    <Ionicons name="time-outline" size={20} color="#3498DB" />
                  </View>
                  <Text style={styles.policyText}>Reach your boarding point at least 15 minutes before departure.</Text>
                </View>
                <View style={styles.policyDivider} />
                <View style={styles.policyWrapper}>
                  <View style={styles.policyIconContainer}>
                    <Ionicons name="call-outline" size={20} color="#3498DB" />
                  </View>
                  <Text style={styles.policyText}>For any queries, please contact our support team.</Text>
                </View>
              </View>
            )}

            {activeTab === 'Boarding DropOff Points' && (
              <View style={styles.boardingDropOffContainer}>
                <View style={styles.pointsRow}>
                  <View style={styles.pointsSection}>
                    <View style={styles.pointsHeader}>
                      <Ionicons name="location" size={20} color="#3498DB" />
                      <Text style={styles.sectionTitle}>Boarding Points</Text>
                    </View>
                    {busDetails.boardingPoints.map((item, index) => {
                      const arrivalTimeArray = item.arrivalTime;
                      const arrivalTime = new Date(
                        arrivalTimeArray[0],
                        arrivalTimeArray[1] - 1,
                        arrivalTimeArray[2],
                        arrivalTimeArray[3],
                        arrivalTimeArray[4]
                      );
                      const formattedArrivalTime = arrivalTime.toLocaleTimeString([], {
                        hour: '2-digit',
                        minute: '2-digit'
                      });

                      return (
                        <View key={index} style={styles.pointCard}>
                          <View style={styles.timeContainer}>
                            <Ionicons name="time-outline" size={16} color="#3498DB" />
                            <Text style={styles.timeText}>{formattedArrivalTime}</Text>
                          </View>
                          <View style={styles.pointInfo}>
                            <Text style={styles.pointName}>{item.pointName}</Text>
                            {item.landMark && (
                              <View style={styles.landMarkContainer}>
                                <Ionicons name="navigate-outline" size={14} color="#7F8C8D" />
                                <Text style={styles.landMark}>{item.landMark}</Text>
                              </View>
                            )}
                          </View>
                        </View>
                      );
                    })}
                  </View>

                  <View style={styles.dividerLine} />

                  <View style={styles.pointsSection}>
                    <View style={styles.pointsHeader}>
                      <Ionicons name="location" size={20} color="#E74C3C" />
                      <Text style={[styles.sectionTitle, { color: '#E74C3C' }]}>DropOff Points</Text>
                    </View>
                    {busDetails.dropOffPoints.map((item, index) => {
                      const arrivalTimeArray = item.arrivalTime;
                      const arrivalTime = new Date(
                        arrivalTimeArray[0],
                        arrivalTimeArray[1] - 1,
                        arrivalTimeArray[2],
                        arrivalTimeArray[3],
                        arrivalTimeArray[4]
                      );
                      const formattedArrivalTime = arrivalTime.toLocaleTimeString([], {
                        hour: '2-digit',
                        minute: '2-digit'
                      });

                      return (
                        <View key={index} style={[styles.pointCard, styles.dropOffCard]}>
                          <View style={styles.timeContainer}>
                            <Ionicons name="time-outline" size={16} color="#E74C3C" />
                            <Text style={[styles.timeText, { color: '#E74C3C' }]}>{formattedArrivalTime}</Text>
                          </View>
                          <View style={styles.pointInfo}>
                            <Text style={styles.pointName}>{item.pointName}</Text>
                            {item.landMark && (
                              <View style={styles.landMarkContainer}>
                                <Ionicons name="navigate-outline" size={14} color="#7F8C8D" />
                                <Text style={styles.landMark}>{item.landMark}</Text>
                              </View>
                            )}
                          </View>
                        </View>
                      );
                    })}
                  </View>
                </View>
              </View>
            )}
          </ScrollView>
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  // Modal Container Styles
  modalContainer: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  modalContent: {
      backgroundColor: '#FFFFFF',
      borderRadius: 20,
      padding: 20,
      elevation: 5,
      shadowColor: '#000',
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.25,
      shadowRadius: 3.84,
  },

  // Header Styles
  headerSection: {
      alignItems: 'center',
      marginTop: 10,
      marginBottom: 20,
      paddingHorizontal: 20,
  },
  operatorText: {
      fontSize: 24,
      fontWeight: 'bold',
      color: '#2C3E50',
      marginBottom: 5,
  },
  busNameText: {
      fontSize: 18,
      color: '#7F8C8D',
  },
  closeButton: {
      position: 'absolute',
      top: 15,
      right: 15,
      zIndex: 1,
  },
  closeButtonCircle: {
      backgroundColor: '#FF5252',
      borderRadius: 20,
      padding: 8,
  },

  // Tab Styles
  tabContainer: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      backgroundColor: '#F8F9FA',
      borderRadius: 15,
      padding: 5,
      marginBottom: 20,
  },
  tabButton: {
      padding: 12,
      borderRadius: 10,
      minWidth: 100,
      alignItems: 'center',
  },
  activeTab: {
      backgroundColor: '#3498DB',
  },
  tabText: {
      fontSize: 14,
      color: '#2C3E50',
  },
  activeTabText: {
      color: '#FFFFFF',
      fontWeight: 'bold',
  },
  tabContent: {
      backgroundColor: '#F8F9FA',
      borderRadius: 15,
      padding: 15,
  },

  // Features/Amenities Styles
  amenitiesContainer: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      padding: 10,
  },
  amenityItem: {
      backgroundColor: '#FFFFFF',
      padding: 15,
      borderRadius: 12,
      margin: 8,
      elevation: 2,
      shadowColor: '#000',
      shadowOffset: { width: 0, height: 1 },
      shadowOpacity: 0.2,
      shadowRadius: 1.41,
  },

  // Cancellation Policy Styles
  cancellationPolicyContainer: {
      padding: 15,
  },
  refundableStatus: {
      backgroundColor: '#E8F5E9',
      padding: 10,
      borderRadius: 8,
      marginBottom: 15,
  },
  refundableText: {
      color: '#2E7D32',
      fontSize: 16,
      fontWeight: '600',
  },
  penaltyCard: {
      backgroundColor: '#FFFFFF',
      padding: 15,
      borderRadius: 12,
      marginBottom: 10,
      elevation: 2,
      shadowColor: '#000',
      shadowOffset: { width: 0, height: 1 },
      shadowOpacity: 0.2,
      shadowRadius: 1.41,
  },
  penaltyTimeframe: {
      fontSize: 14,
      color: '#34495E',
      marginBottom: 8,
  },
  penaltyAmount: {
      fontSize: 16,
      color: '#E74C3C',
      fontWeight: '600',
  },

  // Travel Policy Styles
  travelPolicyContainer: {
      padding: 15,
  },
  policyWrapper: {
      flexDirection: 'row',
      alignItems: 'center',
      paddingVertical: 10,
      paddingHorizontal: 15,
  },
  policyIconContainer: {
      width: 32,
      height: 32,
      borderRadius: 16,
      backgroundColor: '#EBF5FB',
      justifyContent: 'center',
      alignItems: 'center',
  },
  policyText: {
      fontSize: 14,
      color: '#2C3E50',
      flex: 1,
      marginLeft: 12,
  },
  policyDivider: {
      height: 1,
      backgroundColor: '#E8E8E8',
      marginHorizontal: 15,
  },

  // Boarding/DropOff Points Styles
  boardingDropOffContainer: {
      padding: 15,
  },
  pointsRow: {
      flexDirection: 'row',
      justifyContent: 'space-between',
  },
  pointsSection: {
      flex: 1,
      paddingHorizontal: 10,
  },
  pointsHeader: {
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: 20,
      gap: 8,
  },
  sectionTitle: {
      fontSize: 18,
      fontWeight: 'bold',
      color: '#3498DB',
  },
  dividerLine: {
      width: 1,
      backgroundColor: '#E0E0E0',
      marginHorizontal: 20,
  },
  pointCard: {
      backgroundColor: '#FFFFFF',
      padding: 15,
      borderRadius: 12,
      marginBottom: 15,
      borderLeftWidth: 3,
      borderLeftColor: '#3498DB',
      elevation: 2,
      shadowColor: '#000',
      shadowOffset: { width: 0, height: 1 },
      shadowOpacity: 0.1,
      shadowRadius: 2,
  },
  dropOffCard: {
      borderLeftColor: '#E74C3C',
  },
  timeContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: 8,
      gap: 6,
  },
  timeText: {
      fontSize: 15,
      color: '#3498DB',
      fontWeight: '600',
  },
  pointInfo: {
      marginLeft: 22,
  },
  pointName: {
      fontSize: 16,
      fontWeight: '600',
      color: '#2C3E50',
      marginBottom: 6,
  },
  landMarkContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      gap: 4,
  },
  landMark: {
      fontSize: 13,
      color: '#7F8C8D',
      flex: 1,
  },

  // Common/Utility Styles
  noDataText: {
      fontSize: 16,
      color: '#95A5A6',
      textAlign: 'center',
      marginTop: 20,
  }
});

export default BusDetailsModal;