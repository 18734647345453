import React, { useState } from 'react';
import { View, Text, TouchableOpacity, StyleSheet, FlatList, ScrollView, Dimensions } from 'react-native';
import Hoverable from 'react-native-hoverable';
import { isMobile } from 'react-device-detect';
import Seat from './Seat';
import { MaterialCommunityIcons, Ionicons } from '@expo/vector-icons';
import { useUser } from './UserContext';

const SeatArrangement = ({ levels, onSeatClick, selectedSeats, onCancel, onBook, useRewardPoints = false }) => {
    const [showTooltip, setShowTooltip] = useState(null);
    const { user, userPoints } = useUser();

    const getSeatType = (seat) => {
        switch (seat.seatType) {
            case 'REGULAR': return 'Regular Seat';
            case 'SINGLE_REGULAR': return 'Single Seat';
            case 'PUSHBACK': return 'Pushback';
            case 'SEMI_SLEEPER': return 'Semi Sleeper';
            case 'SLEEPER': return 'Sleeper';
            case 'SINGLE_LOWER_SLEEPER': return 'Single Lower';
            case 'SINGLE_UPPER_SLEEPER': return 'Single Upper';
            case 'LOWER_SLEEPER': return 'Lower Sleeper';
            case 'UPPER_SLEEPER': return 'Upper Sleeper';
            default: return 'Seat';
        }
    };

    const isSleeper = (seat) => {
        return ['SLEEPER', 'SINGLE_LOWER_SLEEPER', 'SINGLE_UPPER_SLEEPER', 
                'LOWER_SLEEPER', 'UPPER_SLEEPER'].includes(seat.seatType);
    };

    const calculateEstimatedTotalCost = () => {
        if (!selectedSeats?.length) {
            return {
                basePrice: 0,
                discountAmount: 0,
                totalTaxes: 0,
                totalCost: 0,
                pointsApplied: 0,
                finalAmount: 0
            };
        }

        const initialCosts = selectedSeats.reduce((acc, seat) => ({
            basePrice: acc.basePrice + (seat.seatRate.baseFare || 0),
            discountAmount: acc.discountAmount + (seat.seatRate.discount || 0),
            totalTaxes: acc.totalTaxes + (seat.seatRate.totalTax || 0),
            totalCost: acc.totalCost + (seat.seatRate.totalCost || 0)
        }), {
            basePrice: 0,
            discountAmount: 0,
            totalTaxes: 0,
            totalCost: 0
        });

        if (useRewardPoints && userPoints > 0) {
            const totalCostPaise = initialCosts.totalCost * 100;
            const pointsToApply = Math.min(userPoints, totalCostPaise);
            const pointsValueInRupees = pointsToApply / 100;

            return {
                ...initialCosts,
                pointsApplied: pointsToApply,
                pointsValue: pointsValueInRupees,
                finalAmount: Math.max(0, initialCosts.totalCost - pointsValueInRupees)
            };
        }

        return {
            ...initialCosts,
            pointsApplied: 0,
            pointsValue: 0,
            finalAmount: initialCosts.totalCost
        };
    };

    const renderSeats = (level) => {
        try {
            const seatGrid = Array(level.maxRows).fill(null)
                .map(() => Array(level.maxColumns).fill(null));
    
            // Fill the grid with all seats
            level.seats.forEach(seat => {
                if (seat.row > 0 && seat.col > 0 && 
                    seat.row <= level.maxRows && 
                    seat.col <= level.maxColumns) {
                    seatGrid[seat.row - 1][seat.col - 1] = {
                        ...seat,
                        isWide: isSleeper(seat)
                    };
                }
            });
    
            const isEmptyColumn = (colIndex) => {
                return seatGrid.every(row => !row[colIndex] || 
                    ['FREESPACE', 'WIDER_FREESPACE'].includes(row[colIndex]?.seatType));
            };
    
            // First find rows that contain horizontal seats
            const rowsWithHorizontalSeats = new Set();
            seatGrid.forEach((row, rowIndex) => {
                if (row.some(seat => seat?.orientation === 1)) {
                    rowsWithHorizontalSeats.add(rowIndex);
                }
            });
    
            const renderSeatSection = (isHorizontal) => {
                return (
                    <View style={styles.seatsGrid}>
                        {[...Array(level.maxColumns)].map((_, colIndex) => {
                            if (isEmptyColumn(colIndex)) {
                                return (
                                    <View key={`empty-col-${isHorizontal ? 'h-' : ''}${colIndex}`} 
                                        style={styles.emptySeatColumn}>
                                        <View style={styles.walkway} />
                                    </View>
                                );
                            }
    
                            const columnSeats = seatGrid
                                .map((row, rowIndex) => {
                                    const seat = row[colIndex];
                                    if (!seat) return null;
                                    
                                    // For regular section, skip rows with horizontal seats
                                    if (!isHorizontal && rowsWithHorizontalSeats.has(rowIndex)) {
                                        return null;
                                    }
                                    // For horizontal section, only include rows with horizontal seats
                                    if (isHorizontal && !rowsWithHorizontalSeats.has(rowIndex)) {
                                        return null;
                                    }
    
                                    return { ...seat, rowIndex };
                                })
                                .filter(Boolean);
    
                            return columnSeats.length > 0 ? (
                                <View key={`${isHorizontal ? 'horizontal' : 'regular'}-${colIndex}`} 
                                    style={styles.seatColumn}>
                                    {columnSeats.map(seat => {
                                        if (['FREESPACE', 'WIDER_FREESPACE'].includes(seat.seatType)) {
                                            return (
                                                <View 
                                                    key={`space-${isHorizontal ? 'h-' : ''}${seat.rowIndex}-${colIndex}`}
                                                    style={seat.seatType === 'WIDER_FREESPACE' ? 
                                                        styles.widerEmptySeat : styles.emptySeat}
                                                />
                                            );
                                        }
    
                                        return (
                                            <View 
                                                key={`seat-${isHorizontal ? 'h-' : ''}${seat.rowIndex}-${colIndex}`} 
                                                style={[
                                                    styles.seatWrapper,
                                                    isHorizontal && styles.verticalSeatWrapper,
                                                    isHorizontal && seat.isWide && styles.verticalWideSeatWrapper
                                                ]}>
                                                <Hoverable
                                                    onHoverIn={() => setShowTooltip(seat.seatNo)}
                                                    onHoverOut={() => setShowTooltip(null)}>
                                                    <View>
                                                        <Seat
                                                            seat={seat}
                                                            onSeatClick={onSeatClick}
                                                            isSelected={selectedSeats?.some(
                                                                s => s.seatNo === seat.seatNo)}
                                                        />
                                                        {showTooltip === seat.seatNo && (
                                                            <View style={styles.tooltipContainer}>
                                                                <Text style={styles.tooltipText}>
                                                                    {`${getSeatType(seat)}\n₹${seat.seatRate.baseFare}`}
                                                                    {seat.seatRate.discountedBaseFare < 
                                                                        seat.seatRate.baseFare &&
                                                                        ` → ₹${Math.floor(
                                                                            seat.seatRate.discountedBaseFare)}`}
                                                                </Text>
                                                            </View>
                                                        )}
                                                    </View>
                                                </Hoverable>
                                            </View>
                                        );
                                    })}
                                </View>
                            ) : null;
                        })}
                    </View>
                );
            };
    
            return (
                <View style={styles.levelSeatsContainer}>
                    {/* Regular Seats Section */}
                    {renderSeatSection(false)}
                    
                    {/* Horizontal Seats Section */}
                    <View style={styles.horizontalSeatsSection}>
                        {renderSeatSection(true)}
                    </View>
                </View>
            );
        } catch (error) {
            console.error('Error rendering seats:', error);
            return (
                <View style={styles.errorContainer}>
                    <Text style={styles.errorText}>Error loading seat layout</Text>
                </View>
            );
        }
    };

    const renderLegend = () => (
        <View style={styles.legendContainer}>
            <View style={styles.legendRow}>
                <View style={styles.legendItem}>
                    <View style={[styles.legendSample, styles.availableSample]} />
                    <Text style={styles.legendText}>Available</Text>
                </View>
                <View style={styles.legendItem}>
                    <View style={[styles.legendSample, styles.maleSample]} />
                    <Text style={styles.legendText}>Available Male Only</Text>
                </View>
                <View style={styles.legendItem}>
                    <View style={[styles.legendSample, styles.femaleSample]} />
                    <Text style={styles.legendText}>Available Female Only</Text>
                </View>
                <View style={styles.legendItem}>
                    <View style={[styles.legendSample, styles.selectedSample]} />
                    <Text style={styles.legendText}>Selected</Text>
                </View>
                <View style={styles.legendItem}>
                    <View style={[styles.legendSample, styles.unavailableSample]} />
                    <Text style={styles.legendText}>Booked</Text>
                </View>
                <View style={styles.legendItem}>
                    <View style={[styles.legendSample, styles.maleOccupiedSample]} />
                    <Text style={styles.legendText}>Booked Male</Text>
                </View>
                <View style={styles.legendItem}>
                    <View style={[styles.legendSample, styles.femaleOccupiedSample]} />
                    <Text style={styles.legendText}>Booked Female</Text>
                </View>
            </View>
        </View>
    );

    const renderGenderRestrictions = () => {
        const restrictedSeats = selectedSeats?.filter(seat => 
            ['AVAILABLE_MALE', 'AVAILABLE_FEMALE'].includes(seat.seatStatus));

        if (!restrictedSeats?.length) return null;

        return (
            <View style={styles.restrictionsContainer}>
                <View style={styles.restrictionsHeader}>
                    <Ionicons name="warning" size={20} color="#f44336" />
                    <Text style={styles.restrictionsTitle}>Gender Restrictions Apply</Text>
                </View>
                {restrictedSeats.map(seat => (
                    <Text key={seat.seatNo} style={styles.restrictionText}>
                        Seat {seat.seatNo} is only available for 
                        {seat.seatStatus === 'AVAILABLE_MALE' ? ' male' : ' female'} passengers
                    </Text>
                ))}
            </View>
        );
    };

    const renderSeatSummary = () => {
        if (!selectedSeats?.length) return null;

        const costs = calculateEstimatedTotalCost();
        const hasDiscount = costs.discountAmount > 0;
        const hasPointsDiscount = useRewardPoints && costs.pointsApplied > 0;

        return (
            <View style={styles.summaryContainer}>
                <Text style={styles.summaryTitle}>Selected Seats</Text>
                <FlatList
                    data={selectedSeats}
                    keyExtractor={item => item.seatNo}
                    renderItem={({ item }) => (
                        <View style={styles.seatSummaryItem}>
                            <View style={styles.seatSummaryHeader}>
                                <Text style={styles.seatSummaryTitle}>
                                    Seat {item.seatNo} - {getSeatType(item)}
                                </Text>
                            </View>
                            <View style={styles.seatSummaryPricing}>
                                <Text style={styles.seatSummaryText}>
                                    Base Fare: ₹{item.seatRate.baseFare}
                                </Text>
                                {item.seatRate.discountedBaseFare < item.seatRate.baseFare && (
                                    <Text style={styles.discountText}>
                                        Discount: -₹{item.seatRate.discount}
                                    </Text>
                                )}
                                <Text style={styles.seatSummaryText}>
                                    Taxes: ₹{item.seatRate.totalTax}
                                </Text>
                                <Text style={styles.totalText}>
                                    Total: ₹{item.seatRate.totalCost}
                                </Text>
                            </View>
                        </View>
                    )}
                />
                <View style={styles.totalSummaryContainer}>
                    <Text style={styles.totalSummaryText}>
                        Base Price: ₹{costs.basePrice}
                    </Text>
                    {hasDiscount && (
                        <Text style={styles.discountSummaryText}>
                            Discount: -₹{costs.discountAmount}
                        </Text>
                    )}
                    <Text style={styles.totalSummaryText}>
                        Taxes: ₹{costs.totalTaxes}
                    </Text>
                    <Text style={styles.totalSummaryText}>
                        Subtotal: ₹{costs.totalCost}
                    </Text>
                    
                    {hasPointsDiscount && (
                        <>
                            <Text style={styles.pointsDiscountText}>
                                Reward Points Applied: {costs.pointsApplied} points
                            </Text>
                            <Text style={styles.pointsDiscountText}>
                                Points Value: -₹{Math.floor(costs.pointsValue)}
                            </Text>
                        </>
                    )}
                    
                    <Text style={styles.grandTotalText}>
                        Final Amount: ₹{Math.floor(costs.finalAmount)}
                    </Text>
                    
                    {useRewardPoints && (
                        <Text style={styles.pointsBalanceText}>
                            Available Points Balance: {userPoints}
                        </Text>
                    )}
                </View>
            </View>
        );
    };

    return (
        <View style={styles.container}>
            {renderLegend()}
            <ScrollView contentContainerStyle={styles.scrollContainer}>
                <View style={styles.mainContent}>
                <ScrollView 
    vertical={true} // Always enable horizontal scroll
    showsVerticalScrollIndicator={true}
    style={styles.busLayoutScroll}
    contentContainerStyle={styles.busLayoutScrollContent}
>
    <View style={styles.busLayout}>
        {levels.map((level, index) => (
            <View key={index} style={styles.levelContainer}>
                <View style={styles.levelHeader}>
                    <MaterialCommunityIcons 
                        name={level.upperLowerBith === 'Lower' ? 
                            "steering" : "bed"}
                        size={20} 
                        color="#666"
                    />
                    <Text style={styles.levelTitle}>
                        {level.upperLowerBith} Deck
                    </Text>
                </View>
                {renderSeats(level)}
            </View>
        ))}
    </View>
</ScrollView>
                    <ScrollView 
                        style={styles.rightPanelScroll}
                        showsVerticalScrollIndicator={true}
                    >
                        <View style={styles.rightPanel}>
                            {renderGenderRestrictions()}
                            {renderSeatSummary()}
                            <View style={styles.actionButtons}>
                                <TouchableOpacity 
                                    style={[styles.button, styles.cancelButton]} 
                                    onPress={onCancel}
                                >
                                    <Text style={styles.buttonText}>Cancel</Text>
                                </TouchableOpacity>
                                {selectedSeats && selectedSeats.length > 0 && (
                                    <TouchableOpacity 
                                        style={[styles.button, styles.bookButton]} 
                                        onPress={onBook}
                                    >
                                        <Text style={styles.buttonText}>Proceed</Text>
                                    </TouchableOpacity>
                                )}
                            </View>
                        </View>
                    </ScrollView>
                </View>
            </ScrollView>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff',
    },
    scrollContainer: {
        flexGrow: 1,
    },
    mainContent: {
        flex: 1,
        padding: isMobile ? 4 : 16,
        gap: isMobile ? 4 : 16,
        flexDirection: 'row'      
    },
    busLayoutScroll: {
        flex: isMobile ? 0 : 1,
    },
    busLayoutScrollContent: {
        flexGrow: 1,
        alignItems: 'center',
    },
    busLayout: {
        backgroundColor: '#fff',
        borderRadius: 8,
        padding: isMobile ? 8 : 16,
        elevation: 2,
        shadowColor: "#000",
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.23,
        shadowRadius: 2.62,
        flexDirection: 'row',
        gap: 24,
        alignItems: 'flex-start'
    },
    rightPanelScroll: {
        flex: isMobile ? 0 : 1,
        marginTop: isMobile ? 16 : 0,
        maxWidth: 400
    },
    rightPanel: {
        flex: 1,
        padding: isMobile ? 8 : 16,
    },
    levelContainer: {
        flexDirection: 'column',
        minWidth: 220,
        alignSelf: 'flex-start'
    },
    levelHeader: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 8,
        gap: 8,
        paddingLeft: 8
    },
    levelTitle: {
        fontSize: 14,
        fontWeight: '600',
        color: '#333',
    },
    levelSeatsContainer: {
        padding: 8,
        backgroundColor: '#f8f9fa',
        borderRadius: 8,
        alignItems: 'flex-start',
    },
    seatsGrid: {
        flexDirection: 'row',
        alignItems: 'flex-start',
        gap: 4,
    },
    seatColumn: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: 0,
    },
    emptySeatColumn: {
        width: 20,
        flexDirection: 'column',
        alignItems: 'center',
        opacity: 0.5,
        backgroundColor: '#f0f0f0',
        borderRadius: 2,
        margin: 4,
        minHeight: 35
    },
    seatWrapper: {
        margin: 0,
        alignSelf: 'flex-start',
        flexGrow: 0,
        flexShrink: 0
    },
    emptySeat: {
        width: 40,
        height: 40,
        margin: 2,
        flexGrow: 0,
        flexShrink: 0
    },
    widerEmptySeat: {
        width: 40,
        height: 86,
        margin: 2,
        flexGrow: 0,
        flexShrink: 0
    },
    legendContainer: {
        marginTop: 16,
        padding: isMobile ? 8 : 12,
        backgroundColor: '#f8f9fa',
        borderRadius: 8,
        alignSelf: 'stretch'
    },
    legendRow: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        gap: isMobile ? 8 : 16,
        padding: isMobile ? 4 : 8,
    },
    legendItem: {
        flexDirection: 'row',
        alignItems: 'center',
        gap: 8,
        marginBottom: 4,
        minWidth: 150,
    },
    legendSample: {
        width: 20,
        height: 20,
        borderRadius: 4,
        borderWidth: 1,
    },
    legendText: {
        fontSize: 12,
        color: '#666',
    },
    availableSample: {
        backgroundColor: '#fff',
        borderColor: '#666',
    },
    selectedSample: {
        backgroundColor: '#4CAF50',
        borderColor: '#388E3C',
    },
    unavailableSample: {
        backgroundColor: '#e0e0e0',
        borderColor: '#bdbdbd',
    },
    maleSample: {
        backgroundColor: '#fff',
        borderColor: '#2196F3',
        borderWidth: 2,
    },
    femaleSample: {
        backgroundColor: '#fff',
        borderColor: '#E91E63',
        borderWidth: 2,
    },
    maleOccupiedSample: {
        backgroundColor: '#90CAF9',
        borderColor: '#2196F3',
        borderWidth: 2,
    },
    femaleOccupiedSample: {
        backgroundColor: '#F48FB1',
        borderColor: '#E91E63',
        borderWidth: 2,
    },
    tooltipContainer: {
        position: 'absolute',
        backgroundColor: 'rgba(0,0,0,0.8)',
        padding: 8,
        borderRadius: 4,
        top: -40,
        left: 0,
        zIndex: 1000,
    },
    tooltipText: {
        color: '#fff',
        fontSize: 12,
    },
    summaryContainer: {
        backgroundColor: '#fff',
        borderRadius: 8,
        padding: isMobile ? 12 : 16,
        marginBottom: 16,
        width: '100%',
        elevation: 2,
        shadowColor: "#000",
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.23,
        shadowRadius: 2.62,
    },
    summaryTitle: {
        fontSize: 18,
        fontWeight: '600',
        color: '#333',
        marginBottom: 16,
    },
    seatSummaryItem: {
        borderBottomWidth: 1,
        borderBottomColor: '#eee',
        paddingVertical: isMobile ? 8 : 12,
    },
    totalSummaryContainer: {
        marginTop: 16,
        paddingTop: 16,
        borderTopWidth: 1,
        borderTopColor: '#eee',
    },
    actionButtons: {
        flexDirection: isMobile ? 'column' : 'row',
        gap: 12,
        justifyContent: 'flex-end',
        paddingVertical: 16,
    },
    button: {
        paddingVertical: 12,
        paddingHorizontal: 24,
        borderRadius: 8,
        elevation: 2,
        shadowColor: "#000",
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.23,
        shadowRadius: 2.62,
        minWidth: isMobile ? '100%' : 100,
        alignItems: 'center',
    },
    cancelButton: {
        backgroundColor: '#f44336',
    },
    bookButton: {
        backgroundColor: '#4CAF50',
    },
    buttonText: {
        color: '#fff',
        fontSize: 14,
        fontWeight: '600',
    },
    seatGroup: {
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: 4
    },
    horizontalSeatsGrid: {
        marginTop: 4
    },
});

export default SeatArrangement;