import React, { useState } from 'react';
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native';
import { Ionicons, AntDesign , MaterialCommunityIcons, Fontisto, MaterialIcons} from '@expo/vector-icons';

function MenuBar({ navigation }) {

    const [activeMenu, setActiveMenu] = useState('');

    const onMenuPress = (menu) => {
        if(menu==='home') {
            navigation.navigate('Home');
        } else if (menu==='bus') {
            navigation.navigate('Bus');
        } else if (menu==='flight') {
            navigation.navigate('Flights');
        } else if (menu==='hotel') {
            navigation.navigate('Hotels');
        }

        setActiveMenu(menu);
    }
    return (
        <View style={styles.menuBar}>
             <TouchableOpacity onPress={() => onMenuPress('home')} style={[styles.menuItem, activeMenu === 'home' && styles.activeMenuItem]}>
                <Ionicons name="home-outline" size={16} color="black" />
                <Text style={styles.menuText}>HOME</Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => onMenuPress('bus')} style={[styles.menuItem, activeMenu === 'bus' && styles.activeMenuItem]}>
                <Ionicons name="bus-outline" size={16} color="black" />
                <Text style={styles.menuText}>BUS</Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => onMenuPress('flight')} style={[styles.menuItem, activeMenu === 'flight' && styles.activeMenuItem]}>
                <MaterialIcons name="flight" size={16} color="black" />
                <Text style={styles.menuText}>FLIGHT</Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => onMenuPress('hotel')} style={[styles.menuItem, activeMenu === 'hotel' && styles.activeMenuItem]}>
                <Fontisto name="hotel" size={16} color="black" />
                <Text style={styles.menuText}>HOTEL</Text>
            </TouchableOpacity>
        </View>
    );
}

const styles = StyleSheet.create({
    menuBar: {
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center',
        borderBottomColor: 'lightgray',
        maxHeight: '5%',
        marginTop: 10,
        marginBottom: 10
    },
    menuItem: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    menuText: {
        fontSize: 12,
        fontWeight: 'bold',
        color: 'black',
        marginLeft: 5
        //fontFamily: 'Aldrich'
    },
    activeMenuItem: {
        color: '#5EBC67',
        borderBottomWidth: 1,
        borderBottomColor: '#5EBC67',
    },
});

export default MenuBar;
