import React, { useState, useEffect, useRef } from 'react';
import {
    View,
    Text,
    TextInput,
    TouchableOpacity,
    StyleSheet,
    ScrollView,
    useWindowDimensions,
    Modal,
    Animated,
    ActivityIndicator,
} from 'react-native';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../assets/styles/calendar-styles.css';  // Create this file for the calendar styles
import { MaterialIcons } from '@expo/vector-icons';
import { format } from 'date-fns';
import axios from 'axios';
import Constants from 'expo-constants';
import { useUser } from './UserContext';
import { LinearGradient } from 'expo-linear-gradient';

const API_URL = Constants.expoConfig.extra.apiUrl;
const API_TOKEN = Constants.expoConfig.extra.apiToken;

const THEME = {
    colors: {
        primary: '#5EBC67',
        text: '#1F2937',
        textSecondary: '#6B7280',
        border: '#E5E7EB',
        background: '#FFFFFF',
        backgroundLight: '#F9FAFB',
        backgroundHover: '#F3F4F6',
        gradient: {
            start: '#5EBC67',  // You can adjust these colors
            end: '#3B8F4F'     // to match your desired gradient
        },
        text: {
            white: '#FFFFFF',   // Add this for text colors
            primary: '#1F2937',
            secondary: '#6B7280'
        }
    }
};

const CityInput = ({
    value,
    onChangeText,
    placeholder,
    suggestions,
    showDropdown,
    setShowDropdown,
    onSelect,
    popularCities,
    error,
}) => {
    const inputRef = useRef(null);
    const dropdownRef = useRef(null);
    const [isFocused, setIsFocused] = useState(false);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target) &&
                inputRef.current && !inputRef.current.contains(event.target)) {
                setShowDropdown(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [setShowDropdown]);

    return (
        <View style={[styles.inputContainer, showDropdown && { zIndex: 5 }]}>
            <View style={[
                styles.inputWrapper,
                isFocused && styles.inputWrapperFocused,
                error && styles.inputWrapperError
            ]}>
                <MaterialIcons name="location-on" size={20} color={THEME.colors.primary} />
                <TextInput
                    ref={inputRef}
                    style={styles.input}
                    placeholder={placeholder}
                    value={value}
                    onChangeText={onChangeText}
                    onFocus={() => {
                        setShowDropdown(true);
                        setIsFocused(true);
                    }}
                    onBlur={() => setIsFocused(false)}
                />

            </View>

            {error && (
                <Text style={styles.errorText}>{error}</Text>
            )}

            {showDropdown && (
                <View ref={dropdownRef} style={styles.dropdownContainer}>
                    {!value || value.length === 0 ? (
                        <>
                            <Text style={styles.dropdownHeader}>Popular Cities</Text>
                            <ScrollView style={styles.suggestionsList}>
                                {Array.isArray(popularCities) && popularCities.length > 0 ? (
                                    popularCities.map((city) => (
                                        <TouchableOpacity
                                            key={city.cityId}
                                            style={styles.cityItem}
                                            onPress={() => {
                                                onSelect(city);
                                                setShowDropdown(false);
                                            }}
                                        >
                                            <MaterialIcons name="location-on" size={16} color={THEME.colors.primary} />
                                            <View style={styles.cityTextContainer}>
                                                <Text style={styles.cityName}>{city.cityName}</Text>
                                                <Text style={styles.stateName}>{city.state}</Text>
                                            </View>
                                        </TouchableOpacity>
                                    ))
                                ) : (
                                    <Text style={styles.noResultsText}>Loading popular cities...</Text>
                                )}
                            </ScrollView>
                        </>
                    ) : (
                        <ScrollView style={styles.suggestionsList}>
                            {Array.isArray(suggestions) && suggestions.map((city) => (
                                <TouchableOpacity
                                    key={city.cityId}
                                    style={styles.cityItem}
                                    onPress={() => {
                                        onSelect(city);
                                        setShowDropdown(false);
                                    }}
                                >
                                    <MaterialIcons name="location-on" size={16} color={THEME.colors.primary} />
                                    <View style={styles.cityTextContainer}>
                                        <Text style={styles.cityName}>{city.cityName}</Text>
                                        <Text style={styles.stateName}>{city.state}</Text>
                                    </View>
                                </TouchableOpacity>
                            ))}
                        </ScrollView>
                    )}
                </View>
            )}
        </View>
    );
};

const PromoInput = ({ value, onChangeText, error }) => {
    const [showOffersModal, setShowOffersModal] = useState(false);
    const [deals, setDeals] = useState([]);
    const [currentDealIndex, setCurrentDealIndex] = useState(0);
    const [isLoadingDeals, setIsLoadingDeals] = useState(false);
    const [dealError, setDealError] = useState(null);

    useEffect(() => {
        if (showOffersModal) {
            loadPromotions();
        }
    }, [showOffersModal]);

    const loadPromotions = async () => {
        setIsLoadingDeals(true);
        setDealError(null);
        try {
            const response = await axios.get(`${API_URL}/offers`, {
                headers: {
                    'x-api-token': API_TOKEN
                }
            });
            setDeals(response.data);
        } catch (error) {
            console.error('Error fetching deals:', error);
            setDealError('Failed to load deals. Please try again later.');
        } finally {
            setIsLoadingDeals(false);
        }
    };

    const handleNextDeal = () => {
        setCurrentDealIndex((prev) => (prev + 1) % deals.length);
    };

    const handlePrevDeal = () => {
        setCurrentDealIndex((prev) => (prev - 1 + deals.length) % deals.length);
    };

    const handleApplyCode = (code) => {
        onChangeText(code);
        setShowOffersModal(false);
    };

    return (
        <View style={styles.promoInputContainer}>
            <View style={[
                styles.promoCodeWrapper,
                error && styles.inputWrapperError
            ]}>
                <MaterialIcons
                    name="local-offer"
                    size={20}
                    color={error ? '#DC2626' : THEME.colors.primary}
                />
                <TextInput
                    style={styles.promoInput}
                    placeholder="Enter Promo Code"
                    value={value}
                    onChangeText={onChangeText}
                />
                <TouchableOpacity 
                    onPress={() => setShowOffersModal(true)}
                >
                    <MaterialIcons name="search" size={20} color={THEME.colors.primary} />
                </TouchableOpacity>
            </View>
            
            {error && (
                <Text style={styles.errorText}>{error}</Text>
            )}

            <Modal
                animationType="slide"
                transparent={true}
                visible={showOffersModal}
                onRequestClose={() => setShowOffersModal(false)}
            >
                <View style={styles.modalContainer}>
                    <View style={styles.modalContent}>
                        <View style={styles.modalHeader}>
                            <Text style={styles.modalTitle}>Available Offers</Text>
                            <TouchableOpacity 
                                style={styles.closeButton}
                                onPress={() => setShowOffersModal(false)}
                            >
                                <MaterialIcons name="close" size={24} color={THEME.colors.text} />
                            </TouchableOpacity>
                        </View>

                        <View style={styles.modalBody}>
                            {isLoadingDeals ? (
                                <View style={styles.loadingContainer}>
                                    <ActivityIndicator size="large" color={THEME.colors.primary} />
                                </View>
                            ) : dealError ? (
                                <View style={styles.errorContainer}>
                                    <Text style={styles.errorText}>{dealError}</Text>
                                    <TouchableOpacity 
                                        style={styles.retryButton}
                                        onPress={loadPromotions}
                                    >
                                        <Text style={styles.retryButtonText}>Retry</Text>
                                    </TouchableOpacity>
                                </View>
                            ) : deals.length > 0 ? (
                                <View>
                                    <LinearGradient
                                        colors={[THEME.colors.gradient.start, THEME.colors.gradient.end]}
                                        style={styles.dealHeader}
                                    >
                                        <View style={styles.dealIconContainer}>
                                            <MaterialIcons name="local-offer" size={24} color={THEME.colors.text.white} />
                                        </View>
                                        <View style={styles.dealHeaderText}>
                                            <Text style={styles.dealTitle}>{deals[currentDealIndex].title}</Text>
                                            <Text style={styles.dealDescription}>{deals[currentDealIndex].description}</Text>
                                        </View>
                                    </LinearGradient>

                                    <ScrollView style={styles.benefitsContainer}>
                                        <Text style={styles.benefitsTitle}>Benefits</Text>
                                        {deals[currentDealIndex].details?.benefits.map((benefit, index) => (
                                            <View key={index} style={styles.benefitItem}>
                                                <View style={styles.checkCircle}>
                                                    <MaterialIcons name="check" size={16} color={THEME.colors.text.white} />
                                                </View>
                                                <Text style={styles.benefitText}>{benefit}</Text>
                                            </View>
                                        ))}

                                        <View style={styles.promoCodeSection}>
                                            <View style={styles.promoCodeHeader}>
                                                <Text style={styles.promoCodeLabel}>PROMO CODE:</Text>
                                                <Text style={styles.promoCode}>{deals[currentDealIndex].offerCode}</Text>
                                            </View>
                                            <TouchableOpacity
                                                style={styles.applyButton}
                                                onPress={() => handleApplyCode(deals[currentDealIndex].offerCode)}
                                            >
                                                <Text style={styles.applyButtonText}>Apply Code</Text>
                                            </TouchableOpacity>
                                        </View>
                                    </ScrollView>

                                    <View style={styles.navigationContainer}>
                                        <TouchableOpacity
                                            style={styles.navButton}
                                            onPress={handlePrevDeal}
                                            disabled={deals.length <= 1}
                                        >
                                            <MaterialIcons name="chevron-left" size={24} color={THEME.colors.text} />
                                        </TouchableOpacity>

                                        <View style={styles.paginationContainer}>
                                            {deals.map((_, index) => (
                                                <View
                                                    key={index}
                                                    style={[
                                                        styles.paginationDot,
                                                        index === currentDealIndex && styles.paginationDotActive
                                                    ]}
                                                />
                                            ))}
                                        </View>

                                        <TouchableOpacity
                                            style={styles.navButton}
                                            onPress={handleNextDeal}
                                            disabled={deals.length <= 1}
                                        >
                                            <MaterialIcons name="chevron-right" size={24} color={THEME.colors.text} />
                                        </TouchableOpacity>
                                    </View>
                                </View>
                            ) : (
                                <View style={styles.noDealsContainer}>
                                    <Text style={styles.noDealsText}>No offers available at this time</Text>
                                </View>
                            )}
                        </View>
                    </View>
                </View>
            </Modal>
        </View>
    );
};

const SearchContainer = ({ onSearch, reset }) => {
    const { width } = useWindowDimensions();
    const calendarRef = useRef(null);

    const [fromCity, setFromCity] = useState('');
    const [toCity, setToCity] = useState('');
    const [selectedFromCity, setSelectedFromCity] = useState(null);
    const [selectedToCity, setSelectedToCity] = useState(null);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [showFromDropdown, setShowFromDropdown] = useState(false);
    const [showToDropdown, setShowToDropdown] = useState(false);
    const [showCalendar, setShowCalendar] = useState(false);
    const [fromSuggestions, setFromSuggestions] = useState([]);
    const [toSuggestions, setToSuggestions] = useState([]);
    const [popularCities, setPopularCities] = useState([]);
    const [promoCode, setPromoCode] = useState('');
    const [useRewards, setUseRewards] = useState(false);
    const [fromError, setFromError] = useState('');
    const [toError, setToError] = useState('');
    const [promoError, setPromoError] = useState('');
    const { user, userPoints } = useUser();
    const [validatedPromo, setValidatedPromo] = useState(null);

    useEffect(() => {
        if(reset) {
        setFromCity('');
        setToCity('');
        setSelectedFromCity(null);
        setSelectedToCity(null);
        setSelectedDate(new Date());
        setShowFromDropdown(false);
        setShowToDropdown(false);
        setShowCalendar(false);
        setFromSuggestions([]);
        setToSuggestions([]);
        setPromoCode('');
        setUseRewards(false);
        setFromError('');
        setToError('');
        setPromoError('');
        setValidatedPromo(null);
        }
    }, [reset]);

    useEffect(() => {
        const loadPopularCities = async () => {
            try {
                const response = await axios.get(`${API_URL}/city/loadallPopularCities`, {
                    headers: {
                        'x-api-token': API_TOKEN
                    }
                });

                if (response.data && Array.isArray(response.data)) {
                    const cities = response.data.map(city => ({
                        ...city,
                        cityId: city.cityId || city.id || Math.random().toString(),
                        cityName: city.cityName || city.name || '',
                        state: city.state || ''
                    }));

                    setPopularCities(cities);
                    setFromSuggestions(cities);
                    setToSuggestions(cities);
                } else {
                    console.error('Invalid popular cities data format:', response.data);
                    setPopularCities([]);
                }
            } catch (error) {
                console.error('Error fetching popular cities:', error);
                setPopularCities([]);
            }
        };

        loadPopularCities();
    }, []);

    useEffect(() => {
        const handleCalendarClickOutside = (event) => {
            if (calendarRef.current && !calendarRef.current.contains(event.target)) {
                setShowCalendar(false);
            }
        };

        document.addEventListener('mousedown', handleCalendarClickOutside);
        return () => document.removeEventListener('mousedown', handleCalendarClickOutside);
    }, []);

    const loadSuggestions = async (text, isFrom) => {
        if (!text) {
            isFrom ? setFromSuggestions(popularCities) : setToSuggestions(popularCities);
            return;
        }

        try {
            const response = await axios.get(`${API_URL}/city/search/${text}`, {
                headers: {
                    'x-api-token': API_TOKEN
                }
            });
            if (isFrom) {
                setFromSuggestions(response.data);
            } else {
                setToSuggestions(response.data);
            }
        } catch (error) {
            console.error('Error fetching suggestions:', error);
        }
    };

    const validatePromoCode = async () => {
        if (!promoCode) {
            return true;
        }

        setPromoError('');

        try {
            const formattedDate = format(selectedDate, 'yyyy-MM-dd')
            const response = await axios.post(
                `${API_URL}/offer/validate`,
                {
                    offerCode: promoCode,
                    isLoggedIn: !!user,
                    userId: user?.userId,
                    from: selectedFromCity.cityName,
                    to: selectedToCity.cityName,
                    travelDate: formattedDate,
                    bookingAmount: 0,
                    paymentMethod: "CREDIT_CARD"
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-token': API_TOKEN
                    }
                }
            );

            if (response.data.valid) {
                setValidatedPromo(response.data);
                return true;
            } else {
                setPromoError(response.data.messages[0] || 'Invalid promo code');
                return false;
            }
        } catch (error) {
            console.error('Promo validation error:', error);
            setPromoError(error.response?.data?.messages?.[0] || 'Error validating promo code');
            return false;
        }
    };

    const handleFromCitySelect = (city) => {
        setFromCity(city.cityName);
        setSelectedFromCity(city);
    };

    const handleToCitySelect = (city) => {
        setToCity(city.cityName);
        setSelectedToCity(city);
    };

    const handleSwap = () => {
        setFromCity(toCity);
        setToCity(fromCity);
        setSelectedFromCity(selectedToCity);
        setSelectedToCity(selectedFromCity);
    };

    const handleSearch = async () => {
        // Reset previous errors
        setFromError('');
        setToError('');
        setPromoError('');
        
        let hasError = false;
        let currentValidatedPromo = null;
    
        if (!selectedFromCity) {
            setFromError('Please select origin city');
            hasError = true;
        }
    
        if (!selectedToCity) {
            setToError('Please select destination city');
            hasError = true;
        }
    
        if (promoCode) {
            try {
                const formattedDate = format(selectedDate, 'yyyy-MM-dd');
                const response = await axios.post(
                    `${API_URL}/offer/validate`,
                    {
                        offerCode: promoCode,
                        isLoggedIn: !!user,
                        userId: user?.userId,
                        from: selectedFromCity?.cityName,
                        to: selectedToCity?.cityName,
                        travelDate: formattedDate,
                        bookingAmount: 0,
                        paymentMethod: "CREDIT_CARD"
                    },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'x-api-token': API_TOKEN
                        }
                    }
                );
    
                if (response.data.valid) {
                    currentValidatedPromo = response.data;
                } else {
                    setPromoError(response.data.messages[0] || 'Invalid promo code');
                    hasError = true;
                }
            } catch (error) {
                console.error('Promo validation error:', error);
                setPromoError(error.response?.data?.messages?.[0] || 'Error validating promo code');
                hasError = true;
            }
        }
    
        if (!hasError && onSearch) {
            onSearch({
                origin: selectedFromCity.cityName,
                destination: selectedToCity.cityName,
                travelDate: selectedDate,
                promoCode: promoCode,
                useRewardPoints: useRewards,
                validatedPromo: currentValidatedPromo
            });
        }
    };


    return (
        <View style={styles.container}>
            <View style={styles.searchBox}>
                <View style={styles.row}>
                    <CityInput
                        value={fromCity}
                        onChangeText={(text) => {
                            setFromCity(text);
                            loadSuggestions(text, true);
                        }}
                        placeholder="From"
                        suggestions={fromSuggestions}
                        showDropdown={showFromDropdown}
                        setShowDropdown={setShowFromDropdown}
                        onSelect={handleFromCitySelect}
                        popularCities={popularCities}
                        error={fromError}
                    />

                    <TouchableOpacity
                        style={styles.swapButton}
                        onPress={handleSwap}
                    >
                        <MaterialIcons name="swap-horiz" size={24} color={THEME.colors.text} />
                    </TouchableOpacity>

                    <CityInput
                        value={toCity}
                        onChangeText={(text) => {
                            setToCity(text);
                            loadSuggestions(text, false);
                        }}
                        placeholder="To"
                        suggestions={toSuggestions}
                        showDropdown={showToDropdown}
                        setShowDropdown={setShowToDropdown}
                        onSelect={handleToCitySelect}
                        popularCities={popularCities}
                        error={toError}
                    />

                    <View style={[styles.dateContainer, showCalendar && { zIndex: 5 }]}>

                        <View style={styles.quickLinksContainer}>
                            <TouchableOpacity
                                style={styles.quickLink}
                                onPress={() => {
                                    setSelectedDate(new Date());
                                    setShowCalendar(false);
                                }}
                            >
                                <Text style={styles.quickLinkText}>Today</Text>
                            </TouchableOpacity>
                            <Text style={styles.quickLinkDivider}>|</Text>
                            <TouchableOpacity
                                style={styles.quickLink}
                                onPress={() => {
                                    const tomorrow = new Date();
                                    tomorrow.setDate(tomorrow.getDate() + 1);
                                    setSelectedDate(tomorrow);
                                    setShowCalendar(false);
                                }}
                            >
                                <Text style={styles.quickLinkText}>Tomorrow</Text>
                            </TouchableOpacity>
                        </View>
                        <TouchableOpacity
                            style={styles.dateButton}
                            onPress={() => setShowCalendar(!showCalendar)}
                        >
                            <MaterialIcons name="calendar-today" size={20} color={THEME.colors.primary} />
                            <Text style={styles.dateText}>
                                {format(selectedDate, 'MMM dd, yyyy')}
                            </Text>
                        </TouchableOpacity>



                        {showCalendar && (
                            <View ref={calendarRef} style={styles.calendarDropdown}>
                                <DatePicker
                                    selected={selectedDate}
                                    onChange={(date) => {
                                        setSelectedDate(date);
                                        setShowCalendar(false);
                                    }}
                                    inline
                                    minDate={new Date()}
                                />
                            </View>
                        )}
                    </View>

                    <PromoInput
    value={promoCode}
    onChangeText={(text) => {
        setPromoCode(text);
        setPromoError(''); // Clear error when user types
    }}
    error={promoError}
    onOfferSelect={(offer) => {
        setPromoCode(offer.offerCode);
        // Optionally validate the promo code immediately
        //validatePromoCode(offer.offerCode);
    }}
/>
                {user && 
                    <View style={styles.rewardsContainer}>
                        <TouchableOpacity
                            style={styles.checkboxContainer}
                            onPress={() => setUseRewards(!useRewards)}
                        >
                            <View style={[styles.checkbox, useRewards && styles.checkboxChecked]}>
                                {useRewards && (
                                    <MaterialIcons name="check" size={16} color={THEME.colors.background} />
                                )}
                            </View>
                            <Text style={styles.checkboxLabel}>Use Rewards</Text>
                        </TouchableOpacity>
                    </View> }

                    <TouchableOpacity
                        style={styles.searchButton}
                        onPress={handleSearch}
                    >
                        <Text style={styles.searchButtonText}>Search</Text>
                    </TouchableOpacity>
                </View>
            </View>
        </View>
    );
};


const styles = StyleSheet.create({
    // Main Container Styles
    container: {
        width: '100%',
        padding: 16,
        alignSelf: 'center',
    },
    searchBox: {
        backgroundColor: THEME.colors.background,
        borderRadius: 12,
        padding: 16,
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.1,
        shadowRadius: 4,
    },

    // Layout Styles
    row: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'center',
        gap: 26
    },
    column: {
        flexDirection: 'column',
        gap: 16,
    },

    // Input Common Styles
    inputContainer: {
        flex: 1,
        position: 'relative',
        minWidth: 150,
    },
    inputWrapper: {
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: THEME.colors.background,
        borderRadius: 8,
        borderWidth: 1,
        borderColor: THEME.colors.border,
        paddingHorizontal: 12,
        height: 48,
    },
    inputWrapperFocused: {
        borderColor: THEME.colors.primary,
    },
    inputWrapperError: {
        borderColor: '#DC2626',
    },
    input: {
        flex: 1,
        marginLeft: 8,
        fontSize: 14,
        color: THEME.colors.text,
        outlineStyle: 'none',
    },

    // Dropdown Styles
    dropdownContainer: {
        position: 'absolute',
        top: '100%',
        left: 0,
        right: 0,
        backgroundColor: THEME.colors.background,
        borderRadius: 8,
        marginTop: 4,
        maxHeight: 300,
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.1,
        shadowRadius: 4,
        borderWidth: 1,
        borderColor: THEME.colors.border,
        zIndex: 1000,
    },
    dropdownHeader: {
        padding: 12,
        fontSize: 12,
        fontWeight: '600',
        color: THEME.colors.textSecondary,
        borderBottomWidth: 1,
        borderBottomColor: THEME.colors.border,
    },
    suggestionsList: {
        maxHeight: 250,
    },
    cityItem: {
        flexDirection: 'row',
        alignItems: 'center',
        padding: 12,
        borderBottomWidth: 1,
        borderBottomColor: THEME.colors.border,
    },
    cityTextContainer: {
        marginLeft: 8,
    },
    cityName: {
        fontSize: 14,
        color: THEME.colors.text,
    },
    stateName: {
        fontSize: 12,
        color: THEME.colors.textSecondary,
        marginTop: 2,
    },

    // Date Selection Styles
    dateContainer: {
        flex: 1,
        position: 'relative',
        minWidth: 150,
    },
    quickLinksContainer: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: THEME.colors.backgroundLight,
        borderRadius: 20,
        padding: 4,
        marginBottom: 8,
    },
    quickLink: {
        paddingVertical: 4,
        paddingHorizontal: 12,
        borderRadius: 16,
    },
    quickLinkActive: {
        backgroundColor: THEME.colors.primary,
    },
    quickLinkText: {
        fontSize: 12,
        color: THEME.colors.textSecondary,
        fontWeight: '500',
    },
    quickLinkTextActive: {
        color: THEME.colors.background,
    },
    quickLinkDivider: {
        width: 1,
        height: 16,
        backgroundColor: THEME.colors.border,
        marginHorizontal: 8,
    },
    dateButton: {
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: THEME.colors.background,
        borderRadius: 8,
        borderWidth: 1,
        borderColor: THEME.colors.border,
        paddingHorizontal: 12,
        height: 48,
    },
    dateText: {
        flex: 1,
        marginLeft: 8,
        fontSize: 14,
        color: THEME.colors.text,
    },
    calendarDropdown: {
        position: 'absolute',
        top: '100%',
        right: 0,
        backgroundColor: THEME.colors.background,
        borderRadius: 8,
        marginTop: 4,
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.1,
        shadowRadius: 4,
        borderWidth: 1,
        borderColor: THEME.colors.border,
        zIndex: 1000,
    },

    // Promo Code Styles
    promoInputContainer: {
        flex: 1,
        minWidth: 150,
        position: 'relative',
    },
    promoCodeWrapper: {
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: THEME.colors.background,
        borderRadius: 8,
        borderWidth: 1,
        borderColor: THEME.colors.border,
        paddingHorizontal: 12,
        height: 48,
    },
    promoInput: {
        flex: 1,
        marginLeft: 8,
        fontSize: 14,
        color: THEME.colors.text,
        outlineStyle: 'none',
    },
    searchIconContainer: {
        width: 32,
        height: 32,
        borderRadius: 16,
        backgroundColor: THEME.colors.backgroundLight,
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: 8,
    },

    // Offers Modal Styles
    modalContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
    },
    modalContent: {
        backgroundColor: THEME.colors.background,
        borderRadius: 20,
        width: '90%',
        maxWidth: 500,
        maxHeight: '80%',
    },
    modalHeader: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: 20,
        borderBottomWidth: 1,
        borderBottomColor: THEME.colors.border,
    },
    modalTitle: {
        fontSize: 20,
        fontWeight: '700',
        color: THEME.colors.text,
    },
    closeButton: {
        width: 32,
        height: 32,
        borderRadius: 16,
        backgroundColor: THEME.colors.backgroundLight,
        justifyContent: 'center',
        alignItems: 'center',
    },

    // Offer Slider Styles
    sliderContainer: {
        height: 500,
        position: 'relative',
    },
    cardContainer: {
        padding: 20,
    },
    offerCard: {
        backgroundColor: THEME.colors.background,
        borderRadius: 16,
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.1,
        shadowRadius: 8,
        elevation: 3,
        margin: 10,
        overflow: 'hidden',
    },
    cardGradient: {
        padding: 20,
    },
    cardHeader: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 16,
    },
    iconContainer: {
        width: 48,
        height: 48,
        borderRadius: 24,
        backgroundColor: 'rgba(255, 255, 255, 0.2)',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: 16,
    },
    cardHeaderText: {
        flex: 1,
    },
    offerTitle: {
        fontSize: 18,
        fontWeight: '700',
        color: '#FFFFFF',
        marginBottom: 4,
    },
    offerDescription: {
        fontSize: 14,
        color: '#FFFFFF',
        opacity: 0.9,
    },
    cardContent: {
        padding: 20,
    },
    benefitsTitle: {
        fontSize: 16,
        fontWeight: '600',
        color: THEME.colors.text,
        marginBottom: 16,
    },
    benefitItem: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 12,
    },
    checkCircle: {
        width: 24,
        height: 24,
        borderRadius: 12,
        backgroundColor: THEME.colors.primary,
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: 12,
    },
    benefitText: {
        fontSize: 14,
        color: THEME.colors.text,
        flex: 1,
    },
    promoCodeSection: {
        marginTop: 20,
        padding: 16,
        backgroundColor: THEME.colors.backgroundLight,
        borderRadius: 12,
    },
    promoCodeHeader: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 16,
    },
    promoCodeLabel: {
        fontSize: 12,
        color: THEME.colors.textSecondary,
        fontWeight: '500',
    },
    promoCode: {
        fontSize: 20,
        fontWeight: '700',
        color: THEME.colors.primary,
    },
    actionButtons: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
        gap: 12,
    },
    copyButton: {
        width: 40,
        height: 40,
        borderRadius: 20,
        backgroundColor: THEME.colors.backgroundLight,
        justifyContent: 'center',
        alignItems: 'center',
        borderWidth: 1,
        borderColor: THEME.colors.border,
    },
    applyButton: {
        backgroundColor: THEME.colors.primary,
        paddingHorizontal: 24,
        paddingVertical: 10,
        borderRadius: 20,
        flexDirection: 'row',
        alignItems: 'center',
        gap: 8,
    },
    applyButtonText: {
        color: '#FFFFFF',
        fontSize: 14,
        fontWeight: '600',
    },

    // Slider Navigation
    navigationButton: {
        position: 'absolute',
        top: '50%',
        transform: [{ translateY: -20 }],
        width: 40,
        height: 40,
        borderRadius: 20,
        backgroundColor: THEME.colors.background,
        justifyContent: 'center',
        alignItems: 'center',
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.1,
        shadowRadius: 4,
        elevation: 2,
        zIndex: 1,
    },
    prevButton: {
        left: 10,
    },
    nextButton: {
        right: 10,
    },
    paginationContainer: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        paddingVertical: 20,
        gap: 8,
    },
    paginationDot: {
        width: 8,
        height: 8,
        borderRadius: 4,
        backgroundColor: THEME.colors.border,
    },
    paginationDotActive: {
        width: 24,
        backgroundColor: THEME.colors.primary,
    },

    // Other Components
    swapButton: {
        width: 40,
        height: 40,
        backgroundColor: THEME.colors.backgroundLight,
        borderRadius: 20,
        alignItems: 'center',
        justifyContent: 'center',
    },
    rewardsContainer: {
        minWidth: 120,
        height: 48,
        justifyContent: 'center',
    },
    checkboxContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        gap: 8,
    },
    checkbox: {
        width: 20,
        height: 20,
        borderRadius: 4,
        borderWidth: 2,
        borderColor: THEME.colors.primary,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: THEME.colors.background,
    },
    checkboxChecked: {
        backgroundColor: THEME.colors.primary,
    },
    checkboxLabel: {
        fontSize: 14,
        color: THEME.colors.text,
        fontWeight: '500',
    },
    searchButton: {
        backgroundColor: THEME.colors.primary,
        borderRadius: 8,
        paddingVertical: 12,
        paddingHorizontal: 24,
        minWidth: 100,
        alignItems: 'center',
        justifyContent: 'center',
        height: 48,
    },
    searchButtonText: {
        color: THEME.colors.background,
        fontSize: 14,
        fontWeight: '600',
    },

    // States and Feedback
    errorText: {
        color: '#DC2626',
        fontSize: 12,
        marginTop: 4,
    },
    loadingContainer: {
        padding: 40,
        alignItems: 'center',
    },
    noResultsText: {
        padding: 16,
        textAlign: 'center',
        color: THEME.colors.textSecondary,
        fontSize: 14,
    },
    promoInputContainer: {
        flex: 1,
        minWidth: 150,
        position: 'relative',
    },
    promoCodeWrapper: {
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: THEME.colors.background,
        borderRadius: 8,
        borderWidth: 1,
        borderColor: THEME.colors.border,
        paddingHorizontal: 12,
        height: 48,
    },
    promoInput: {
        flex: 1,
        marginLeft: 8,
        marginRight: 8,
        fontSize: 14,
        color: THEME.colors.text,
    },
    modalContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
    },
    modalContent: {
        backgroundColor: THEME.colors.background,
        borderRadius: 20,
        width: '90%',
        maxWidth: 500,
        maxHeight: '80%',
        overflow: 'hidden',
    },
    modalHeader: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: 20,
        borderBottomWidth: 1,
        borderBottomColor: THEME.colors.border,
    },
    modalTitle: {
        fontSize: 20,
        fontWeight: '700',
        color: THEME.colors.text,
    },
    closeButton: {
        padding: 4,
    },
    modalBody: {
        flex: 1,
    },
    dealHeader: {
        padding: 20,
        flexDirection: 'row',
        alignItems: 'flex-start',
    },
    dealIconContainer: {
        width: 48,
        height: 48,
        borderRadius: 24,
        backgroundColor: 'rgba(255, 255, 255, 0.2)',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: 16,
    },
    dealHeaderText: {
        flex: 1,
    },
    dealTitle: {
        fontSize: 18,
        fontWeight: '700',
        color: THEME.colors.text.white,
        marginBottom: 4,
    },
    dealDescription: {
        fontSize: 14,
        color: THEME.colors.text.white,
        opacity: 0.9,
    },
    benefitsContainer: {
        padding: 20,
    },
    benefitsTitle: {
        fontSize: 16,
        fontWeight: '600',
        color: THEME.colors.text,
        marginBottom: 16,
    },
    benefitItem: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 12,
    },
    checkCircle: {
        width: 24,
        height: 24,
        borderRadius: 12,
        backgroundColor: THEME.colors.primary,
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: 12,
    },
    benefitText: {
        fontSize: 14,
        color: THEME.colors.text,
        flex: 1,
    },
    promoCodeSection: {
        marginTop: 20,
        padding: 16,
        backgroundColor: THEME.colors.backgroundLight,
        borderRadius: 12,
    },
    promoCodeHeader: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 16,
    },
    promoCodeLabel: {
        fontSize: 12,
        color: THEME.colors.text.secondary,
        fontWeight: '500',
    },
    promoCode: {
        fontSize: 20,
        fontWeight: '700',
        color: THEME.colors.primary,
    },
    applyButton: {
        backgroundColor: THEME.colors.primary,
        padding: 12,
        borderRadius: 8,
        alignItems: 'center',
    },
    applyButtonText: {
        color: THEME.colors.text.white,
        fontSize: 14,
        fontWeight: '600',
    },
    navigationContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: 20,
        borderTopWidth: 1,
        borderTopColor: THEME.colors.border,
    },
    navButton: {
        padding: 8,
        backgroundColor: THEME.colors.backgroundLight,
        borderRadius: 20,
    },
    paginationContainer: {
        flexDirection: 'row',
        gap: 8,
    },
    paginationDot: {
        width: 8,
        height: 8,
        borderRadius: 4,
        backgroundColor: THEME.colors.border,
    },
    paginationDotActive: {
        width: 24,
        backgroundColor: THEME.colors.primary,
    },
    loadingContainer: {
        padding: 40,
        alignItems: 'center',
    },
    errorContainer: {
        padding: 20,
        alignItems: 'center',
    },
    retryButton: {
        marginTop: 12,
        backgroundColor: THEME.colors.primary,
        paddingVertical: 8,
        paddingHorizontal: 16,
        borderRadius: 8,
    },
    retryButtonText: {
        color: THEME.colors.text.white,
        fontSize: 14,
        fontWeight: '500',
    },
    noDealsContainer: {
        padding: 40,
        alignItems: 'center',
    },
    noDealsText: {
        color: THEME.colors.text.secondary,
        fontSize: 14,
    },
    errorText: {
        color: '#DC2626',
        fontSize: 12,
        marginTop: 4,
    },
});


export default SearchContainer;