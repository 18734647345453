// TermsModal.js

import React from 'react';
import { View, Text, StyleSheet, TouchableOpacity, Modal } from 'react-native';

const ShippingAndDeliveryPolicyModal = ({ isVisible, closeModal }) => {
  return (
    <Modal visible={isVisible} onRequestClose={closeModal} animationType="slide"
    transparent={true}>
        <View style={styles.modalContainer}>
      <View style={styles.modalContent}>
        <Text style={styles.title}>Shipping and Delivery</Text>
        <Text style={styles.text}>
Last updated on Jul 6 2024

Shipping is not applicable for business.



</Text>
        <TouchableOpacity onPress={closeModal}>
          <Text style={styles.closeButton}>Close</Text>
        </TouchableOpacity>
      </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
    modalContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
      },
      modalContent: {
        backgroundColor: '#f2f2f2',
        padding: 20,
        borderRadius: 10,
        alignItems: 'center',
      },
      title: {
        fontSize: 20,
        marginBottom: 10,
        //fontFamily: 'Aldrich',
      },
      closeButton: {
        marginTop: 10,
        color: 'blue', // Customize close button style
        textDecorationLine: 'underline',
        //fontFamily: 'Aldrich',
      },
      text:{
        //fontFamily: 'Aldrich',
      }
});

export default ShippingAndDeliveryPolicyModal;
