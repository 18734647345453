import React from 'react';
import { View, Text } from 'react-native';
import { Ionicons } from '@expo/vector-icons';

const RatingStars = ({ rating }) => {
  if (rating === 0) {
    return (
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        <Text style={{fontSize: 10, color: 'lightgray'}}>No ratings available</Text>
      </View>
    );
  }

  const filledStars = Math.floor(rating);
  const halfStar = rating - filledStars >= 0.5 ? 1 : 0;
  const emptyStars = 5 - filledStars - halfStar;

  const renderStars = () => {
    let stars = [];
    for (let i = 0; i < filledStars; i++) {
      stars.push(<Ionicons key={i} name="star" size={16} color="#67BC5E" />);
    }
    if (halfStar) {
      stars.push(<Ionicons key={filledStars} name="star-half" size={16} color="#67BC5E" />);
    }
    for (let i = 0; i < emptyStars; i++) {
      stars.push(<Ionicons key={filledStars + halfStar + i} name="star-outline" size={16} color="#67BC5E" />);
    }
    return stars;
  };

  return (
    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
      {renderStars()}
      <Text style={{ marginLeft: 5 }}>{rating.toFixed(1)}</Text>
    </View>
  );
};

export default RatingStars;
