import React, { useState, useRef } from 'react';
import { View, TextInput, Button, StyleSheet, Text, Alert, TouchableOpacity } from 'react-native';
import { signIn, getCurrentUser, fetchUserAttributes, signUp, confirmSignUp, forgotPassword, forgotPasswordSubmit } from 'aws-amplify/auth';
import DateTimePickerModal from "react-native-modal-datetime-picker";
import PhoneInput from 'react-native-phone-number-input';
import ScreenLayout from '../components/ScreenLayout';
import { useUser } from '../components/UserContext';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { MaterialCommunityIcons, MaterialIcons } from '@expo/vector-icons';
import { format } from 'date-fns';

const LoginScreen = ({ navigation }) => {
    const { setUser, setUserAttributes } = useUser();
    const [password, setPassword] = useState('');
    const [signUpPage, setSignUpPage] = useState(false);
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [dateOfBirth, setDateOfBirth] = useState('');
    const [showCalendar, setShowCalendar] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [countryCode, setCountryCode] = useState('IN');
    const [confirmationCode, setConfirmationCode] = useState('');
    const [confirmSignUpPage, setConfirmSignUpPage] = useState(false);
    const [forgotPasswordPage, setForgotPasswordPage] = useState(false);
    const [newPassword, setNewPassword] = useState('');
    const [errors, setErrors] = useState({});

    const datePickerRef = useRef(null);

    const handleSignIn = async () => {
        try {
            const loginStatus = await signIn({ username: email, password });
            if(loginStatus.isSignedIn) {
                await handleSuccessfulLogin();
            } else if (loginStatus.nextStep.signInStep === 'CONFIRM_SIGN_UP') {
                setConfirmSignUpPage(true);
            }
        } catch (error) {
            if (error.message === 'There is already a signed in user.') {
                await handleAlreadyLoggedInUser();
            } else {
                setErrors({ signIn: error.message });
            }
        }
    };

    const handleSuccessfulLogin = async () => {
        const attributes = await fetchUserAttributes();
        Alert.alert('Logged in successfully');
        setUser(await getCurrentUser());
        setUserAttributes(attributes);
        navigation.navigate('Home');
    };

    const handleAlreadyLoggedInUser = async () => {
        try {
            const currentUser = await getCurrentUser();
            console.log("Current user:", currentUser);

            const attributes = await fetchUserAttributes();

            Alert.alert('Already logged in', 'You are already logged in. Redirecting to Home.');
            setUser(currentUser);
            setUserAttributes(attributes);
            navigation.navigate('Home');
        } catch (error) {
            console.error("Error handling already logged in user:", error);
            setErrors({ signIn: "Error handling logged in user. Please try logging out and in again." });
        }
    };

    const handleSignUp = async () => {
        const errors = {};
        if (!email) errors.email = 'Email is required';
        if (!firstName) errors.firstName = 'First Name is required';
        if (!lastName) errors.lastName = 'Last Name is required';
        if (!password) errors.password = 'Password is required';
        if (!dateOfBirth) errors.dateOfBirth = 'Date of Birth is required';
        if (!phoneNumber) errors.phoneNumber = 'Phone Number is required';

        setErrors(errors);

        if (Object.keys(errors).length > 0) return;

        try {
            await signUp({
                username: email,
                password,
                options: {
                    userAttributes: {
                        email,
                        given_name: firstName,
                        family_name: lastName,
                        birthdate: dateOfBirth,
                        phone_number: phoneNumber
                    }
                }
            });
            Alert.alert('Sign up successful', 'Please confirm your email');
            setSignUpPage(false);
            setConfirmSignUpPage(true);
        } catch (error) {
            if (error.message === 'User already exists') {
                Alert.alert('Error signing up', 'User already exists. Please sign in or use a different username.');
                setSignUpPage(false);
                setConfirmSignUpPage(true);
            } else {
                setErrors({ signUp: error.message });
            }
        }
    };

    const handleConfirmSignUp = async () => {
        try {
            await confirmSignUp({ username: email, confirmationCode });
            Alert.alert('Confirmation successful', 'You can now log in');
            setSignUpPage(false);
            setConfirmSignUpPage(false);
        } catch (error) {
            setErrors({ confirmSignUp: error.message });
        }
    };

    const handleForgotPassword = async () => {
        try {
            await forgotPassword({ username: email });
            Alert.alert('Confirmation code sent', 'Please check your email for the confirmation code.');
            setForgotPasswordPage(true);
        } catch (error) {
            setErrors({ forgotPassword: error.message });
        }
    };

    const handleForgotPasswordSubmit = async () => {
        try {
            await forgotPasswordSubmit({ username: email, confirmationCode, newPassword });
            Alert.alert('Password reset successful', 'You can now log in with your new password.');
            setForgotPasswordPage(false);
        } catch (error) {
            setErrors({ forgotPasswordSubmit: error.message });
        }
    };

    const showDatePickerModal = () => {
        setShowDatePicker(true);
    };

    const hideDatePickerModal = () => {
        setShowDatePicker(false);
    };

    const handleDateConfirm = (selectedDate) => {
        const formattedDate = format(selectedDate, 'yyyy-MM-dd');
        setDateOfBirth(formattedDate);
        hideDatePickerModal();
    };

    return (
        <ScreenLayout onLogoPress={() => navigation.navigate('Home')} onManageReservationPress={() => navigation.navigate('Manage')} navigation={navigation}>
            <View style={styles.outerContainer}>
                <View style={styles.formContainer}>
                    <Text style={styles.title}>
                        {forgotPasswordPage ? 'Reset Password' : signUpPage ? 'Sign Up' : 'Sign In'}
                    </Text>
                    <View style={styles.inputContainer}>
                        <Text>Email <Text style={styles.mandatory}>*</Text></Text>
                        <TextInput style={styles.input} value={email} onChangeText={setEmail} />
                        {errors.email && <Text style={styles.error}>{errors.email}</Text>}
                        {errors.signIn && <Text style={styles.error}>{errors.signIn}</Text>}
                    </View>
                    {forgotPasswordPage ? (
                        <>
                            <View style={styles.inputContainer}>
                                <Text>Confirmation Code</Text>
                                <TextInput style={styles.input} value={confirmationCode} onChangeText={setConfirmationCode} />
                            </View>
                            <View style={styles.inputContainer}>
                                <Text>New Password</Text>
                                <TextInput
                                    style={styles.input}
                                    value={newPassword}
                                    onChangeText={setNewPassword}
                                    secureTextEntry
                                />
                            </View>
                            <Button title="Submit" onPress={handleForgotPasswordSubmit} />
                        </>
                    ) : signUpPage ? (
                        <>
                            <View style={styles.inputContainer}>
                                <Text>First Name <Text style={styles.mandatory}>*</Text></Text>
                                <TextInput style={styles.input} value={firstName} onChangeText={setFirstName} />
                                {errors.firstName && <Text style={styles.error}>{errors.firstName}</Text>}
                            </View>
                            <View style={styles.inputContainer}>
                                <Text>Last Name <Text style={styles.mandatory}>*</Text></Text>
                                <TextInput style={styles.input} value={lastName} onChangeText={setLastName} />
                                {errors.lastName && <Text style={styles.error}>{errors.lastName}</Text>}
                            </View>
                            <View style={styles.inputContainer}>
                                <Text>Date of Birth <Text style={styles.mandatory}>*</Text></Text>
                                <View style={styles.datePickerContainer}>
                                    <TextInput
                                        style={styles.input}
                                        value={dateOfBirth}
                                        placeholder='Format yyyy-mm-dd'
                                        onChangeText={setDateOfBirth}
                                        editable={true} // Make the text input non-editable
                                    />
                                    <TouchableOpacity onPress={() => setShowCalendar(!showCalendar)}>
                                        <MaterialIcons name="calendar-today" size={24} color="#5EBC67" />
                                    </TouchableOpacity>
                                </View>
                                {showCalendar && (
                                <DatePicker
                                    selected={dateOfBirth}
                                    maxDate={new Date()}
                                    onChange={setDateOfBirth}
                                    dateFormat="MM/dd/yyyy"
                                    open={showCalendar}
                                    containerStyle={styles.datePicker}
                                />
                    )}

                                {errors.dateOfBirth && <Text style={styles.error}>{errors.dateOfBirth}</Text>}
                            </View>
                            <View style={styles.inputContainer}>
                                <Text>Phone Number <Text style={styles.mandatory}>*</Text></Text>
                                <View style={styles.phoneInputContainer}>
                                    <PhoneInput
                                        defaultValue={phoneNumber}
                                        defaultCode="IN"
                                        layout="first"
                                        onChangeFormattedText={text => {
                                            setPhoneNumber(text);
                                        }}
                                        withDarkTheme
                                        withShadow
                                        autoFocus
                                        containerStyle={styles.phoneInput}
                                        textContainerStyle={styles.phoneTextInput}
                                        flagButtonStyle={styles.flagButton}
                                    />
                                </View>
                                {errors.phoneNumber && <Text style={styles.error}>{errors.phoneNumber}</Text>}
                            </View>
                            <View style={styles.inputContainer}>
                                <Text>Password <Text style={styles.mandatory}>*</Text></Text>
                                <TextInput
                                    style={styles.input}
                                    value={password}
                                    onChangeText={setPassword}
                                    secureTextEntry
                                />
                                {errors.password && <Text style={styles.error}>{errors.password}</Text>}
                            </View>
                            <Button title="Sign Up" onPress={handleSignUp} />
                            <TouchableOpacity onPress={() => setSignUpPage(false)}>
                                <Text style={styles.linkText}>Already have an account? Sign In</Text>
                            </TouchableOpacity>
                        </>
                    ) : confirmSignUpPage ? (
                        <>
                            <View style={styles.inputContainer}>
                                <Text style={styles.infomessage}>An Email with verification code is sent. Please enter below</Text>
                                <Text>Confirmation Code</Text>
                                <TextInput
                                    style={styles.input}
                                    value={confirmationCode}
                                    onChangeText={setConfirmationCode}
                                />
                            </View>
                            <Button title="Confirm Sign Up" onPress={handleConfirmSignUp} />
                        </>
                    ) : (
                        <>
                            <View style={styles.inputContainer}>
                                <Text>Password <Text style={styles.mandatory}>*</Text></Text>
                                <TextInput
                                    style={styles.input}
                                    value={password}
                                    onChangeText={setPassword}
                                    secureTextEntry
                                />
                                {errors.password && <Text style={styles.error}>{errors.password}</Text>}
                            </View>
                            <Button title="Sign In" onPress={handleSignIn} />
                            <TouchableOpacity onPress={() => setSignUpPage(true)}>
                                <Text style={styles.linkText}>Don't have an account? Sign Up</Text>
                            </TouchableOpacity>
                            <TouchableOpacity onPress={handleForgotPassword}>
                                <Text style={styles.linkText}>Forgot Password?</Text>
                            </TouchableOpacity>
                        </>
                    )}
                </View>
            </View>
        </ScreenLayout>
    );
};

const styles = StyleSheet.create({
    outerContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        padding: 20,
    },
    formContainer: {
        width: '100%',
        maxWidth: 500,
        backgroundColor: 'white',
        padding: 20,
        borderRadius: 10,
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.8,
        shadowRadius: 2,
        elevation: 1,
    },
    title: {
        fontSize: 24,
        fontWeight: 'bold',
        marginBottom: 20,
        textAlign: 'center',
    },
    inputContainer: {
        marginBottom: 15,
    },
    input: {
        borderWidth: 1,
        borderColor: '#ccc',
        padding: 10,
        borderRadius: 5,
    },
    error: {
        color: 'red',
        marginTop: 5,
    },
    linkText: {
        color: 'blue',
        textAlign: 'center',
        marginTop: 15,
    },
    mandatory: {
        color: 'red',
    },
    phoneInputContainer: {
        width: '100%',
        borderWidth: 1,
        borderColor: '#ccc',
        borderRadius: 5,
        overflow: 'hidden',
    },
    phoneInput: {
        width: '100%',
        borderWidth: 0, // Remove the default border
    },
    phoneTextInput: {
        padding: 0,
        width: '80%',
    },
    flagButton: {
        width: '20%',
        borderWidth: 1,
        borderColor: '#ccc',
        borderRadius: 5,
    },
    datePickerContainer: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    datePicker:{
        elevation: 5
    },
    infomessage:{
        fontStyle: 'italic',
    }
});

export default LoginScreen;
