import React, { useEffect, useState } from "react";
import { View, StyleSheet, Button, Alert, Platform, Text, ActivityIndicator } from "react-native";
import RazorpayCheckout from "react-native-razorpay";
import axios from 'axios';
import { useUser } from './UserContext';
import { format } from 'date-fns';
import Constants from 'expo-constants';

const API_URL = Constants.expoConfig.extra.apiUrl;
const API_TOKEN = Constants.expoConfig.extra.apiToken;

const BusPaymentSummary = ({ bookSummary, handlePaymentSuccess, handleBookSummaryChange }) => {
  const { confirmationCode, ticketCode, origin, destination, travelDate, busAvailability, contactEmail, contactPhone, passengers, boardingPoint, dropOffPoint, seats, totalCost } = bookSummary;
  const formatTime = (timeArray) => {
    const arrivalTime = new Date(timeArray[0], timeArray[1] - 1, timeArray[2], timeArray[3], timeArray[4]);
    return arrivalTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  };
  const { user } = useUser();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (Platform.OS === 'web') {
      const script = document.createElement('script');
      script.src = 'https://checkout.razorpay.com/v1/checkout.js';
      script.async = true;
      script.onload = () => {
        console.log('Razorpay script loaded');
      };
      script.onerror = () => {
        console.error('Failed to load Razorpay script');
      };
      document.body.appendChild(script);
    }
  }, []);

  const convertToRupeeAndPasie = (totalAmount) => {
    const amountInRupees = totalAmount / 100;
    const amountString = amountInRupees.toFixed(2);
    return amountString;
  }

  const handleSuccess = async (response) => {
    const paymentId = response.razorpay_payment_id;
    setLoading(true);
    try {
      const formattedDate = format(travelDate, 'yyyy-MM-dd');
      const requestBody = {
        transactionId: paymentId,
        transactionPNR: confirmationCode,
        ticketCode: bookSummary.ticketCode,
        contactNumber: contactPhone,
        contactEmail: contactEmail,
        tripIdentifier: busAvailability.tripIdentifier,
        origin: origin,
        destination: destination,
        travelDate: formattedDate,
        boardingPoint: boardingPoint,
        droppingPoint: dropOffPoint,
        seats: seats,
        passengersInfo: passengers,
        busAvailability: busAvailability,
        userId: user ? user.userId : '',
        pos: {
          type: 'WEB'
        }
      };

      console.log('Request Body:', requestBody);

      const response = await axios.post(API_URL + '/confirm-seats', requestBody, {
        headers: {
          'Content-Type': 'application/json',
          'x-api-token': API_TOKEN
        }
      });

      console.log('Response Status:', response.status);
      console.log('Response Data:', response.data);

      const updatedBookSummary = {
        ...bookSummary,
        confirmationCode: response.data.confirmationCode,
        operatorPNR: response.data.operatorPNR,
        paymentId: paymentId
      };
      handleBookSummaryChange(updatedBookSummary);

      handlePaymentSuccess(response);
    } catch (error) {
      console.error('Error:', error);

      if (error.response) {
        console.error('Error Response Data:', error.response.data);
      }
    } finally {
      setLoading(false);
    }
  }

  const handlePayment = async () => {

    const options = {
      key: Constants.expoConfig.extra.razorPayKey,
      amount: bookSummary.totalCost, // Amount in currency subunits. Example: 50000 paise = INR 500.
      currency: 'INR',
      name: 'BYGBUS',
      description: 'Ticket Booking',
      image: 'https://bygbus.com/static/media/bygbus-high-resolution-logo-cropped.a987a185c40e2809bc64.png',
      order_id: bookSummary.orderId,
      handler: handleSuccess,
      prefill: {
        email: bookSummary.contactEmail,
        contact: bookSummary.contactPhone
      },
      notes: {
        address: 'BYGBUS Office'
      },
      theme: {
        color: '#5EBC67'
      }
    };

    if (Platform.OS === 'web') {
      if (window.Razorpay) {
        const rzp1 = new window.Razorpay(options);
        rzp1.on('payment.failed', function (response) {
          Alert.alert('Payment Failure', response.error.description);
        });
        rzp1.open();
      } else {
        Alert.alert('Error', 'Razorpay SDK not loaded');
      }
    } else {
      Alert.alert('Web only feature', 'Payment gateway is available only on web.');
    }
  };

  return (
    <View style={styles.container}>
      <Text style={styles.headerText}>Payment Summary</Text>
      {bookSummary.seats.map((seat, index) => (
        <View key={index} style={styles.costContainer}>
          <Text style={styles.detailText}>Seat {seat.seatNo}: ₹{seat.seatRate.totalCost}</Text>
        </View>
      ))}
      <View style={styles.totalCostContainer}>
        <Text style={styles.totalCostText}>Total Cost: ₹{convertToRupeeAndPasie(bookSummary.totalCost)}</Text>
      </View>
      {loading ? (
        <ActivityIndicator size="small" color="#fff" />
      ) : (
        <Button style={styles.payButton} title={`Pay ₹${convertToRupeeAndPasie(bookSummary.totalCost)}`} onPress={handlePayment} />
      )}

    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
  },
  headerText: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 20,
    textAlign: 'center',
    //fontFamily: 'Aldrich',
  },
  costContainer: {
    marginBottom: 10,
  },
  totalCostContainer: {
    borderTopWidth: 1,
    borderTopColor: '#ddd',
    paddingTop: 10,
    marginVertical: 10,
  },
  totalCostText: {
    fontSize: 18,
    fontWeight: 'bold',
    color: 'floralwhite'
    //fontFamily: 'Aldrich',
  },
  detailText: {
    fontSize: 16,
    marginBottom: 5,
    //fontFamily: 'Aldrich',
  },
  payButton: {
    backgroundColor: '#5EBC67',
  }
});

export default BusPaymentSummary;
