import React from 'react';
import ScreenLayout from './ScreenLayout';
import { View, Text, StyleSheet, TouchableOpacity, Linking, ScrollView } from 'react-native';
import { Ionicons } from '@expo/vector-icons'; // Make sure to install expo-vector-icons

const FAQItem = ({ question, answer }) => (
  <View style={styles.faqItem}>
    <Text style={styles.faqQuestion}>{question}</Text>
    <Text style={styles.faqAnswer}>{answer}</Text>
  </View>
);

const SupportScreen = ({ navigation }) => {
  const faqData = [
    { question: "How do I book a ride?", answer: "You can book a ride by..." },
    { question: "What payment methods are accepted?", answer: "We accept..." },
    { question: "How can I cancel my booking?", answer: "To cancel your booking..." },
  ];

  const additionalResources = [
    { title: "User Guide", icon: "book-outline", onPress: () => {} },
    { title: "Video Tutorials", icon: "videocam-outline", onPress: () => {} },
    { title: "Terms of Service", icon: "document-text-outline", onPress: () => {} },
  ];

  return (
    <ScreenLayout navigation={navigation}>
      <ScrollView style={styles.container}>
        <Text style={styles.title}>Support Center</Text>

        {/* Contact Information Section */}
        <View style={styles.section}>
          <Text style={styles.sectionTitle}>
            <Ionicons name="information-circle-outline" size={24} color="#007bff" /> Contact Information
          </Text>
          <Text style={styles.contactInfo}>
            <Ionicons name="call-outline" size={18} color="#007bff" /> +91 8179757744
          </Text>
          <Text style={styles.contactInfo}>
            <Ionicons name="mail-outline" size={18} color="#007bff" /> support@bygbus.com
          </Text>
        </View>

        {/* FAQ Section */}
        <View style={styles.section}>
          <Text style={styles.sectionTitle}>
            <Ionicons name="help-circle-outline" size={24} color="#007bff" /> Frequently Asked Questions
          </Text>
          {faqData.map((faq, index) => (
            <FAQItem key={index} question={faq.question} answer={faq.answer} />
          ))}
        </View>

        {/* Contact Support Section */}
        <View style={styles.section}>
          <Text style={styles.sectionTitle}>
            <Ionicons name="call-outline" size={24} color="#007bff" /> Contact Support
          </Text>
          <View style={styles.buttonContainer}>
            <TouchableOpacity
              style={styles.button}
              onPress={() => Linking.openURL('mailto:support@bygbus.com')}>
              <Ionicons name="mail-outline" size={24} color="#fff" />
              <Text style={styles.buttonText}>Email Us</Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={styles.button}
              onPress={() => Linking.openURL('tel:+918179757744')}>
              <Ionicons name="call-outline" size={24} color="#fff" />
              <Text style={styles.buttonText}>Call Us</Text>
            </TouchableOpacity>
          </View>
        </View>

        {/* Additional Resources Section */}
        <View style={styles.section}>
          <Text style={styles.sectionTitle}>
            <Ionicons name="library-outline" size={24} color="#007bff" /> Additional Resources
          </Text>
          {additionalResources.map((resource, index) => (
            <TouchableOpacity key={index} style={styles.resourceButton} onPress={resource.onPress}>
              <Ionicons name={resource.icon} size={24} color="#007bff" />
              <Text style={styles.resourceButtonText}>{resource.title}</Text>
            </TouchableOpacity>
          ))}
        </View>
      </ScrollView>
    </ScreenLayout>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#f5f5f5',
  },
  title: {
    fontSize: 28,
    fontWeight: 'bold',
    marginBottom: 20,
    textAlign: 'center',
    color: '#333',
    paddingTop: 20,
  },
  section: {
    backgroundColor: '#fff',
    borderRadius: 10,
    padding: 20,
    marginHorizontal: 15,
    marginBottom: 20,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 4,
    elevation: 3,
  },
  sectionTitle: {
    fontSize: 20,
    fontWeight: '600',
    marginBottom: 15,
    color: '#007bff',
    flexDirection: 'row',
    alignItems: 'center',
  },
  contactInfo: {
    fontSize: 16,
    color: '#333',
    marginBottom: 10,
    flexDirection: 'row',
    alignItems: 'center',
  },
  faqItem: {
    marginBottom: 15,
  },
  faqQuestion: {
    fontSize: 16,
    fontWeight: '600',
    color: '#333',
    marginBottom: 5,
  },
  faqAnswer: {
    fontSize: 14,
    color: '#666',
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 10,
  },
  button: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 12,
    paddingHorizontal: 20,
    backgroundColor: '#007bff',
    borderRadius: 8,
    minWidth: '45%',
  },
  buttonText: {
    color: '#fff',
    fontSize: 16,
    fontWeight: '600',
    marginLeft: 8,
  },
  resourceButton: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 10,
    borderBottomWidth: 1,
    borderBottomColor: '#e0e0e0',
  },
  resourceButtonText: {
    fontSize: 16,
    color: '#333',
    marginLeft: 10,
  },
});

export default SupportScreen;