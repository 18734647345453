import React, { useState } from 'react';
import { View, Text, TouchableOpacity, StyleSheet, TextInput, FlatList, ScrollView } from 'react-native';
import PhoneInput from 'react-native-phone-number-input';

const BusBookingForm = ({ bookSummary, handleBookSummaryChange, onProceed, onClickBack }) => {
    const [selectedBoardingPoint, setSelectedBoardingPoint] = useState(bookSummary.boardingPoint);
    const [selectedDropOffPoint, setSelectedDropOffPoint] = useState(bookSummary.dropOffPoint);
    const [contactEmail, setContactEmail] = useState(bookSummary.contactEmail);
    const [contactPhone, setContactPhone] = useState(bookSummary.contactPhone);
    const [passengers, setPassengers] = useState(
        bookSummary.seats.map((seat) => {
            const passenger = { firstName: '', lastName: '', gender: '', age: '', seat };
            if (seat.seatStatus === 'AVAILABLE_MALE') {
                passenger.gender = 'Male';
            } else if (seat.seatStatus === 'AVAILABLE_FEMALE') {
                passenger.gender = 'Female';
            }
            return passenger;
        })
    );
    const [errors, setErrors] = useState({});

    const handleBoardingPointPress = (point) => {
        setSelectedBoardingPoint(point === selectedBoardingPoint ? '' : point);
        setErrors((prevErrors) => ({ ...prevErrors, boardingPoint: '' }));
    };

    const handleDropOffPointPress = (point) => {
        setSelectedDropOffPoint(point === selectedDropOffPoint ? '' : point);
        setErrors((prevErrors) => ({ ...prevErrors, dropOffPoint: '' }));
    };

    const handlePassengerChange = (index, key, value) => {
        const updatedPassengers = [...passengers];
        if (!updatedPassengers[index]) {
            updatedPassengers[index] = { firstName: '', lastName: '', gender: '', age: '', seat: bookSummary.seats[index] };
        }
        updatedPassengers[index][key] = value;
        setPassengers(updatedPassengers);

        const errorKey = `passenger_${index}_${key}`;
        setErrors((prevErrors) => ({ ...prevErrors, [errorKey]: '' }));
    };

    const handleBack = () => {
        setPassengers([]);
        setSelectedBoardingPoint('');
        setSelectedDropOffPoint('');
        setContactEmail('');
        setContactPhone('');
        onClickBack();
    };

    const onReview = () => {
        if (validateForm()) {
            handleSubmit();
            onProceed();
        }
    };

    const getSeatType = (seat) => {
        switch (seat.seatType) {
            case 'REGULAR':
                return 'Regular Seat';
            case 'SINFLE_REGULAR':
                return 'Single Regular Seat';
            case 'PUSHBACK':
                return 'Pushback';
            case 'SEMI_SLEEPER':
                return 'Semi Sleeper';
            case 'SLEEPER':
                return 'Sleeper';
            case 'SINGLE_LOWER_SLEEPER':
                return 'Single Lower Sleeper';
            case 'SINGLE_UPPER_SLEEPER':
                return 'Single Upper Sleeper';
            case 'LOWER_SLEEPER':
                return 'Lower Sleeper';
            case 'UPPER_SLEEPER':
                return 'Upper Sleeper';
            default:
                return 'Seat';
        }
    };

    const validateForm = () => {
        const newErrors = {};

        if (!selectedBoardingPoint) {
            newErrors.boardingPoint = 'Boarding point is required';
        }

        if (!selectedDropOffPoint) {
            newErrors.dropOffPoint = 'Drop off point is required';
        }

        if (!contactEmail) {
            newErrors.contactEmail = 'Email is required';
        }

        if(!contactPhone) {
            newErrors.contactPhone = 'Phone is required';
        }

        passengers.forEach((passenger, index) => {
            if (!passenger || !passenger.firstName) {
                newErrors[`passenger_${index}_firstName`] = 'First name is required';
            }
            if (!passenger || !passenger.lastName) {
                newErrors[`passenger_${index}_lastName`] = 'Last name is required';
            }
            if (!passenger || !passenger.age) {
                newErrors[`passenger_${index}_age`] = 'Age is required';
            }
            if (!passenger || !passenger.gender) {
                newErrors[`passenger_${index}_gender`] = 'Gender is required';
            }
        });

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = () => {
        const updatedBookSummary = {
            ...bookSummary,
            boardingPoint: selectedBoardingPoint,
            dropOffPoint: selectedDropOffPoint,
            contactEmail: contactEmail,
            contactPhone: contactPhone,
            passengers: passengers,
        };
        handleBookSummaryChange(updatedBookSummary);
    };

    return (
        <View style={styles.container}>
            <View style={styles.bookFormContainer}>

                <View style={styles.contactContainer}>
                    <Text style={styles.containerBorderText}>
                        Contact Details <Text style={styles.asterisk}> *</Text>
                    </Text>
                    <View style={styles.contactDetailContainer}>
                        <Text style={styles.detailHeader}>
                            Email <Text style={styles.asterisk}> *</Text>
                        </Text>
                        <TextInput
                            style={styles.input}
                            placeholder="Contact Email"
                            onChangeText={setContactEmail}
                            value={contactEmail}

                        />
                        {errors.contactEmail && (
                            <Text style={styles.errorText}>{errors.contactEmail}</Text>
                        )}
                    </View>
                    <View style={styles.contactDetailContainer}>
                        <Text style={styles.detailHeader}>
                            Phone Number <Text style={styles.asterisk}> *</Text>
                        </Text>
                        <TextInput
                            style={styles.input}
                            placeholder="Contact Phone"
                            onChangeText={setContactPhone}
                            value={contactPhone}
                        />
                        {errors.contactPhone && (
                            <Text style={styles.errorText}>{errors.contactPhone}</Text>
                        )}
                    </View>
                    <View>
                        <Text style={styles.contactCommunicationText}>
                            Above provided contact information would be used to send Email/Watsapp communications regarding booking
                        </Text>
                    </View>
                </View>

                <View style={styles.passengersContainer}>
                    <Text style={styles.containerBorderText}>
                        Passenger Details <Text style={styles.asterisk}> *</Text>
                    </Text>
                    {bookSummary.seats.map((selectedSeat, index) => (
                        <View key={index} style={styles.passengerContact}>
                            <Text style={styles.containerBorderText}>Passenger {index + 1}</Text>
                            <Text style={styles.seatLabel}>Seat {selectedSeat.seatNo} ({getSeatType(selectedSeat)})</Text>
                            <View style={styles.passengerInformation}>
                                <View style={styles.nameContainer}>
                                    <Text style={styles.detailHeader}>
                                        First Name<Text style={styles.asterisk}> *</Text>
                                    </Text>
                                    <TextInput
                                        style={styles.input}
                                        placeholder="First Name"
                                        onChangeText={(value) => handlePassengerChange(index, 'firstName', value)}
                                        value={passengers[index]?.firstName || ''}

                                    />
                                    {errors[`passenger_${index}_firstName`] && (
                                        <Text style={styles.errorText}>
                                            {errors[`passenger_${index}_firstName`]}
                                        </Text>
                                    )}
                                </View>
                                <View style={styles.nameContainer}>
                                    <Text style={styles.detailHeader}>
                                        Last Name<Text style={styles.asterisk}> *</Text>
                                    </Text>
                                    <TextInput
                                        style={styles.input}
                                        placeholder="Last Name"
                                        onChangeText={(value) => handlePassengerChange(index, 'lastName', value)}
                                        value={passengers[index]?.lastName || ''}

                                    />
                                    {errors[`passenger_${index}_lastName`] && (
                                        <Text style={styles.errorText}>
                                            {errors[`passenger_${index}_lastName`]}
                                        </Text>
                                    )}
                                </View>
                                <View style={styles.ageContainer}>
                                    <Text style={styles.detailHeader}>
                                        Age<Text style={styles.asterisk}> *</Text>
                                    </Text>
                                    <TextInput
                                        style={styles.input}
                                        placeholder="Age"
                                        onChangeText={(value) => handlePassengerChange(index, 'age', value)}
                                        value={passengers[index]?.age || ''}

                                    />
                                    {errors[`passenger_${index}_age`] && (
                                        <Text style={styles.errorText}>
                                            {errors[`passenger_${index}_age`]}
                                        </Text>
                                    )}
                                </View>
                                <View style={styles.genderContainer}>
                                    <Text style={styles.detailHeader}>
                                        Gender<Text style={styles.asterisk}> *</Text>
                                    </Text>
                                    <View style={styles.genderButtons}>
                                        {selectedSeat.seatStatus === 'AVAILABLE_MALE' || selectedSeat.seatStatus === 'AVAILABLE_FEMALE' ? (
                                            <Text style={styles.nonEditableGender}>
                                                {selectedSeat.seatStatus === 'AVAILABLE_MALE' ? 'Male' : 'Female'}
                                            </Text>
                                        ) : (
                                            <>
                                                <TouchableOpacity
                                                    style={[
                                                        styles.genderButton,
                                                        passengers[index]?.gender === 'Male' && styles.maleSelected,
                                                    ]}
                                                    onPress={() => handlePassengerChange(index, 'gender', 'Male')}
                                                >
                                                    <Text
                                                        style={[
                                                            styles.genderButtonText,
                                                            passengers[index]?.gender === 'Male' && styles.selectedGenderButtonText,
                                                        ]}
                                                    >
                                                        Male
                                                    </Text>
                                                </TouchableOpacity>
                                                <TouchableOpacity
                                                    style={[
                                                        styles.genderButton,
                                                        passengers[index]?.gender === 'Female' && styles.femaleSelected,
                                                    ]}
                                                    onPress={() => handlePassengerChange(index, 'gender', 'Female')}
                                                >
                                                    <Text
                                                        style={[
                                                            styles.genderButtonText,
                                                            passengers[index]?.gender === 'Female' && styles.selectedGenderButtonText,
                                                        ]}
                                                    >
                                                        Female
                                                    </Text>
                                                </TouchableOpacity>
                                            </>
                                        )}
                                    </View>
                                    {errors[`passenger_${index}_gender`] && (
                                        <Text style={styles.errorText}>
                                            {errors[`passenger_${index}_gender`]}
                                        </Text>
                                    )}
                                </View>
                            </View>
                        </View>
                    ))}
                </View>

                <View style={styles.boardingDropOffContainer}>
                    <Text style={styles.containerBorderText}>
                        Boarding/Dropping Points
                    </Text>

                    <View style={styles.boardingDropOffPointsContainer}>
                        <View style={{width: '50%'}}>
                            <Text style={styles.listHeader}>
                                Boarding Points<Text style={styles.asterisk}> *</Text>
                            </Text>
                            {errors.boardingPoint && (
                                    <Text style={styles.errorText}>{errors.boardingPoint}</Text>
                                )}
                            <ScrollView contentContainerStyle={styles.boardingPointsContainer}>

                                {bookSummary.busAvailability.boardingPoints.map((item) => {
                                    const arrivalTimeArray = item.arrivalTime;
                                    const arrivalTime = new Date(
                                        arrivalTimeArray[0],
                                        arrivalTimeArray[1] - 1,
                                        arrivalTimeArray[2],
                                        arrivalTimeArray[3],
                                        arrivalTimeArray[4]
                                    );
                                    const formattedArrivalTime = arrivalTime.toLocaleTimeString([], {
                                        hour: '2-digit',
                                        minute: '2-digit',
                                    });

                                    return (
                                        <TouchableOpacity
                                            key={item.pointIdentifier}
                                            onPress={() => handleBoardingPointPress(item)}
                                        >
                                            <View
                                                style={
                                                    selectedBoardingPoint.pointName === item.pointName
                                                        ? styles.selectedPoint
                                                        : styles.point
                                                }
                                            >
                                                <Text
                                                    style={
                                                        selectedBoardingPoint.pointName === item.pointName
                                                            ? styles.selectedPointName
                                                            : styles.pointName
                                                    }
                                                >
                                                    {item.pointName} ({formattedArrivalTime})
                                                </Text>
                                                <Text
                                                    style={
                                                        selectedBoardingPoint.pointName === item.pointName
                                                            ? styles.selectedPointLandMark
                                                            : styles.landMark
                                                    }
                                                >
                                                    {item.landMark}
                                                </Text>
                                            </View>
                                        </TouchableOpacity>
                                    );
                                })}
                               
                            </ScrollView>
                        </View>


                        <View style={{width: '50%'}}>
                            <Text style={styles.listHeader}>
                                Dropoff Points<Text style={styles.asterisk}> *</Text>
                            </Text>
                            {errors.dropOffPoint && (
                                    <Text style={styles.errorText}>{errors.dropOffPoint}</Text>
                                )}
                            <ScrollView contentContainerStyle={styles.dropOffPointsContainer}>

                                {bookSummary.busAvailability.dropOffPoints.map((item) => {
                                    const arrivalTimeArray = item.arrivalTime;
                                    const arrivalTime = new Date(
                                        arrivalTimeArray[0],
                                        arrivalTimeArray[1] - 1,
                                        arrivalTimeArray[2],
                                        arrivalTimeArray[3],
                                        arrivalTimeArray[4]
                                    );
                                    const formattedArrivalTime = arrivalTime.toLocaleTimeString([], {
                                        hour: '2-digit',
                                        minute: '2-digit',
                                    });

                                    return (
                                        <TouchableOpacity
                                            key={item.pointIdentifier}
                                            onPress={() => handleDropOffPointPress(item)}
                                        >
                                            <View
                                                style={
                                                    selectedDropOffPoint.pointName === item.pointName
                                                        ? styles.selectedPoint
                                                        : styles.point
                                                }
                                            >
                                                <Text
                                                    style={
                                                        selectedDropOffPoint.pointName === item.pointName
                                                            ? styles.selectedPointName
                                                            : styles.pointName
                                                    }
                                                >
                                                    {item.pointName} ({formattedArrivalTime})
                                                </Text>
                                                <Text
                                                    style={
                                                        selectedDropOffPoint.pointName === item.pointName
                                                            ? styles.selectedPointLandMark
                                                            : styles.landMark
                                                    }
                                                >
                                                    {item.landMark}
                                                </Text>
                                            </View>
                                        </TouchableOpacity>
                                    );
                                })}
                                
                            </ScrollView>
                        </View>

                    </View>

                </View>

                <View style={styles.actionContainer}>
                    <TouchableOpacity onPress={handleBack} style={styles.editButton}>
                        <Text style={styles.editButtonText}>Back</Text>
                    </TouchableOpacity>
                    <TouchableOpacity onPress={onReview} style={styles.reviewButton}>
                        <Text style={styles.reviewButtonText}>Review</Text>
                    </TouchableOpacity>
                </View>
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        justifyContent: 'center',
        alignItems: 'center'
    },
    bookFormContainer: {
        width: '90%',
        padding: 20,
        backgroundColor: 'white',
        borderRadius: 10,
        elevation: 5,
    },
    contactContainer: {
        width: '100%',
        borderWidth: 1,
        borderColor: 'lightgray',
        padding: 10,
        marginVertical: 20,
        borderRadius: 5,
    },
    containerBorderText: {
        position: 'absolute',
        top: -10,
        left: 10,
        backgroundColor: 'white',
        paddingHorizontal: 5,
        //fontFamily: 'Aldrich',
    },
    contactDetailContainer: {
        marginBottom: 10,
    },
    detailHeader: {
        fontSize: 14,
        fontWeight: 'bold',
        marginBottom: 5,
        //fontFamily: 'Aldrich',
    },
    input: {
        borderWidth: 1,
        borderColor: 'gray',
        padding: 10,
        borderRadius: 5,
    },
    passengersContainer: {
        width: '100%',
        borderWidth: 1,
        borderColor: 'lightgray',
        padding: 10,
        marginBottom: 20,
        borderRadius: 5,
    },
    passengerContact: {
        borderWidth: 1,
        borderColor: 'lightgray',
        padding: 10,
        borderRadius: 5,
        marginVertical: 10,
    },
    passengerInformation: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
    },
    seatLabel: {
        fontSize: 14,
        fontWeight: 'bold',
        marginBottom: 10,
        //fontFamily: 'Aldrich',
    },
    nameContainer: {
        width: '45%',
        marginBottom: 10,
        //fontFamily: 'Aldrich',
    },
    ageContainer: {
        width: '45%',
        marginBottom: 10,
        //fontFamily: 'Aldrich',
    },
    genderContainer: {
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: '45%',
        marginBottom: 10,
    },
    genderButtons: {
        flexDirection: 'row',
    },
    genderButton: {
        flex: 1,
        padding: 10,
        borderRadius: 5,
        borderWidth: 1,
        borderColor: 'gray',
    },
    nonEditableGender: {
        flex: 1,
        padding: 10,
        borderRadius: 5,
        borderWidth: 1,
        borderColor: 'gray',
        backgroundColor: 'gray'
    },
    maleSelected: {
        backgroundColor: '#add8e6',
    },
    femaleSelected: {
        backgroundColor: '#ffb6c1',
    },
    genderButtonText: {
        color: 'black',
        textAlign: 'center',
    },

    selectedGenderButtonText: {
        color: 'white',
    },
    boardingDropOffContainer: {
        width: '100%',
        borderWidth: 1,
        borderColor: 'lightgray',
        padding: 10,
        borderRadius: 5,
        marginBottom: 20,
    },
    boardingDropOffPointsContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        maxHeight: 200,
    },
    boardingPointsContainer: {
        flex: 1,
        paddingHorizontal: 10,
    },
    dropOffPointsContainer: {
        flex: 1,
        borderLeftWidth: 1,
        borderLeftColor: 'gray',
        paddingHorizontal: 10
    },
    listHeader: {
        fontSize: 16,
        fontWeight: 'bold',
        marginBottom: 10,
        //fontFamily: 'Aldrich',
    },
    point: {
        marginVertical: 10,
    },
    pointName: {
        fontSize: 14,
        //fontFamily: 'Aldrich',
    },
    landMark: {
        fontSize: 12,
        fontStyle: 'italic',
        //fontFamily: 'Aldrich',
    },
    selectedPoint: {
        backgroundColor: '#5EBC67',
    },
    selectedPointName: {
        color: 'white',
        fontWeight: 'bold',
        //fontFamily: 'Aldrich',
    },
    selectedPointLandMark: {
        color: 'white',
        //fontFamily: 'Aldrich',
    },
    actionContainer: {
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row'
    },
    reviewButton: {
        backgroundColor: '#5EBC67',
        paddingVertical: 10,
        paddingHorizontal: 20,
        borderRadius: 5,
        marginHorizontal: 5
    },
    reviewButtonText: {
        color: 'white',
        fontWeight: 'bold',
        textAlign: 'center',
        //fontFamily: 'Aldrich',
    },
    editButton: {
        backgroundColor: '#5EBC67',
        paddingVertical: 10,
        paddingHorizontal: 20,
        borderRadius: 5,
    },
    editButtonText: {
        color: 'white',
        fontWeight: 'bold',
        textAlign: 'center',
        //fontFamily: 'Aldrich',
    },
    contactCommunicationText: {
        fontSize: 12,
        fontStyle: 'italic',
        //fontFamily: 'Aldrich',
    },
    asterisk: {
        color: 'red',
    },
    errorText: {
        color: 'red',
        fontSize: 12,
        marginTop: 5,
        //fontFamily: 'Aldrich',
    },
    phoneInput: {
        width: '100%',
        borderWidth: 1,
        borderColor: 'gray',
        padding: 10,
        borderRadius: 5,
    },
    phoneTextInput: {
        padding: 10,
        borderWidth: 1,
        borderColor: '#ccc',
        borderRadius: 5,
        backgroundColor: 'white'
    },
    flagButton: {
        width: '20%',
        borderWidth: 1,
        borderColor: '#ccc',
        borderRadius: 5,
    },
});

export default BusBookingForm;