import React, { useState } from 'react';
import { View, Text, StyleSheet, TouchableOpacity, FlatList, Modal, ScrollView, useWindowDimensions } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import { AmenityIcon } from "../common/FeatureComponent";

const BusDetailsModal = ({ closeModal, busDetails, activatedTab }) => {
  const [activeTab, setActiveTab] = useState(activatedTab);
  const { width, height } = useWindowDimensions();

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  const convertTo12HourFormat = (dateTime) => {
    const date = new Date(dateTime.replace(' ', 'T'));

    // Extract date components
    const day = date.getDate();
    const month = date.getMonth() + 1; // Months are zero-based
    const year = date.getFullYear();

    // Extract time components
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';

    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    const minutesStr = minutes < 10 ? '0' + minutes : minutes;

    const formattedDate = `${day}/${month}/${year}`;
    const formattedTime = `${hours}:${minutesStr} ${ampm}`;

    return `${formattedDate} ${formattedTime}`;
  };

  return (
    <Modal
      animationType="slide"
      transparent={true}
      visible={true}
      onRequestClose={closeModal}
    >
      <View style={styles.modalContainer}>
        <View style={[styles.modalContent, { width: width * 0.8, height: height * 0.8 }]}>
          <TouchableOpacity style={styles.closeButton} onPress={closeModal}>
            <Ionicons name="close" size={24} color="black" />
          </TouchableOpacity>
          <Text style={styles.text}>{busDetails.operator}</Text>
          <Text style={styles.text}>{busDetails.bus.name}</Text>
          <View style={styles.tabContainer}>
            <TouchableOpacity
              style={[styles.tabButton, activeTab === 'Features' && styles.activeTab]}
              onPress={() => handleTabClick('Features')}
            >
              <Text style={styles.text}>Features</Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={[styles.tabButton, activeTab === 'Cancel Policy' && styles.activeTab]}
              onPress={() => handleTabClick('Cancel Policy')}
            >
              <Text style={styles.text}>Cancel Policy</Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={[styles.tabButton, activeTab === 'Travel Policy' && styles.activeTab]}
              onPress={() => handleTabClick('Travel Policy')}
            >
              <Text style={styles.text}>Travel Policy</Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={[styles.tabButton, activeTab === 'Boarding DropOff Points' && styles.activeTab]}
              onPress={() => handleTabClick('Boarding DropOff Points')}
            >
              <Text style={styles.text}>Boarding/DropOff Points</Text>
            </TouchableOpacity>
          </View>
          <ScrollView style={styles.tabContent}>
            {activeTab === 'Features' && (
              <View style={styles.amenitiesContainer}>
                {busDetails.amenities && busDetails.amenities.map((amenity, index) => (
                  <AmenityIcon key={index} amenity={amenity} size={36} color="black" />
                ))}
              </View>
            )}
            {activeTab === 'Cancel Policy' && (
              <View style={styles.cancellationPolicyContainer}>
                <Text style={styles.listHeader}>Cancellation Policy</Text>
                {busDetails.cancellationPolicy ? (
                  <>
                    <Text style={styles.text}>Refundable: {busDetails.cancellationPolicy.refundable ? 'Yes' : 'No'}</Text>
                    {busDetails.cancellationPolicy.cancellationPenalty && busDetails.cancellationPolicy.cancellationPenalty.length > 0 ? (
                      busDetails.cancellationPolicy.cancellationPenalty.map((cancelPenalty, index) => (
                        <View key={index} style={styles.penaltyContainer}>
                          <Text style={styles.text}>Between {cancelPenalty.from ? convertTo12HourFormat(cancelPenalty.from) : 'Departure'} and {cancelPenalty.to ? convertTo12HourFormat(cancelPenalty.to) : 'Booked Date/time'}:</Text>
                          <Text style={styles.text}>
                            {cancelPenalty.refundable ?
                              `${cancelPenalty.chargeType === 'AMOUNT' ? '₹' : ''} ${cancelPenalty.cancellationCharge} ${cancelPenalty.chargeType === 'PERCENTAGE' ? '%' : ''} Charged`
                              : 'Non Refundable'}
                          </Text>
                        </View>
                      ))
                    ) : (
                      <Text style={styles.text}>No penalties available.</Text>
                    )}
                  </>
                ) : (
                  <Text style={styles.text}>No cancellation policy available.</Text>
                )}
              </View>
            )}
            {activeTab === 'Travel Policy' && (
              <Text style={styles.text}>Travel Policy Content Here</Text>
            )}
            {activeTab === 'Boarding DropOff Points' && (
              <View style={styles.boardingDropOffContainer}>
                <View style={styles.pointsRow}>
                  <View style={styles.boardingPointsContainer}>
                    <Text style={styles.listHeader}>Boarding Points</Text>
                    {busDetails.boardingPoints.map((item, index) => {
                      const arrivalTimeArray = item.arrivalTime;
                      const arrivalTime = new Date(arrivalTimeArray[0], arrivalTimeArray[1] - 1, arrivalTimeArray[2], arrivalTimeArray[3], arrivalTimeArray[4]);
                      const formattedArrivalTime = arrivalTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

                      return (
                        <View key={index} style={styles.point}>
                          <Text style={styles.pointName}>{item.pointName} ({formattedArrivalTime})</Text>
                          <Text style={styles.landMark}>{item.landMark}</Text>
                        </View>
                      );
                    })}
                  </View>
                  <View style={styles.line} />
                  <View style={styles.dropOffPointsContainer}>
                    <Text style={styles.listHeader}>DropOff Points</Text>
                    {busDetails.dropOffPoints.map((item, index) => {
                      const arrivalTimeArray = item.arrivalTime;
                      const arrivalTime = new Date(arrivalTimeArray[0], arrivalTimeArray[1] - 1, arrivalTimeArray[2], arrivalTimeArray[3], arrivalTimeArray[4]);
                      const formattedArrivalTime = arrivalTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

                      return (
                        <View key={index} style={styles.point}>
                          <Text style={styles.pointName}>{item.pointName} ({formattedArrivalTime})</Text>
                          <Text style={styles.landMark}>{item.landMark}</Text>
                        </View>
                      );
                    })}
                  </View>
                </View>
              </View>
            )}
          </ScrollView>
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
  },
  modalContent: {
    backgroundColor: '#f2f2f2',
    padding: 20,
    borderRadius: 10,
    alignItems: 'center',
  },
  closeButton: {
    position: 'absolute',
    top: 10,
    right: 10,
  },
  tabContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    marginBottom: 20,
  },
  tabButton: {
    padding: 10,
  },
  activeTab: {
    borderBottomWidth: 2,
    borderBottomColor: 'black',
  },
  tabContent: {
    maxHeight: '60%',
    width: '100%',
    backgroundColor: 'white',
  },
  amenitiesContainer: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    marginTop: 10,
    paddingHorizontal: 10, // Add horizontal padding to create space between items
  },
  boardingDropOffContainer: {
    maxHeight: '60%', // adjust as needed
    width: '100%',
    backgroundColor: 'white',
    paddingHorizontal: 10,
  },
  pointsRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  boardingPointsContainer: {
    flex: 1,
    marginRight: 10,
  },
  dropOffPointsContainer: {
    flex: 1,
    marginLeft: 10,
  },
  point: {
    marginVertical: 10,
  },
  pointName: {
    color: 'black',
    fontSize: 16,
  },
  landMark: {
    fontSize: 12,
    fontStyle: 'italic',
  },
  line: {
    width: 1,
    backgroundColor: 'black',
  },
  listHeader: {
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 10,
    marginTop: 20,
    //fontFamily: 'Aldrich',
  },

  text: {
    // fontFamily: 'Aldrich',
  },
  cancellationPolicyContainer: {
    padding: 10,
  },
  penaltyContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginVertical: 5,
  },
});

export default BusDetailsModal;