import React from 'react';
import { View, StyleSheet, SafeAreaView } from 'react-native';
import Header from './Header';
import MenuBar from './MenuBar';
import Footer from './Footer';

const ScreenLayout = ({ children, navigation }) => {
    return (
        <View style={styles.container}>
            <Header navigation={navigation} />
            <MenuBar navigation={navigation} />
            <SafeAreaView style={styles.content}>{children}</SafeAreaView>
            <Footer />
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: 'white',
    },
    content: {
        flex: 1,
    },
});

export default ScreenLayout;
