import React, { useEffect, useState } from 'react';
import { View, Text, StyleSheet, TouchableOpacity, TextInput, Alert } from 'react-native';
import axios from 'axios';
import * as Print from 'expo-print';
import Constants from 'expo-constants';

const API_URL = Constants.expoConfig.extra.apiUrl;
const API_TOKEN = Constants.expoConfig.extra.apiToken;

const BusBookingConfirmation = ({ bookSummary, handleViewReservation, onHome}) => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [reservationDetails, setReservationDetails] = useState(null);

    useEffect(() => {
        fetchReservationDetails();
    }, []);

    const handleSendEmail = async () => {
        if (!email.trim()) {
            Alert.alert('Email is required', 'Please enter an email address.');
            return;
        }

        // Replace with your actual email sending logic
        const requestBody = {
            pnr: bookSummary.confirmationCode,
            emails : email,
            notificationType : 'CONFIRMATION'
        }

        try {
            const response = await axios.post(API_URL + '/notify', requestBody , {
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-token': API_TOKEN
                }
            });
            setMessage(response.data);
        } catch (error) {
            console.log(error)
            setError('Failed to send Email. Please try again or contact BYGBUS Customer Support.')
        }

        // Mock sending email (replace with actual sending logic)
        Alert.alert('Sending email to ' + email, emailContent);
    };

    const fetchReservationDetails = async () => {
        try {
            const response = await axios.get(`${API_URL}/bus-reservation/${bookSummary.confirmationCode}`, {
                headers: {
                    'x-api-token': API_TOKEN
                }
            });
            const payload = response.data;
            setReservationDetails(payload);
        } catch (error) {
            console.error('Error fetching reservation:', error);
            Alert.alert('Error', 'Failed to fetch reservation details. Please try again.');
        }
    };

    const handlePrint = async () => {
        try {
            const response = await axios.get(API_URL + '/bus-reservation/print/' + bookSummary.confirmationCode , {
                headers: {
                    'x-api-token': API_TOKEN
                }}) ;
            await Print.printAsync({
                html: response.data
            });
        } catch (error) {
            console.error(error);
        }
    };

    const formatTime = (timeArray) => {
        const arrivalTime = new Date(timeArray[0], timeArray[1] - 1, timeArray[2], timeArray[3], timeArray[4]);
        return arrivalTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    };

    const convertTo12HourFormat = (dateTime) => {
        const date = new Date(dateTime.replace(' ', 'T'));

        // Extract date components
        const day = date.getDate();
        const month = date.getMonth() + 1; // Months are zero-based
        const year = date.getFullYear();

        // Extract time components
        let hours = date.getHours();
        const minutes = date.getMinutes();
        const ampm = hours >= 12 ? 'PM' : 'AM';

        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        const minutesStr = minutes < 10 ? '0' + minutes : minutes;

        const formattedDate = `${day}/${month}/${year}`;
        const formattedTime = `${hours}:${minutesStr} ${ampm}`;

        return `${formattedDate} ${formattedTime}`;
    };

    return (
        <View style={styles.bookFormContainer}>
            {message && (
                <Text style={styles.message} >{message}</Text>
            )}
            {error && (
                <Text style={styles.error} >{error}</Text>
            )}
            <Text style={styles.title}>Booking Confirmation</Text>
          
            <Text style={styles.text}>Enter comma separated emails, if you would like to send confirmation to multiple emails</Text>
            <TextInput
                style={styles.input}
                placeholder="Enter Email Address"
                keyboardType="email-address"
                value={email}
                onChangeText={setEmail}
            />

            <View style={styles.buttonContainer}>
                <TouchableOpacity style={styles.button} onPress={handleSendEmail}>
                    <Text style={styles.buttonText}>Send Email</Text>
                </TouchableOpacity>
                <TouchableOpacity style={styles.button} onPress={handlePrint}>
                    <Text style={styles.buttonText}>Print</Text>
                </TouchableOpacity>
                <TouchableOpacity style={styles.button} onPress={onHome}>
                    <Text style={styles.buttonText}>Home</Text>
                </TouchableOpacity>
            </View>

            {reservationDetails && (
                    <View style={styles.detailsContainer}>
                        <Text style={styles.detailsTitle}>Reservation Details:</Text>
                        <Text style={styles.detailsText}>BYGBUS PNR: 
                        <TouchableOpacity onPress={handleViewReservation}>
                            <Text style={[styles.value, styles.hyperlink]}>{bookSummary.confirmationCode}</Text>
                        </TouchableOpacity></Text>
                        <Text style={styles.detailsText}>Operator PNR: {reservationDetails.operatorPNR}</Text>
                        <Text style={styles.detailsText}>Transaction Confirmation: {bookSummary.paymentId}</Text>
                        <Text style={styles.detailsText}>Service Number : {reservationDetails.busAvailability.serviceIdentifier}</Text>
                        {reservationDetails.status === "BOOKED" && (
                            <Text style={[{ color: 'green', fontWeight: 'bold' }, styles.detailsText]}>Confirmed</Text>
                        )}

                        {reservationDetails.status === "CANCELLED" && (
                            <Text style={[{ color: 'red', fontWeight: 'bold' }, styles.detailsText]}>Cancelled</Text>
                        )}

                        {reservationDetails.status != "CANCELLED" && reservationDetails.status != "BOOKED" && (
                           <Text style={styles.detailsText}>Status: {reservationDetails.status}</Text>
                        )}

                        <View style={styles.section}>
                            <Text style={styles.sectionHeader}>Travel/Operator</Text>
                            <Text style={styles.detailText}>Operator: {reservationDetails.busAvailability.operator} ({reservationDetails.busAvailability.bus.name})</Text>
                            <Text style={styles.detailText}>From City: {reservationDetails.origin}</Text>
                            <Text style={styles.detailText}>To City: {reservationDetails.destination}</Text>
                            <Text style={styles.detailText}>On: {reservationDetails.travelDate}</Text>
                            <Text style={styles.detailText}>Departure: {formatTime(reservationDetails.busAvailability.departureTime)}</Text>
                            <Text style={styles.detailText}>Arrival: {formatTime(reservationDetails.busAvailability.arrivalTime)}</Text>
                        </View>

                        <View style={styles.section}>
                            <Text style={styles.sectionHeader}>Contact Details</Text>
                            <Text style={styles.detailText}>Email: {reservationDetails.contactEmail}</Text>
                            <Text style={styles.detailText}>Phone: {reservationDetails.contactNumber}</Text>
                        </View>

                        <View style={styles.section}>
                            <Text style={styles.sectionHeader}>Passenger Details</Text>
                            <View style={styles.passengersContainer}>
                                {reservationDetails.passengersInfo.map((passenger, index) => (
                                    <View key={index} style={styles.passengerContainer}>
                                        <Text style={styles.passengerHeader}>Passenger {index + 1}</Text>
                                        <Text style={styles.detailText}>Seat: {passenger.seat.seatNo}</Text>
                                        <Text style={styles.detailText}>First Name: {passenger.firstName}</Text>
                                        <Text style={styles.detailText}>Last Name: {passenger.lastName}</Text>
                                        <Text style={styles.detailText}>Age: {passenger.age}</Text>
                                        <Text style={styles.detailText}>Gender: {passenger.gender}</Text>
                                        {passenger.seat.seatStatus === "BOOKED" && (
                                            <Text style={{ color: 'green', fontWeight: 'bold' }}>Booked</Text>
                                        )}

                                        {passenger.seat.seatStatus === "CANCELLED" && (
                                            <Text style={{ color: 'red', fontWeight: 'bold' }}>Cancelled</Text>
                                        )}
                                    </View>
                                ))}
                            </View>
                        </View>

                        <View style={styles.section}>
                            <Text style={styles.sectionHeader}>Boarding/DropOff</Text>
                            <View style={styles.rowContainer}>

                                <View style={styles.pointContainer}>
                                    <Text style={styles.subHeader}>Boarding Point</Text>
                                    <Text style={styles.detailText}>Boarding At: {reservationDetails.boardingPoint.pointName}</Text>
                                    <Text style={styles.detailText}>Landmark: {reservationDetails.boardingPoint.landMark}</Text>
                                    <Text style={styles.detailText}>Contact : {reservationDetails.boardingPoint.contactNumber}</Text>
                                    <Text style={styles.detailText}>
                                        Arrival Time: {new Date(
                                            reservationDetails.boardingPoint.arrivalTime[0],
                                            reservationDetails.boardingPoint.arrivalTime[1] - 1,
                                            reservationDetails.boardingPoint.arrivalTime[2],
                                            reservationDetails.boardingPoint.arrivalTime[3],
                                            reservationDetails.boardingPoint.arrivalTime[4]
                                        ).toLocaleTimeString([], {
                                            hour: '2-digit',
                                            minute: '2-digit',
                                        })}
                                    </Text>
                                </View>

                                <View style={styles.pointContainer}>
                                    <Text style={styles.subHeader}>Drop-Off Point</Text>
                                    <Text style={styles.detailText}>Drop-Off At: {reservationDetails.droppingPoint.pointName}</Text>
                                    <Text style={styles.detailText}>Landmark: {reservationDetails.droppingPoint.landMark}</Text>
                                    <Text style={styles.detailText}>Contact : {reservationDetails.droppingPoint.contactNumber}</Text>
                                    <Text style={styles.detailText}>
                                        Arrival Time: {new Date(
                                            reservationDetails.droppingPoint.arrivalTime[0],
                                            reservationDetails.droppingPoint.arrivalTime[1] - 1,
                                            reservationDetails.droppingPoint.arrivalTime[2],
                                            reservationDetails.droppingPoint.arrivalTime[3],
                                            reservationDetails.droppingPoint.arrivalTime[4]
                                        ).toLocaleTimeString([], {
                                            hour: '2-digit',
                                            minute: '2-digit',
                                        })}
                                    </Text>
                                </View>
                            </View>
                        </View>

                        <View style={styles.section}>
                            <Text style={styles.sectionHeader}>Cancellation Policy</Text>
                            {reservationDetails.busAvailability.cancellationPolicy && reservationDetails.busAvailability.cancellationPolicy.cancellationPenalty.map((cancelPenalty, index) => (
                                <View key={index} style={styles.penaltyContainer}>
                                    <Text style={styles.detailText}>
                                        Between {cancelPenalty.from ? convertTo12HourFormat(cancelPenalty.from) : 'Departure'} and {cancelPenalty.to ? convertTo12HourFormat(cancelPenalty.to) : 'Booked Date/time'}:
                                        {cancelPenalty.refundable ?
                                            ` ${cancelPenalty.chargeType === 'AMOUNT' ? '₹' : ''}${cancelPenalty.cancellationCharge}${cancelPenalty.chargeType === 'PERCENTAGE' ? '%' : ''}`
                                            : ' Non Refundable'}
                                    </Text>
                                </View>
                            ))}
                        </View>

                        <View style={styles.section}>
                            <Text style={styles.sectionHeader}>Cost Breakdown</Text>
                            {reservationDetails.seats.map((seat, index) => (
                                <View key={index} style={styles.costContainer}>
                                    <Text style={styles.detailText}>Seat {seat.seatNo}: (Base ₹{seat.seatRate.discountedBaseFare} + Tax ₹{seat.seatRate.totalTax}) ₹{seat.seatRate.totalCost}</Text>
                                </View>
                            ))}
                        </View>
                        </View>
                        )}
        </View>
    );
};

const styles = StyleSheet.create({
    bookFormContainer: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        padding: 20,
        backgroundColor: '#f2f2f2',
    },
    title: {
        fontSize: 24,
        fontWeight: 'bold',
        marginBottom: 20,
    },
    label: {
        fontSize: 18,
        fontWeight: '600',
        marginTop: 10,
    },
    value: {
        fontSize: 18,
        color: '#333',
        marginBottom: 10,
    },
    input: {
        height: 40,
        borderColor: '#ccc',
        borderWidth: 1,
        borderRadius: 5,
        paddingHorizontal: 10,
        marginBottom: 20,
    },
    buttonContainer: {
        flexDirection: 'row',
        marginTop: 10,
    },
    button: {
        backgroundColor: '#007BFF',
        paddingVertical: 10,
        paddingHorizontal: 20,
        marginHorizontal: 10,
        borderRadius: 5,
    },
    buttonText: {
        color: '#fff',
        fontSize: 16,
        fontWeight: 'bold',
        textAlign: 'center',
    },
    text:{
        fontSize: 12,
        fontStyle: 'italic'
    },
    message:{
        fontSize : 16,
        color: '#28a745'
    },
    error:{
        fontSize : 16,
        color: 'red'
    },
    hyperlink:{
        textDecorationLine: "underline",
    },
    detailsContainer: {
        marginTop: 20,
        width: '100%',
    },
    detailsTitle: {
        fontSize: 20,
        fontWeight: 'bold',
        marginBottom: 10,
        textAlign: 'center',
    },
    detailsText: {
        marginBottom: 10,
        fontSize: 16,
    },
    section: {
        marginBottom: 20,
        padding: 10,
        backgroundColor: '#f9f9f9',
        borderRadius: 5,
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.1,
        shadowRadius: 5,
        elevation: 2,
    },
    sectionHeader: {
        fontSize: 18,
        fontWeight: 'bold',
        marginBottom: 10,
        borderBottomWidth: 1,
        borderBottomColor: 'lightgray',
        paddingBottom: 5,
    },
    subHeader: {
        fontSize: 16,
        fontWeight: 'bold',
        marginTop: 10,
        // fontFamily: 'Aldrich',
    },
    pointContainer: {
        marginHorizontal: 20,
        marginBottom: 10,
    },
    detailText: {
        fontSize: 16,
        marginBottom: 5,
    },
    passengersContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
    passengerContainer: {
        marginBottom: 10,
        marginHorizontal: 5,
    },
    passengerHeader: {
        fontSize: 16,
        fontWeight: 'bold',
        marginBottom: 5,
    },
    costContainer: {
        marginBottom: 5,
    },
    penaltyContainer: {
        marginBottom: 10,
    },
    retrieveButton: {
        paddingVertical: 10,
        paddingHorizontal: 20,
        borderRadius: 5,
        alignItems: 'center',
        marginVertical: 10,
        backgroundColor: '#5EBC67',
    },
    manageButton: {
        paddingVertical: 10,
        paddingHorizontal: 20,
        borderRadius: 5,
        alignItems: 'center',
        marginVertical: 10,
        backgroundColor: 'red',
    },
    buttonText: {
        fontSize: 16,
        color: 'white',
    },
    background: {
        flex: 1,
        resizeMode: 'cover',
        justifyContent: 'center',
    },
    rowContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: '100%',
    },
});

export default BusBookingConfirmation;
