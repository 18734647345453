import React, { useState, useEffect, useRef } from 'react';
import { 
  StyleSheet, 
  View, 
  Text, 
  TouchableOpacity, 
  ScrollView, 
  ImageBackground, 
  FlatList, 
  Dimensions, 
  Animated, 
  useWindowDimensions
} from 'react-native';
import { StatusBar } from 'expo-status-bar';
import { Ionicons } from '@expo/vector-icons';
import * as Font from 'expo-font';
import ScreenLayout from './ScreenLayout';

const fetchFonts = () => {
  return Font.loadAsync({
    'Roboto-Regular': require('../assets/fonts/Roboto-Regular.ttf'),
    'Roboto-Bold': require('../assets/fonts/Roboto-Bold.ttf'),
    'Roboto-Medium': require('../assets/fonts/Roboto-Medium.ttf'),
  });
};

const popularDestinations = [
  { id: '1', name: 'Mumbai', image: require('../assets/destinations/Mumbai-dest.png') },
  { id: '2', name: 'Delhi', image: require('../assets/destinations/Delhi-dest.png') },
  { id: '3', name: 'Bangalore', image: require('../assets/destinations/Bangalore-dest.png') },
  { id: '4', name: 'Hyderabad', image: require('../assets/destinations/Hyderabad-dest.png') },
  { id: '5', name: 'Chennai', image: require('../assets/destinations/Chennai-dest.png') },
  { id: '6', name: 'Kerala', image: require('../assets/destinations/Kerala-dest.png') },
  { id: '7', name: 'Pune', image: require('../assets/destinations/Pune-dest.png') },
  { id: '8', name: 'Vijayawada', image: require('../assets/destinations/Vijayawada-dest.png') },
  { id: '9', name: 'Jaipur', image: require('../assets/destinations/Jaipur-dest.png') },
];

const deals = [
  { id: '1', title: 'Earn Rewards', description: 'Earn 1% Back As Rewards On All Bookings.', image: require('../assets/offers/EarnRewards.png') },
  { id: '2', title: 'Zero Service charge', description: 'No Additional Service Charge On All Bookings.', image: require('../assets/offers/NoServiceCharge.png') },
];

const backgroundImages = [
  require('../assets/HomeScreenBackground.png'),
  require('../assets/HomeScreenBackgroundImage2.png'),
  require('../assets/HomeScreenBackgroundImage3.png'),
  require('../assets/HomeScreenBackgroundImage4.png'),
];

const DestinationCard = ({ name, image }) => (
  <TouchableOpacity style={styles.destinationCard}>
    <ImageBackground source={image} style={styles.destinationImage}>
      <View style={styles.destinationTextContainer}>
        <Text style={styles.destinationText}>{name}</Text>
      </View>
    </ImageBackground>
  </TouchableOpacity>
);

const DealCard = ({ title, description, image }) => (
  <TouchableOpacity style={styles.dealCard}>
    <ImageBackground source={image} style={styles.dealImage}>
      <View style={styles.dealTextContainer}>
        <Text style={styles.dealTitle}>{title}</Text>
        <Text style={styles.dealDescription}>{description}</Text>
      </View>
    </ImageBackground>
  </TouchableOpacity>
);

const HomeScreen = ({ navigation }) => {
  const { width: screenWidth } = useWindowDimensions();
  const [fontLoaded, setFontLoaded] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const fadeAnim = useRef(new Animated.Value(1)).current;

  const [isWideScreen, setIsWideScreen] = useState(screenWidth >= 768);

  useEffect(() => {
    const loadFonts = async () => {
      await fetchFonts();
      setFontLoaded(true);
    };
    loadFonts();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      fadeOut();
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const handleDimensionsChange = ({ window }) => {
      setIsWideScreen(window.width >= 768);
    };

    const subscription = Dimensions.addEventListener('change', handleDimensionsChange);

    return () => subscription.remove();
  }, []);

  const fadeOut = () => {
    Animated.timing(fadeAnim, {
      toValue: 0,
      duration: 1000,
      useNativeDriver: true,
    }).start(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % backgroundImages.length);
      fadeIn();
    });
  };

  const fadeIn = () => {
    Animated.timing(fadeAnim, {
      toValue: 1,
      duration: 1000,
      useNativeDriver: true,
    }).start();
  };

  const handleBookNow = () => {
    navigation.navigate('Bus');
  };

  const renderBookNowButton = () => (
    <View style={styles.bookNowContainer}>
      <TouchableOpacity style={styles.bookNowButton} onPress={handleBookNow}>
        <Text style={styles.bookNowButtonText}>Book Now</Text>
      </TouchableOpacity>
    </View>
  );

  return (
    <ScreenLayout navigation={navigation}>
      <StatusBar style="light" />
      <ScrollView style={styles.scrollView}>
        <View style={styles.heroContainer}>
          <Animated.View style={[styles.heroImageContainer, { opacity: fadeAnim }]}>
            <ImageBackground
              source={backgroundImages[currentImageIndex]}
              style={styles.heroImage}
            />
          </Animated.View>
          <View style={styles.heroContent}>
            <Text style={styles.heroTitle}>Discover India by Bus</Text>
            {renderBookNowButton()}
          </View>
        </View>

        <View style={styles.content}>
          <View style={styles.sectionContainer}>
            <Text style={styles.sectionTitle}>Popular Destinations</Text>
            <FlatList
              data={popularDestinations}
              renderItem={({ item }) => <DestinationCard name={item.name} image={item.image} />}
              keyExtractor={item => item.id}
              horizontal
              showsHorizontalScrollIndicator={false}
            />
          </View>

          <View style={styles.sectionContainer}>
            <Text style={styles.sectionTitle}>Deals & Offers</Text>
            {deals.map((deal) => (
              <DealCard key={deal.id} title={deal.title} description={deal.description} image={deal.image} />
            ))}
          </View>

          <View style={styles.featuresContainer}>
            <Text style={styles.sectionTitle}>Why Choose BYGBUS?</Text>
            <View style={styles.featuresGrid}>
              <View style={styles.featureItem}>
                <Ionicons name="bus-outline" size={32} color="#007AFF" />
                <Text style={styles.featureText}>Extensive Network</Text>
              </View>
              <View style={styles.featureItem}>
                <Ionicons name="time-outline" size={32} color="#007AFF" />
                <Text style={styles.featureText}>24/7 Support</Text>
              </View>
              <View style={styles.featureItem}>
                <Ionicons name="shield-checkmark-outline" size={32} color="#007AFF" />
                <Text style={styles.featureText}>Secure Booking</Text>
              </View>
              <View style={styles.featureItem}>
                <Ionicons name="cash-outline" size={32} color="#007AFF" />
                <Text style={styles.featureText}>Best Prices</Text>
              </View>
            </View>
          </View>
        </View>
      </ScrollView>
    </ScreenLayout>
  );
};

const styles = StyleSheet.create({
  scrollView: {
    flex: 1,
    backgroundColor: '#F2F2F7',
  },
  heroContainer: {
    height: 500,
    position: 'relative',
    marginBottom: 20
  },
  heroImageContainer: {
    ...StyleSheet.absoluteFillObject,
  },
  heroImage: {
    width: '100%',
    height: '100%',
  },
  heroContent: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    padding: 20,
    justifyContent: 'center',
    alignItems: 'center',
  },
  heroTitle: {
    fontFamily: 'Roboto-Bold',
    fontSize: 36,
    color: '#FFFFFF',
    textAlign: 'center',
    marginBottom: 30,
  },
  bookNowContainer: {
    width: '100%',
    alignItems: 'center',
  },
  bookNowButton: {
    backgroundColor: '#007AFF',
    borderRadius: 5,
    paddingVertical: 15,
    paddingHorizontal: 30,
    marginTop: 20,
  },
  bookNowButtonText: {
    fontFamily: 'Roboto-Bold',
    fontSize: 18,
    color: '#FFFFFF',
    textAlign: 'center',
  },
  content: {
    padding: 20,
  },
  sectionContainer: {
    marginBottom: 30,
  },
  sectionTitle: {
    fontFamily: 'Roboto-Bold',
    fontSize: 24,
    color: '#000000',
    marginBottom: 15,
  },
  destinationCard: {
    width: 150,
    height: 200,
    marginRight: 15,
    borderRadius: 10,
    overflow: 'hidden',
  },
  destinationImage: {
    width: '100%',
    height: '100%',
    justifyContent: 'flex-end',
  },
  destinationTextContainer: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    padding: 10,
  },
  destinationText: {
    fontFamily: 'Roboto-Bold',
    fontSize: 16,
    color: '#FFFFFF',
  },
  dealCard: {
    height: 200,
    marginBottom: 15,
    borderRadius: 10,
    overflow: 'hidden',
  },
  dealImage: {
    width: '100%',
    height: '100%',
    justifyContent: 'flex-end',
  },
  dealTextContainer: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    padding: 15,
  },
  dealTitle: {
    fontFamily: 'Roboto-Bold',
    fontSize: 20,
    color: '#FFFFFF',
    marginBottom: 5,
  },
  dealDescription: {
    fontFamily: 'Roboto-Regular',
    fontSize: 14,
    color: '#FFFFFF',
  },
  featuresContainer: {
    marginBottom: 30,
  },
  featuresGrid: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  featureItem: {
    width: '48%',
    backgroundColor: '#FFFFFF',
    borderRadius: 10,
    padding: 15,
    alignItems: 'center',
    marginBottom: 15,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 4,
    elevation: 3,
  },
  featureText: {
    fontFamily: 'Roboto-Medium',
    fontSize: 14,
    color: '#000000',
    marginTop: 10,
    textAlign: 'center',
  },
});

export default HomeScreen;