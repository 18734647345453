import React, { useState, useEffect } from "react";
import { View, Text, StyleSheet, Dimensions, TouchableOpacity, ScrollView, Modal } from "react-native";
import { Ionicons, MaterialCommunityIcons, MaterialIcons } from '@expo/vector-icons';
import Slider from '@react-native-community/slider';

const { width } = Dimensions.get('window');

const COLORS = {
    primary: '#059669',
    white: '#FFFFFF',
    background: '#f5f5f5',
    border: '#E5E7EB',
    text: {
        primary: '#1a1a1a',
        secondary: '#4A5568',
        light: '#718096',
    }
};

const BusAvailabilitiesFilterSort = ({ onFilterChange, onSortChange, busAvailabilities, operators, boardingPoints, dropOffPoints }) => {
    const initialFilters = {
        ac: false,
        nonAc: false,
        sleeper: false,
        semiSleeper: false,
        pushback: false,
        minPrice: 100,
        maxPrice: 10000,
        operators: [],
        boardingPoints: [],
        dropOffPoints: [],
    };

    const initialSortBy = {
        price: null,
        departure: null,
        arrival: null
    };

    const [filters, setFilters] = useState(initialFilters);
    const [sortBy, setSortBy] = useState(initialSortBy);
    const [showFilters, setShowFilters] = useState(false);
    const [selectedFilterCount, setSelectedFilterCount] = useState(0);
    const [modalVisible, setModalVisible] = useState(false);
    const [modalType, setModalType] = useState(null);

    const quickFilterOptions = [
        { key: 'ac', icon: 'snow', label: 'AC', IconComponent: Ionicons },
        { key: 'nonAc', icon: 'snow-outline', label: 'Non AC', IconComponent: Ionicons },
        { key: 'sleeper', icon: 'airline-seat-individual-suite', label: 'Sleeper', IconComponent: MaterialIcons },
        { key: 'semiSleeper', icon: 'airline-seat-flat-angled', label: 'Semi Sleeper', IconComponent: MaterialIcons },
    ];

    const sortOptions = [
        {label: 'Price ↑', value: 'Price ↑', category: 'price'},
        {label: 'Price ↓', value: 'Price ↓', category: 'price'},
        {label: 'Departure ↑', value: 'Departure ↑', category: 'departure'},
        {label: 'Departure ↓', value: 'Departure ↓', category: 'departure'},
    ];

    // Sort operators, boarding points, and drop-off points alphabetically
    const sortedOperators = [...operators].sort((a, b) => a.localeCompare(b));
    const sortedBoardingPoints = [...boardingPoints].sort((a, b) => a.localeCompare(b));
    const sortedDropOffPoints = [...dropOffPoints].sort((a, b) => a.localeCompare(b));

    useEffect(() => {
        const countSelectedFilters = () => {
            const filterKeys = ['ac', 'nonAc', 'sleeper', 'semiSleeper', 'pushback'];
            let count = filterKeys.filter(key => filters[key]).length;
            count += filters.operators.length;
            count += filters.boardingPoints.length;
            count += filters.dropOffPoints.length;
            count += Object.values(sortBy).filter(Boolean).length;
            setSelectedFilterCount(count);
        };
        countSelectedFilters();
    }, [filters, sortBy]);

    const handlePress = (filter) => {
        setFilters(prevFilters => ({ ...prevFilters, [filter]: !prevFilters[filter] }));
    };

    const handleQuickFilter = (key) => {
        const newFilters = { ...filters, [key]: !filters[key] };
        setFilters(newFilters);
        onFilterChange(newFilters);
    };

    const handleQuickSort = (option) => {
        const newSortBy = {
            ...initialSortBy,
            [option.category]: sortBy[option.category] === option.value ? null : option.value
        };
        setSortBy(newSortBy);
        onSortChange(newSortBy);
    };

    const handleSliderChange = (field, value) => {
        setFilters(prevFilters => ({ ...prevFilters, [field]: value }));
    };

    const applyFilters = () => {
        onFilterChange(filters);
        onSortChange(sortBy);
        setShowFilters(false);
    };

    const clearFilters = () => {
        setFilters(initialFilters);
        setSortBy(initialSortBy);
        onFilterChange(initialFilters);
        onSortChange(initialSortBy);
        setShowFilters(false);
    };

    const openModal = (type) => {
        setModalType(type);
        setModalVisible(true);
    };

    const closeModal = () => {
        setModalVisible(false);
        setModalType(null);
    };

    const handleMultiSelect = (item) => {
        setFilters(prevFilters => {
            const currentSelection = prevFilters[modalType];
            const updatedSelection = currentSelection.includes(item)
                ? currentSelection.filter(i => i !== item)
                : [...currentSelection, item];
            return { ...prevFilters, [modalType]: updatedSelection };
        });
    };

    const handleSortSelect = (item) => {
        setSortBy(prevSortBy => {
            const newSortBy = { ...prevSortBy };
            if (newSortBy[item.category] === item.value) {
                newSortBy[item.category] = null;
            } else {
                Object.keys(newSortBy).forEach(key => {
                    newSortBy[key] = null;
                });
                newSortBy[item.category] = item.value;
            }
            return newSortBy;
        });
    };

    const getSelectedItemsText = (type) => {
        const selectedItems = filters[type];
        if (selectedItems.length === 0) return `Select ${type.replace(/([A-Z])/g, ' $1').toLowerCase()}`;
        if (selectedItems.length === 1) return selectedItems[0];
        return `${selectedItems.length} selected`;
    };

    const renderModalContent = () => {
        let options;
        switch (modalType) {
            case 'operators':
                options = sortedOperators;
                break;
            case 'boardingPoints':
                options = sortedBoardingPoints;
                break;
            case 'dropOffPoints':
                options = sortedDropOffPoints;
                break;
            case 'sortBy':
                options = sortOptions;
                break;
            default:
                return null;
        }

        return (
            <View style={styles.modalContent}>
                <Text style={styles.modalTitle}>
                    {modalType.charAt(0).toUpperCase() + modalType.slice(1).replace(/([A-Z])/g, ' $1')}
                </Text>
                <ScrollView style={styles.modalScrollView}>
                    {options.map((item) => (
                        <TouchableOpacity
                            key={modalType === 'sortBy' ? item.value : item}
                            style={[
                                styles.modalItem,
                                modalType === 'sortBy'
                                    ? sortBy[item.category] === item.value && styles.selectedModalItem
                                    : filters[modalType].includes(item) && styles.selectedModalItem
                            ]}
                            onPress={() => modalType === 'sortBy' ? handleSortSelect(item) : handleMultiSelect(item)}
                        >
                            <Text style={[
                                styles.modalItemText,
                                modalType === 'sortBy'
                                    ? sortBy[item.category] === item.value && styles.selectedModalItemText
                                    : filters[modalType].includes(item) && styles.selectedModalItemText
                            ]}>
                                {modalType === 'sortBy' ? item.label : item}
                            </Text>
                        </TouchableOpacity>
                    ))}
                </ScrollView>
                <TouchableOpacity style={styles.modalCloseButton} onPress={closeModal}>
                    <Text style={styles.modalCloseButtonText}>Apply</Text>
                </TouchableOpacity>
            </View>
        );
    };

    return (
        <View style={styles.stickyContainer}>
            <View style={styles.container}>
                <View style={styles.header}>
                    <View style={styles.headerTop}>
                        <Text style={styles.headerText}>
                            Filter & Sort
                            {selectedFilterCount > 0 && (
                                <Text style={styles.filterCount}> ({selectedFilterCount})</Text>
                            )}
                        </Text>
                        <TouchableOpacity onPress={() => setShowFilters(!showFilters)}>
                            <Ionicons 
                                name={showFilters ? "chevron-up" : "chevron-down"} 
                                size={24} 
                                color={COLORS.white} 
                            />
                        </TouchableOpacity>
                    </View>
                    <ScrollView 
                        horizontal 
                        showsHorizontalScrollIndicator={false} 
                        style={styles.quickOptionsContainer}
                    >
                        {/* Quick Filters */}
                        {quickFilterOptions.map(({ key, icon, label, IconComponent }) => (
                            <TouchableOpacity
                                key={key}
                                style={[
                                    styles.quickOptionButton,
                                    filters[key] && styles.quickOptionButtonActive
                                ]}
                                onPress={() => handleQuickFilter(key)}
                            >
                                <IconComponent 
                                    name={icon} 
                                    size={16} 
                                    color={filters[key] ? COLORS.primary : COLORS.white} 
                                    style={styles.quickOptionIcon}
                                />
                                <Text style={[
                                    styles.quickOptionText,
                                    filters[key] && styles.quickOptionTextActive
                                ]}>
                                    {label}
                                </Text>
                            </TouchableOpacity>
                        ))}

                        <View style={styles.quickOptionsDivider} />

                        {/* Quick Sort Options */}
                        {sortOptions.map((option) => (
                            <TouchableOpacity
                                key={option.value}
                                style={[
                                    styles.quickOptionButton,
                                    sortBy[option.category] === option.value && styles.quickOptionButtonActive
                                ]}
                                onPress={() => handleQuickSort(option)}
                            >
                                <Text style={[
                                    styles.quickOptionText,
                                    sortBy[option.category] === option.value && styles.quickOptionTextActive
                                ]}>
                                    {option.label}
                                </Text>
                            </TouchableOpacity>
                        ))}
                    </ScrollView>
                </View>

                {showFilters && (
                    <ScrollView style={styles.filterContent}>
                        <View style={styles.section}>
                            <Text style={styles.sectionTitle}>Bus Type</Text>
                            <View style={styles.buttonGroup}>
                                {[
                                    { key: 'ac', icon: 'snow', label: 'AC', IconComponent: Ionicons },
                                    { key: 'nonAc', icon: 'snow-outline', label: 'Non AC', IconComponent: Ionicons },
                                    { key: 'sleeper', icon: 'airline-seat-individual-suite', label: 'Sleeper', IconComponent: MaterialIcons },
                                    { key: 'semiSleeper', icon: 'airline-seat-flat-angled', label: 'Semi Sleeper', IconComponent: MaterialIcons },
                                    { key: 'pushback', icon: 'seat-recline-extra', label: 'Pushback', IconComponent: MaterialCommunityIcons },
                                ].map(({ key, icon, label, IconComponent }) => (
                                    <TouchableOpacity 
                                        key={key}
                                        style={[styles.filterButton, filters[key] && styles.selectedButton]} 
                                        onPress={() => handlePress(key)}
                                    >
                                        <IconComponent 
                                            name={icon} 
                                            size={24} 
                                            color={filters[key] ? COLORS.white : COLORS.text.primary} 
                                        />
                                        <Text style={[
                                            styles.buttonText, 
                                            filters[key] && styles.selectedButtonText
                                        ]}>
                                            {label}
                                        </Text>
                                    </TouchableOpacity>
                                ))}
                            </View>
                        </View>

                        <View style={styles.section}>
                            <Text style={styles.sectionTitle}>Price Range</Text>
                            <Slider
                                style={styles.slider}
                                minimumValue={100}
                                maximumValue={10000}
                                step={100}
                                minimumTrackTintColor={COLORS.primary}
                                maximumTrackTintColor="#ddd"
                                thumbTintColor={COLORS.primary}
                                value={filters.maxPrice}
                                onValueChange={value => handleSliderChange('maxPrice', value)}
                            />
                            <Text style={styles.sliderValue}>
                                ₹{filters.minPrice} - ₹{filters.maxPrice}
                            </Text>
                        </View>
                        
                        {['operators', 'boardingPoints', 'dropOffPoints'].map((type) => (
                            <View key={type} style={styles.section}>
                                <Text style={styles.sectionTitle}>
                                    {type.charAt(0).toUpperCase() + type.slice(1).replace(/([A-Z])/g, ' $1')}
                                </Text>
                                <TouchableOpacity 
                                    style={styles.dropdownButton} 
                                    onPress={() => openModal(type)}
                                >
                                    <Text style={styles.dropdownButtonText}>
                                        {getSelectedItemsText(type)}
                                    </Text>
                                    <Ionicons name="chevron-down" size={24} color="#333" />
                                </TouchableOpacity>
                            </View>
                        ))}

                        <View style={styles.buttonContainer}>
                            <TouchableOpacity 
                                onPress={clearFilters} 
                                style={styles.clearButton}
                            >
                                <Text style={styles.clearButtonText}>Clear All</Text>
                            </TouchableOpacity>
                            <TouchableOpacity 
                                onPress={applyFilters} 
                                style={styles.applyButton}
                            >
                                <Text style={styles.applyButtonText}>Apply</Text>
                            </TouchableOpacity>
                        </View>
                    </ScrollView>
                )}

                <Modal
                    animationType="slide"
                    transparent={true}
                    visible={modalVisible}
                    onRequestClose={closeModal}
                >
                    <View style={styles.modalContainer}>
                        {renderModalContent()}
                    </View>
                </Modal>
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    stickyContainer: {
        position: 'sticky',
        top: 0,
        zIndex: 100,
        backgroundColor: COLORS.white,
        shadowColor: "#000",
        shadowOffset: {width: 0, height: 2},
        shadowOpacity: 0.1,
        shadowRadius: 4,
        elevation: 3,
    },
    container: {
        backgroundColor: COLORS.background,
        borderRadius: 8,
        overflow: 'hidden',
        margin: 6,
        width: '97%',
        marginHorizontal: '1.5%',
    },
    header: {
        backgroundColor: COLORS.primary,
        paddingBottom: 6,
    },
    headerTop: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: 8,
        height: 36,
    },
    headerText: {
        fontSize: 14,
        fontWeight: '600',
        color: COLORS.white,
    },
    filterCount: {
        fontSize: 12,
        color: COLORS.white,
    },
    quickOptionsContainer: {
        paddingHorizontal: 8,
        height: 32,
    },
    quickOptionButton: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingHorizontal: 8,
        paddingVertical: 4,
        borderRadius: 12,
        backgroundColor: 'rgba(255, 255, 255, 0.2)',
        marginRight: 6,
        height: 24,
    },
    quickOptionButtonActive: {
        backgroundColor: COLORS.white,
    },
    quickOptionIcon: {
        marginRight: 2,
    },
    quickOptionText: {
        fontSize: 11,
        color: COLORS.white,
        fontWeight: '500',
    },
    quickOptionTextActive: {
        color: COLORS.primary,
    },
    quickOptionsDivider: {
        width: 1,
        height: '100%',
        backgroundColor: 'rgba(255, 255, 255, 0.3)',
        marginHorizontal: 6,
    },
    filterContent: {
        padding: 10,
    },
    section: {
        marginBottom: 12,
    },
    sectionTitle: {
        fontSize: 14,
        fontWeight: '600',
        marginBottom: 6,
        color: COLORS.text.primary,
    },
    buttonGroup: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        gap: 6,
    },
    filterButton: {
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: COLORS.white,
        borderRadius: 16,
        paddingVertical: 6,
        paddingHorizontal: 8,
        borderWidth: 1,
        borderColor: COLORS.border,
    },
    selectedButton: {
        backgroundColor: COLORS.primary,
        borderColor: COLORS.primary,
    },
    buttonText: {
        marginLeft: 4,
        fontSize: 12,
        color: COLORS.text.primary,
    },
    selectedButtonText: {
        color: COLORS.white,
    },
    slider: {
        width: '100%',
        height: 32,
    },
    sliderValue: {
        textAlign: 'center',
        marginTop: 4,
        fontSize: 12,
        color: COLORS.text.secondary,
    },
    dropdownButton: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: COLORS.white,
        borderRadius: 8,
        borderWidth: 1,
        borderColor: COLORS.border,
        padding: 8,
        height: 40,
    },
    dropdownButtonText: {
        fontSize: 12,
        color: COLORS.text.primary,
    },
    buttonContainer: {
        flexDirection: 'row',
        gap: 8,
        marginTop: 16,
    },
    clearButton: {
        flex: 1,
        backgroundColor: COLORS.white,
        borderRadius: 8,
        paddingVertical: 10,
        alignItems: 'center',
        borderWidth: 1,
        borderColor: COLORS.primary,
    },
    clearButtonText: {
        color: COLORS.primary,
        fontSize: 14,
        fontWeight: '600',
    },
    applyButton: {
        flex: 1,
        backgroundColor: COLORS.primary,
        borderRadius: 8,
        paddingVertical: 10,
        alignItems: 'center',
    },
    applyButtonText: {
        color: COLORS.white,
        fontSize: 14,
        fontWeight: '600',
    },
    modalContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        padding: 16,
    },
    modalContent: {
        backgroundColor: COLORS.white,
        borderRadius: 12,
        padding: 16,
        width: '100%',
        maxHeight: '80%',
    },
    modalTitle: {
        fontSize: 16,
        fontWeight: '600',
        marginBottom: 12,
        textAlign: 'center',
        color: COLORS.text.primary,
    },
    modalScrollView: {
        maxHeight: 350,
    },
    modalItem: {
        padding: 12,
        borderBottomWidth: 1,
        borderBottomColor: COLORS.border,
    },
    selectedModalItem: {
        backgroundColor: '#e6f7ff',
    },
    modalItemText: {
        fontSize: 14,
        color: COLORS.text.primary,
    },
    selectedModalItemText: {
        color: COLORS.primary,
        fontWeight: '600',
    },
    modalCloseButton: {
        marginTop: 16,
        padding: 10,
        backgroundColor: COLORS.primary,
        borderRadius: 8,
        alignItems: 'center',
    },
    modalCloseButtonText: {
        color: COLORS.white,
        fontSize: 14,
        fontWeight: '600',
    }
 });

export default BusAvailabilitiesFilterSort;