import React, { useState, useEffect, useRef , useMemo} from 'react';
import {
  StyleSheet,
  View,
  TextInput,
  Text,
  TouchableOpacity,
  ScrollView,
  ImageBackground,
  FlatList,
  Dimensions,
  Animated,
  useWindowDimensions,
  ActivityIndicator
} from 'react-native';
import { StatusBar } from 'expo-status-bar';
import { Ionicons, MaterialIcons } from '@expo/vector-icons';
import * as Font from 'expo-font';
import { LinearGradient } from 'expo-linear-gradient';
import * as Clipboard from 'expo-clipboard';
import ScreenLayout from './ScreenLayout';
import axios from 'axios';
import Constants from 'expo-constants';
import { useUser } from './UserContext';
import { Helmet } from 'react-helmet';
import SearchContainer from './HeroSearchContainer';

const API_URL = Constants.expoConfig.extra.apiUrl;
const API_TOKEN = Constants.expoConfig.extra.apiToken;

const THEME = {
  colors: {
    primary: '#2563EB',        // Royal Blue
    secondary: '#3B82F6',      // Lighter Blue
    accent: '#0EA5E9',         // Sky Blue
    success: '#10B981',        // Emerald
    gradient: {
      start: '#3B82F6',
      end: '#2563EB',
    },
    text: {
      primary: '#1F2937',
      secondary: '#4B5563',
      light: '#9CA3AF',
      white: '#FFFFFF',
    },
    border: '#E5E7EB',
    background: {
      light: '#F3F4F6',
      white: '#FFFFFF',
    }
  }
};

const fetchFonts = () => {
  return Font.loadAsync({
    'Roboto-Regular': require('../assets/fonts/Roboto-Regular.ttf'),
    'Roboto-Bold': require('../assets/fonts/Roboto-Bold.ttf'),
    'Roboto-Medium': require('../assets/fonts/Roboto-Medium.ttf'),
  });
};

const popularDestinations = [
  { id: '1', name: 'Mumbai', image: require('../assets/destinations/Mumbai-dest.png') },
  { id: '2', name: 'Delhi', image: require('../assets/destinations/Delhi-dest.png') },
  { id: '3', name: 'Bangalore', image: require('../assets/destinations/Bangalore-dest.png') },
  { id: '4', name: 'Hyderabad', image: require('../assets/destinations/Hyderabad-dest.png') },
  { id: '5', name: 'Chennai', image: require('../assets/destinations/Chennai-dest.png') },
  { id: '6', name: 'Kerala', image: require('../assets/destinations/Kerala-dest.png') },
  { id: '7', name: 'Pune', image: require('../assets/destinations/Pune-dest.png') },
  { id: '8', name: 'Vijayawada', image: require('../assets/destinations/Vijayawada-dest.png') },
  { id: '9', name: 'Jaipur', image: require('../assets/destinations/Jaipur-dest.png') },
];

const backgroundImages = [
  require('../assets/HomeScreenBackground.png'),
  require('../assets/HomeScreenBackgroundImage2.png'),
  require('../assets/HomeScreenBackgroundImage3.png'),
  require('../assets/HomeScreenBackgroundImage4.png'),
];

const DestinationCard = ({ name, image }) => (
  <TouchableOpacity style={styles.destinationCard}>
    <ImageBackground source={image} style={styles.destinationImage}>
      <View style={styles.destinationTextContainer}>
        <Text style={styles.destinationText}>{name}</Text>
      </View>
    </ImageBackground>
  </TouchableOpacity>
);

const RouteCard = ({ origin, destination }) => (
  <TouchableOpacity style={styles.routeCard}>
    <View style={styles.routeContent}>
      <View style={styles.routeOrigin}>
        <MaterialIcons name="trip-origin" size={14} color={THEME.colors.primary} />
        <Text style={styles.routeText}>{origin}</Text>
      </View>
      <MaterialIcons 
        name="keyboard-arrow-right" 
        size={14} 
        color={THEME.colors.text.secondary}
        style={styles.routeArrow}
      />
      <View style={styles.routeDestination}>
        <MaterialIcons name="location-on" size={14} color={THEME.colors.accent} />
        <Text style={styles.routeText}>{destination}</Text>
      </View>
    </View>
  </TouchableOpacity>
);

const DealCard = ({ title, description, icon, details, offerCode, navigation }) => {
  const [expanded, setExpanded] = useState(false);
  const animatedHeight = useRef(new Animated.Value(0)).current;
  const scaleValue = useRef(new Animated.Value(1)).current;

  useEffect(() => {
    Animated.timing(animatedHeight, {
      toValue: expanded ? 1 : 0,
      duration: 300,
      useNativeDriver: false,
    }).start();
  }, [expanded]);

  const onPressIn = () => {
    Animated.spring(scaleValue, {
      toValue: 0.98,
      useNativeDriver: true,
    }).start();
  };

  const onPressOut = () => {
    Animated.spring(scaleValue, {
      toValue: 1,
      useNativeDriver: true,
    }).start();
  };

  const handleCopyCode = async () => {
    await Clipboard.setStringAsync(offerCode);
    // Optional: Add a toast or alert here to confirm copy
  };

  const handleApplyCode = () => {
    navigation.navigate('Bus', { promoCode: offerCode });
  };

  return (
    <TouchableOpacity
      activeOpacity={0.9}
      onPress={() => setExpanded(!expanded)}
      onPressIn={onPressIn}
      onPressOut={onPressOut}
    >
      <Animated.View style={[
        styles.dealCard,
        { transform: [{ scale: scaleValue }] }
      ]}>
        <LinearGradient
          colors={[THEME.colors.gradient.start, THEME.colors.gradient.end]}
          start={{ x: 0, y: 0 }}
          end={{ x: 1, y: 1 }}
          style={styles.dealGradient}
        >
          <View style={styles.dealHeader}>
            <View style={styles.iconContainer}>
              <MaterialIcons name={icon} size={24} color={THEME.colors.text.white} />
            </View>
            <View style={styles.dealHeaderText}>
              <Text style={styles.dealTitle}>{title}</Text>
              <Text style={styles.dealDescription}>{description}</Text>
            </View>
            <MaterialIcons
              name={expanded ? "keyboard-arrow-up" : "keyboard-arrow-down"}
              size={24}
              color={THEME.colors.text.white}
            />
          </View>
        </LinearGradient>

        <Animated.View style={[
          styles.dealDetails,
          {
            maxHeight: animatedHeight.interpolate({
              inputRange: [0, 1],
              outputRange: [0, 1000]
            }),
            opacity: animatedHeight
          }
        ]}>
          {details && (
            <View style={styles.detailsContent}>
              <Text style={styles.dealBenefitsTitle}>Benefits</Text>
              {details.benefits.map((benefit, index) => (
                <View key={index} style={styles.benefitItem}>
                  <View style={styles.checkCircle}>
                    <MaterialIcons name="check" size={16} color={THEME.colors.text.white} />
                  </View>
                  <Text style={styles.benefitText}>{benefit}</Text>
                </View>
              ))}
              {offerCode && (
                <View style={styles.offerCodeContainer}>
                  <View style={styles.offerCodeContent}>
                    <Text style={styles.offerCodeLabel}>PROMO CODE:</Text>
                    <Text style={styles.offerCode}>{offerCode}</Text>
                  </View>
                  <View style={styles.actionsContainer}>
                    <TouchableOpacity
                      style={styles.copyButton}
                      onPress={handleCopyCode}
                    >
                      <MaterialIcons name="content-copy" size={20} color={THEME.colors.primary} />
                    </TouchableOpacity>
                  </View>
                </View>
              )}
            </View>
          )}
        </Animated.View>
      </Animated.View>
    </TouchableOpacity>
  );
};
const HomeScreen = ({ navigation }) => {
  const { width: screenWidth } = useWindowDimensions();
  const [fontLoaded, setFontLoaded] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const fadeAnim = useRef(new Animated.Value(1)).current;
  const [deals, setDeals] = useState([]);
  const [isLoadingDeals, setIsLoadingDeals] = useState(true);
  const [error, setError] = useState(null);
  const [isWideScreen, setIsWideScreen] = useState(screenWidth >= 768);
  const { user, userPoints } = useUser();
  const [searchReset, setSearchReset] = useState(false);
  const [allOperators, setAllOperators] = useState([]); // Store all operators
  const [selectedLetter, setSelectedLetter] = useState(null);
  const [isLoadingOperators, setIsLoadingOperators] = useState(false);
  const [operatorError, setOperatorError] = useState(null);
  const [popularRoutes, setPopularRoutes] = useState([]);
const [isLoadingRoutes, setIsLoadingRoutes] = useState(true);
const [routesError, setRoutesError] = useState(null);

  

  useEffect(() => {
    const loadFonts = async () => {
      await fetchFonts();
      setFontLoaded(true);
    };
    loadFonts();
    loadPromotions();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      fadeOut();
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    loadPopularRoutes();
  }, []);

  useEffect(() => {
    const handleDimensionsChange = ({ window }) => {
      setIsWideScreen(window.width >= 768);
    };
    const subscription = Dimensions.addEventListener('change', handleDimensionsChange);
    return () => subscription.remove();
  }, []);

  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      setSearchReset(true);
    });

    return unsubscribe;
  }, [navigation]);

  useEffect(() => {
    loadAllOperators();
  }, []);

  const loadPopularRoutes = async () => {
    setIsLoadingRoutes(true);
    setRoutesError(null);
    try {
      const response = await axios.get(`${API_URL}/popular-routes`, {
        headers: {
          'x-api-token': API_TOKEN
        }
      });
      setPopularRoutes(response.data.popularRoutes);
    } catch (error) {
      console.error('Error fetching popular routes:', error);
      setRoutesError('Failed to load popular routes.');
    } finally {
      setIsLoadingRoutes(false);
    }
  };

  const filteredOperators = useMemo(() => {
    if (!selectedLetter) return [];
    return allOperators.filter(operator => 
      operator.toUpperCase().startsWith(selectedLetter)
    );
  }, [selectedLetter, allOperators]);

  const loadAllOperators = async () => {
    setIsLoadingOperators(true);
    setOperatorError(null);
    try {
      const response = await axios.get(`${API_URL}/operators`, {
        headers: {
          'x-api-token': API_TOKEN
        }
      });
      setAllOperators(response.data.sort((a, b) => a.localeCompare(b)));
    } catch (error) {
      console.error('Error fetching operators:', error);
      setOperatorError('Failed to load operators. Please try again.');
    } finally {
      setIsLoadingOperators(false);
    }
  };

  const handleSearch = (searchParams) => {
    setSearchReset(false); // Reset the flag after search
    navigation.navigate('Bus', searchParams);
  };

  const loadPromotions = async () => {
    setIsLoadingDeals(true);
    setError(null);
    try {
      const response = await axios.get(`${API_URL}/offers`, {
        headers: {
          'x-api-token': API_TOKEN
        }
      });
      setDeals(response.data);
    } catch (error) {
      console.error('Error fetching deals:', error);
      setError('Failed to load deals. Please try again later.');
    } finally {
      setIsLoadingDeals(false);
    }
  };

  const fadeOut = () => {
    Animated.timing(fadeAnim, {
      toValue: 0,
      duration: 1000,
      useNativeDriver: true,
    }).start(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % backgroundImages.length);
      fadeIn();
    });
  };

  const fadeIn = () => {
    Animated.timing(fadeAnim, {
      toValue: 1,
      duration: 1000,
      useNativeDriver: true,
    }).start();
  };

  const handleBookNow = () => {
    navigation.navigate('Bus');
  };

  const renderOperatorsSection = () => (
    <View style={styles.sectionContainer}>
      <Text style={styles.sectionTitle}>Bus Operators</Text>
      
      <View style={styles.alphabetContainer}>
        <ScrollView horizontal showsHorizontalScrollIndicator={false}>
          {[...Array(26)].map((_, i) => {
            const letter = String.fromCharCode(65 + i);
            return (
              <TouchableOpacity
                key={letter}
                style={[
                  styles.letterButton,
                  selectedLetter === letter && styles.letterButtonActive
                ]}
                onPress={() => setSelectedLetter(selectedLetter === letter ? null : letter)}
              >
                <Text style={[
                  styles.letterText,
                  selectedLetter === letter && styles.letterTextActive
                ]}>
                  {letter}
                </Text>
              </TouchableOpacity>
            );
          })}
        </ScrollView>
      </View>

      {selectedLetter && (
        <View style={styles.operatorsContainer}>
          {isLoadingOperators ? (
            <ActivityIndicator size="large" color={THEME.colors.primary} />
          ) : operatorError ? (
            <View style={styles.errorContainer}>
              <Text style={styles.errorText}>{operatorError}</Text>
              <TouchableOpacity 
                style={styles.retryButton} 
                onPress={loadAllOperators}
              >
                <Text style={styles.retryButtonText}>Retry</Text>
              </TouchableOpacity>
            </View>
          ) : filteredOperators.length === 0 ? (
            <Text style={styles.noOperatorsText}>
              No operators found for '{selectedLetter}'
            </Text>
          ) : (
            <ScrollView 
              style={styles.operatorsList}
              contentContainerStyle={styles.operatorsListContent}
              nestedScrollEnabled={true}
            >
              {filteredOperators.map((operator, index) => (
                <View 
                  key={index}
                  style={styles.operatorItem}
                >
                  <MaterialIcons 
                    name="directions-bus" 
                    size={24} 
                    color={THEME.colors.primary} 
                  />
                  <Text style={styles.operatorText}>{operator}</Text>
                </View>
              ))}
            </ScrollView>
          )}
        </View>
      )}
    </View>
  );

  const renderPopularRoutes = () => (
    <View style={styles.sectionContainer}>
      <Text style={styles.sectionTitle}>Popular Routes</Text>
      {isLoadingRoutes ? (
        <View style={styles.routesLoadingContainer}>
          <ActivityIndicator size="small" color={THEME.colors.primary} />
        </View>
      ) : routesError ? (
        <View style={styles.routesErrorContainer}>
          <Text style={styles.routesErrorText}>{routesError}</Text>
          <TouchableOpacity onPress={loadPopularRoutes} style={styles.retryButton}>
            <Text style={styles.retryButtonText}>Retry</Text>
          </TouchableOpacity>
        </View>
      ) : popularRoutes.length > 0 ? (
        <FlatList
          data={popularRoutes}
          keyExtractor={(item) => item.routeId}
          horizontal
          showsHorizontalScrollIndicator={false}
          contentContainerStyle={styles.routesContainer}
          renderItem={({ item }) => (
            <RouteCard
              origin={item.origin}
              destination={item.destination}
            />
          )}
        />
      ) : (
        <Text style={styles.noRoutesText}>No popular routes available</Text>
      )}
    </View>
  );

  const renderBookNowButton = () => (
    <View style={styles.bookNowContainer}>
      <TouchableOpacity style={styles.bookNowButton} onPress={handleBookNow}>
        <Text style={styles.bookNowButtonText}>Book Now</Text>
      </TouchableOpacity>
    </View>
  );

  const RewardPointsCard = ({ points }) => (
    <View style={styles.rewardPointsCard}>
      <MaterialIcons name="stars" size={24} color={THEME.colors.primary} />
      <View style={styles.pointsTextContainer}>
        <Text style={styles.pointsTitle}>Your Reward Points</Text>
        <Text style={styles.pointsValue}>{points} Points</Text>
      </View>
    </View>
  );

  const renderDealsSection = (navigation) => {
    if (isLoadingDeals) {
      return (
        <View style={styles.loadingContainer}>
          <ActivityIndicator size="large" color={THEME.colors.primary} />
        </View>
      );
    }

    if (error) {
      return (
        <View style={styles.errorContainer}>
          <Text style={styles.errorText}>{error}</Text>
          <TouchableOpacity style={styles.retryButton} onPress={loadPromotions}>
            <Text style={styles.retryButtonText}>Retry</Text>
          </TouchableOpacity>
        </View>
      );
    }

    return deals.map((deal) => (
      <DealCard
        key={deal.id}
        title={deal.title}
        description={deal.description}
        icon={deal.icon}
        details={deal.details}
        offerCode={deal.offerCode}
        navigation={navigation}
      />
    ));
  };

  return (
    <ScreenLayout navigation={navigation}>
      <Helmet>
        <title>BYGBUS - Book Bus Tickets Online in India | AC & Non-AC Bus Booking</title>
        <meta name="description" content="Book bus tickets online across India with BYGBUS. Easy booking, instant confirmation, secure payments. Choose from AC & Non-AC buses for all major routes including Mumbai, Delhi, Bangalore." />
        {/* Open Graph tags for social sharing */}
        <meta property="og:title" content="BYGBUS - Online Bus Ticket Booking in India" />
        <meta property="og:description" content="Book bus tickets online across India. Easy & secure booking with instant confirmation. Multiple payment options available." />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="BYGBUS" />
        {/* Additional meta tags for mobile */}
        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-title" content="BYGBUS" />
        {/* Keywords */}
        <meta name="keywords" content="bus booking, online bus tickets, bus tickets India, AC bus booking, Mumbai bus tickets, Delhi bus tickets, BYGBUS" />
      </Helmet>
      <StatusBar style="light" />
      <ScrollView style={styles.scrollView} >
        <View style={styles.heroContainer}>
          <Animated.View style={[styles.heroImageContainer, { opacity: fadeAnim }]}>
            <ImageBackground
              source={backgroundImages[currentImageIndex]}
              style={styles.heroImage}
            />
          </Animated.View>
          <View style={styles.heroContent}>
            <Text style={styles.heroTitle}>Discover India by Bus</Text>
            <View style={{ marginBottom: 10, elevation: 9 }}>
              <SearchContainer onSearch={handleSearch} reset={searchReset}/>
            </View>
          </View>
        </View>

        <View style={styles.content}>

{user && (
  <View style={styles.sectionContainer}>
    <RewardPointsCard points={userPoints} />
  </View>
)}
<View style={styles.sectionContainer}>
  <Text style={styles.sectionTitle}>Popular Destinations</Text>
  <View style={{ flex: 1 }}>
    <FlatList
      data={popularDestinations}
      renderItem={({ item }) => <DestinationCard name={item.name} image={item.image} />}
      keyExtractor={(item) => item.id}
      horizontal
      showsHorizontalScrollIndicator={false}
    />
  </View>
</View>

{renderPopularRoutes()}

<View style={styles.sectionContainer}>
  <Text style={styles.sectionTitle}>Deals & Offers</Text>
  {renderDealsSection(navigation)}
</View>

{renderOperatorsSection()}

<View style={styles.featuresContainer}>
  <Text style={styles.sectionTitle}>Why Choose BYGBUS?</Text>
  <View style={styles.featuresGrid}>
    <View style={styles.featureItem}>
      <Ionicons name="bus-outline" size={32} color={THEME.colors.primary} />
      <Text style={styles.featureText}>Extensive Network</Text>
    </View>
    <View style={styles.featureItem}>
      <Ionicons name="time-outline" size={32} color={THEME.colors.primary} />
      <Text style={styles.featureText}>24/7 Support</Text>
    </View>
    <View style={styles.featureItem}>
      <Ionicons name="shield-checkmark-outline" size={32} color={THEME.colors.primary} />
      <Text style={styles.featureText}>Secure Booking</Text>
    </View>
    <View style={styles.featureItem}>
      <Ionicons name="cash-outline" size={32} color={THEME.colors.primary} />
      <Text style={styles.featureText}>Best Prices</Text>
    </View>
  </View>
</View>
</View>
      </ScrollView>
    </ScreenLayout>
  );
};
const styles = StyleSheet.create({
  scrollView: {
    flex: 1,
    backgroundColor: THEME.colors.background.light,
  },
  heroContainer: {
    height: 600,
    position: 'relative',
    marginBottom: 20,
    zIndex: 1
  },
  heroImageContainer: {
    ...StyleSheet.absoluteFillObject,
  },
  heroImage: {
    width: '100%',
    height: '100%',
  },
  heroContent: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    padding: 20,
    justifyContent: 'center',
    alignItems: 'center',
  },
  heroTitle: {
    fontFamily: 'Roboto-Bold',
    fontSize: 36,
    color: THEME.colors.text.white,
    textAlign: 'center',
    marginBottom: 30,
  },
  content: {
    padding: 20,
  },
  sectionContainer: {
    marginBottom: 30,
    marginTop: 20, // Added top margin
  },
  sectionTitle: {
    fontFamily: 'Roboto-Bold',
    fontSize: 16,
    color: THEME.colors.text.primary,
    marginBottom: 15,
  },
  destinationCard: {
    width: 150,
    height: 200,
    marginRight: 15,
    borderRadius: 10,
    overflow: 'hidden',
    //elevation: 3,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.15,
    shadowRadius: 3,
  },
  destinationImage: {
    width: '100%',
    height: '100%',
    justifyContent: 'flex-end',
  },
  destinationTextContainer: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    padding: 10,
  },
  destinationText: {
    fontFamily: 'Roboto-Bold',
    fontSize: 16,
    color: THEME.colors.text.white,
  },
  dealCard: {
    backgroundColor: THEME.colors.background.white,
    borderRadius: 16,
    marginBottom: 16,
    overflow: 'hidden',
    //elevation: 4,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.15,
    shadowRadius: 8,
  },
  dealGradient: {
    padding: 16,
  },
  dealHeader: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  iconContainer: {
    width: 56,
    height: 56,
    borderRadius: 28,
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 16,
  },
  dealHeaderText: {
    flex: 1,
  },
  dealTitle: {
    fontFamily: 'Roboto-Bold',
    fontSize: 18,
    color: THEME.colors.text.white,
    marginBottom: 4,
  },
  dealDescription: {
    fontFamily: 'Roboto-Regular',
    fontSize: 14,
    color: THEME.colors.text.white,
    opacity: 0.9,
  },
  dealDetails: {
    overflow: 'hidden',
    backgroundColor: THEME.colors.background.white,
  },
  detailsContent: {
    padding: 16,
  },
  dealBenefitsTitle: {
    fontFamily: 'Roboto-Bold',
    fontSize: 16,
    color: THEME.colors.text.primary,
    marginBottom: 12,
  },
  benefitItem: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10,
  },
  checkCircle: {
    width: 24,
    height: 24,
    borderRadius: 12,
    backgroundColor: THEME.colors.success,
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 12,
  },
  benefitText: {
    fontFamily: 'Roboto-Regular',
    fontSize: 14,
    color: THEME.colors.text.secondary,
    flex: 1,
  },
  offerCodeContainer: {
    marginTop: 16,
    padding: 12,
    backgroundColor: THEME.colors.background.light,
    borderRadius: 12,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  offerCodeContent: {
    flex: 1,
  },
  offerCodeLabel: {
    fontFamily: 'Roboto-Medium',
    fontSize: 12,
    color: THEME.colors.text.secondary,
    marginBottom: 4,
  },
  offerCode: {
    fontFamily: 'Roboto-Bold',
    fontSize: 20,
    color: THEME.colors.primary,
    letterSpacing: 1,
  },
  copyButton: {
    width: 40,
    height: 40,
    borderRadius: 20,
    backgroundColor: THEME.colors.background.white,
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 12,
    //elevation: 2,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.1,
    shadowRadius: 2,
  },
  featuresContainer: {
    marginBottom: 30,
  },
  featuresGrid: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  featureItem: {
    width: '48%',
    backgroundColor: THEME.colors.background.white,
    borderRadius: 12,
    padding: 20,
    alignItems: 'center',
    marginBottom: 15,
   //elevation: 3,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 4,
  },
  featureText: {
    fontFamily: 'Roboto-Medium',
    fontSize: 14,
    color: THEME.colors.text.primary,
    marginTop: 12,
    textAlign: 'center',
  },
  loadingContainer: {
    padding: 20,
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 200,
  },
  errorContainer: {
    padding: 20,
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 200,
  },
  errorText: {
    fontFamily: 'Roboto-Regular',
    fontSize: 16,
    color: '#FF3B30',
    marginBottom: 10,
    textAlign: 'center',
  },
  retryButton: {
    backgroundColor: THEME.colors.primary,
    paddingHorizontal: 20,
    paddingVertical: 10,
    borderRadius: 8,
  },
  retryButtonText: {
    fontFamily: 'Roboto-Bold',
    fontSize: 14,
    color: THEME.colors.text.white,
  },
  rewardPointsCard: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#fff',
    padding: 16,
    borderRadius: 12,
    marginBottom: 20,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.1,
    shadowRadius: 3.84,
    //elevation: 5,
  },
  pointsTextContainer: {
    marginLeft: 12,
  },
  pointsTitle: {
    fontFamily: 'Roboto-Medium',
    fontSize: 14,
    color: THEME.colors.text.secondary,
    marginBottom: 4,
  },
  pointsValue: {
    fontFamily: 'Roboto-Bold',
    fontSize: 18,
    color: THEME.colors.primary,
  },
  actionsContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 8,
  },
  applyButton: {
    backgroundColor: THEME.colors.primary,
    paddingHorizontal: 16,
    paddingVertical: 8,
    borderRadius: 4,
  },
  applyButtonText: {
    color: THEME.colors.text.white,
    fontSize: 14,
    fontWeight: '600',
  },
  alphabetContainer: {
    marginBottom: 16,
  },
  letterButton: {
    width: 40,
    height: 40,
    borderRadius: 20,
    backgroundColor: THEME.colors.background.white,
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 8,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.1,
    shadowRadius: 2,
  },
  letterButtonActive: {
    backgroundColor: THEME.colors.primary,
  },
  letterText: {
    fontFamily: 'Roboto-Medium',
    fontSize: 16,
    color: THEME.colors.text.primary,
  },
  letterTextActive: {
    color: THEME.colors.text.white,
  },
  operatorsContainer: {
    minHeight: 100,
    paddingTop: 16,
  },
  operatorsList: {
    backgroundColor: THEME.colors.background.white,
    borderRadius: 12,
    overflow: 'hidden',
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 4,
    maxHeight: 300,
  },
  operatorsListContent: {
    flexGrow: 1,
  },
  operatorItem: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 16,
    borderBottomWidth: 1,
    borderBottomColor: THEME.colors.border,
  },
  operatorText: {
    flex: 1,
    marginLeft: 12,
    fontFamily: 'Roboto-Regular',
    fontSize: 16,
    color: THEME.colors.text.primary,
  },
  routeCard: {
    backgroundColor: THEME.colors.background.white,
    borderRadius: 8,
    padding: 12,
    marginRight: 12,
    width: 200,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.1,
    shadowRadius: 2,
  },
  routeContent: {
    flex: 1,
    flexDirection: 'row'
  },
  routeOrigin: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 4,
  },
  routeDestination: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  routeArrow: {
    marginVertical: 2,
    marginLeft: 7,
  },
  routeText: {
    fontFamily: 'Roboto-Regular',
    fontSize: 12,
    color: THEME.colors.text.primary,
    marginLeft: 8,
  },
  routesContainer: {
    paddingVertical: 4,
    paddingRight: 20,
  },
  routesLoadingContainer: {
    height: 80,
    justifyContent: 'center',
    alignItems: 'center',
  },
  routesErrorContainer: {
    padding: 12,
    alignItems: 'center',
  },
  routesErrorText: {
    fontFamily: 'Roboto-Regular',
    fontSize: 12,
    color: '#FF3B30',
    marginBottom: 8,
    textAlign: 'center',
  },
  noRoutesText: {
    fontFamily: 'Roboto-Regular',
    fontSize: 12,
    color: THEME.colors.text.secondary,
    textAlign: 'center',
    padding: 12,
  },
});

export default HomeScreen;