import React, { useState } from 'react';
import { View, Text, TouchableOpacity, StyleSheet, FlatList, ScrollView } from 'react-native';
import Hoverable from 'react-native-hoverable';
import Seat from './Seat';
import Tooltip from './Tooltip';
import { MaterialCommunityIcons } from '@expo/vector-icons';

const SeatArrangement = ({ levels, onSeatClick, selectedSeats, onCancel, onBook }) => {
    const [hoveredSeat, setHoveredSeat] = useState(null);

    const handleBook = () => {
        onBook();
    };

    const getSeatStatusStyle = (seat) => {
        if (selectedSeats && selectedSeats.some((selectedSeat) => selectedSeat.seatNo === seat.seatNo)) {
            return styles.selectedSeatContainer;
        }

        if (seat && (seat.seatType === 'FREESPACE' || seat.seatType === 'RESTROOM')) {
            return styles.emptySeat;
        }

        switch (seat.seatStatus) {
            case 'AVAILABLE_ALL':
                return styles.availableSeatContainer;
            case 'AVAILABLE_MALE':
                return styles.availableMaleSeatContainer;
            case 'AVAILABLE_FEMALE':
                return styles.availableFemaleSeatContainer;
            case 'BOOKED_MALE':
                return styles.bookedMaleSeatContainer;
            case 'BOOKED_FEMALE':
                return styles.bookedFeMaleSeatContainer;
            default:
                return styles.unavailableSeatContainer;
        }
    };

    const getSeatStyle = (seat) => {
        switch (seat.seatType) {
            case 'REGULAR':
            case 'SINGLE_REGULAR':
            case 'PUSHBACK':
                return styles.seatContainer;
            case 'SEMI_SLEEPER':
                return styles.semiSleeperContainer;
            case 'SLEEPER':
            case 'SINGLE_LOWER_SLEEPER':
            case 'SINGLE_UPPER_SLEEPER':
            case 'LOWER_SLEEPER':
            case 'UPPER_SLEEPER':
                return styles.sleeperContainer;
            case 'FREESPACE':
            case 'RESTROOM':
                return styles.freespaceContainer;
            default:
                return styles.seatContainer;
        }
    };

    const getSeatType = (seat) => {
        switch (seat.seatType) {
            case 'REGULAR':
                return 'Regular Seat';
            case 'SINGLE_REGULAR':
                return 'Single Regular Seat';
            case 'PUSHBACK':
                return 'Pushback';
            case 'SEMI_SLEEPER':
                return 'Semi Sleeper';
            case 'SLEEPER':
                return 'Sleeper';
            case 'SINGLE_LOWER_SLEEPER':
                return 'Single Lower Sleeper';
            case 'SINGLE_UPPER_SLEEPER':
                return 'Single Upper Sleeper';
            case 'LOWER_SLEEPER':
                return 'Lower Sleeper';
            case 'UPPER_SLEEPER':
                return 'Upper Sleeper';
            case 'FREESPACE':
            case 'RESTROOM':
                return '';
            default:
                return 'Seat';
        }
    };

    const calculateEstimatedTotalCost = () => {
        let totalBasePrice = 0;
        let totalTaxes = 0;
        let totalCost = 0;
        let totalDiscount = 0;
        selectedSeats.forEach((seat) => {
            totalBasePrice += seat.seatRate.baseFare;
            totalDiscount += seat.seatRate.baseFare - seat.seatRate.discountedBaseFare;
            totalTaxes += seat.seatRate.totalTax;
            totalCost += seat.seatRate.totalCost;
        });
        return { totalBasePrice, totalDiscount, totalTaxes, totalCost };
    };

    const getSmallestSeatStyle = (level) => {
        let minWidth = Infinity;
        let minHeight = Infinity;

        level.seats.forEach((seat) => {
            const seatStyle = getSeatStyle(seat);
            minWidth = Math.min(minWidth, StyleSheet.flatten(seatStyle).width);
            minHeight = Math.min(minHeight, StyleSheet.flatten(seatStyle).height);
        });

        return {
            width: minWidth,
            height: minHeight
        };
    };

    const checkSeatRestrictions = () => {
        return selectedSeats.some(seat => seat.seatStatus === 'AVAILABLE_MALE' || seat.seatStatus === 'AVAILABLE_FEMALE');
    };

    const renderSeats = (level) => {
        const rows = level.maxRows;
        const columns = level.maxColumns;

        const seatLayout = Array.from({ length: rows }, () =>
            Array.from({ length: columns }, () => null)
        );

        level.seats.forEach((seat) => {
            const startRow = seat.row - 1;
            const endRow = startRow + (seat.rowSpan || 1);
            const startCol = seat.col - 1;
            const endCol = startCol + (seat.colSpan || 1);

            for (let row = startRow; row < Math.min(endRow, rows); row++) {
                for (let col = startCol; col < Math.min(endCol, columns); col++) {
                    if (!seatLayout[row][col]) {
                        seatLayout[row][col] = seat;
                    }
                }
            }
        });

        const smallestSeatStyle = getSmallestSeatStyle(level);
        const renderedSeats = new Set();

        return seatLayout.map((row, rowIndex) => {
            const hasSeat = row.some((seat) => seat !== null);

            return (
                <View key={rowIndex} style={styles.rowContainer}>
                    {row.map((seat, colIndex) => {
                        if (seat && !renderedSeats.has(seat.seatNo)) {
                            renderedSeats.add(seat.seatNo);
                            return (
                                <Hoverable
                                    key={colIndex}
                                    onHoverIn={() => setHoveredSeat(seat)}
                                    onHoverOut={() => setHoveredSeat(null)}
                                >
                                    <View
                                        style={[
                                            getSeatStyle(seat),
                                            getSeatStatusStyle(seat),
                                            { marginBottom: 5 }
                                        ]}
                                    >
                                        {hoveredSeat === seat && (
                                            <Tooltip
                                                seat={seat}
                                            />
                                        )}
                                        <Seat
                                            seat={seat}
                                            onSeatClick={onSeatClick}
                                            isSelected={selectedSeats && selectedSeats.some((selectedSeat) => selectedSeat.seatNo === seat.seatNo)}
                                        />
                                    </View>
                                </Hoverable>
                            );
                        } else if (hasSeat) {
                            // Check if there are any more seats left to render in the current row
                            const remainingSeatsInRow = row.slice(colIndex + 1).some((remainingSeat) => remainingSeat !== null);
                            return remainingSeatsInRow ? (
                                <View key={colIndex} style={[styles.emptySeat, smallestSeatStyle]} />
                            ) : null;
                        }
                        return null;
                    })}
                </View>
            );
        });
    };


    const totalCost = calculateEstimatedTotalCost();
    const restrictionsInPlace = checkSeatRestrictions();

    return (
        <ScrollView horizontal={true} contentContainerStyle={styles.container}>
            <View style={styles.seatCostContainer}>
                <View style={styles.seatArrangementContainer}>
                    <View style={styles.levelsContainer}>
                        {levels.map((level, index) => (
                            <View key={index} style={styles.levelContainer}>
                                <View style={styles.levelHeaderContainer}>
                                    <Text style={styles.levelHeaderText}>
                                        {level.upperLowerBith === 'Lower' ? (
                                            <>
                                                <MaterialCommunityIcons name="steering" size={16} color="black" /> {/* Adjust icon name, size, and color as needed */}
                                                {' Lower'}
                                            </>
                                        ) : (
                                            'Upper'
                                        )}
                                    </Text>
                                </View>
                                <View style={styles.level}>
                                    {renderSeats(level)}
                                </View>
                            </View>
                        ))}
                    </View>
                    <View style={styles.seatIdentifierContainer}>
                        <View style={styles.identifierItem}>
                            <View style={styles.unavailableSeat} />
                            <Text style={styles.seatIdentifierText}>UnAvailable</Text>
                        </View>
                        <View style={styles.identifierItem}>
                            <View style={styles.availableSeat} />
                            <Text style={styles.seatIdentifierText}>Available</Text>
                        </View>
                        <View style={styles.identifierItem}>
                            <View style={styles.femaleOnlySeat} />
                            <Text style={styles.seatIdentifierText}>Available For Female</Text>
                        </View>
                        <View style={styles.identifierItem}>
                            <View style={styles.maleOnlySeat} />
                            <Text style={styles.seatIdentifierText}>Available For Male</Text>
                        </View>
                        <View style={styles.identifierItem}>
                            <View style={styles.selectedSeat} />
                            <Text style={styles.seatIdentifierText}>Selected</Text>
                        </View>
                    </View>
                </View>

                {selectedSeats.length > 0 && restrictionsInPlace && (
                    <View style={styles.importantInfoContainer}>
                        <Text style={styles.importantInfoHeader}>Note !!! Gender Restrictions In Place</Text>
                        {selectedSeats.filter(seat => seat.seatStatus === 'AVAILABLE_MALE' || seat.seatStatus === 'AVAILABLE_FEMALE').map((seat, index) => (
                            <Text key={index} style={styles.seatInfo}>
                                Seat {seat.seatNo} is available only for {seat.seatStatus === 'AVAILABLE_MALE' ? 'male' : 'female'}
                            </Text>
                        ))}
                    </View>
                )}

                {selectedSeats.length > 0 && (
                    <View style={styles.costContainer}>
                        <Text style={styles.costHeaderText}>Selected Seats</Text>
                        <FlatList
                            data={selectedSeats}
                            keyExtractor={(item) => item.seatNo}
                            renderItem={({ item }) => (
                                <View style={styles.costItem}>
                                    <Text style={styles.costSeatNo}>Seat {item.seatNo} ({getSeatType(item)})</Text>
                                    <Text style={styles.costText}>Base Fare: ₹ {item.seatRate.baseFare.toFixed(2)}</Text>
                                    <Text style={styles.costText}>Taxes: ₹ {item.seatRate.totalTax.toFixed(2)}</Text>
                                    <Text style={styles.costText}>Total: ₹ {item.seatRate.totalCost.toFixed(2)}</Text>
                                </View>
                            )}
                        />
                        <View style={styles.totalCost}>
                            <Text style={styles.costText}>Total Base Fare: ₹ {totalCost.totalBasePrice.toFixed(2)}</Text>
                            <Text style={styles.costText}>Total Taxes: ₹ {totalCost.totalTaxes.toFixed(2)}</Text>
                            <Text style={styles.costText}>Total Cost: ₹ {totalCost.totalCost.toFixed(2)}</Text>
                        </View>
                    </View>
                )}

            </View>

            <View style={styles.bookContainer}>
                <TouchableOpacity onPress={() => onCancel()} style={styles.bookButton}>
                    <Text style={styles.bookButtonText}>Close</Text>
                </TouchableOpacity>

                {selectedSeats.length > 0 && (
                    <TouchableOpacity onPress={() => handleBook()} style={styles.bookButton}>
                        <Text style={styles.bookButtonText}>Book</Text>
                    </TouchableOpacity>
                )}
            </View>
        </ScrollView>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    seatCostContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center',
        marginHorizontal: 'auto'
    },
    seatArrangementContainer: {
        backgroundColor: 'white',
        flexDirection: 'column',
    },
    levelsContainer: {
        flexDirection: 'column',
        justifyContent: 'space-around',
    },
    levelContainer: {
        margin: 5,
        padding: 5,
    },
    level: {
        backgroundColor: 'white',
        borderWidth: 1,
        borderColor: 'black',
    },
    rowContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-around',
        margin: 5,
    },
    seatContainer: {
        alignItems: 'center',
        justifyContent: 'center',
        width: 30,
        height: 20,
        borderWidth: 1,
        borderColor: 'black',
        marginHorizontal: 1,
    },
    semiSleeperContainer: {
        alignItems: 'center',
        justifyContent: 'center',
        width: 30,
        height: 20,
        borderWidth: 1,
        borderColor: 'black',
        marginHorizontal: 1,
    },
    sleeperContainer: {
        alignItems: 'center',
        justifyContent: 'center',
        width: 59,
        height: 20,
        borderWidth: 1,
        borderColor: 'black',
        marginHorizontal: 1,
    },
    emptySeat: {
        marginHorizontal: 1,
    },
    bookContainer: {
        alignItems: 'center',
        marginVertical: 10,
        flexDirection: 'row',
        justifyContent: 'space-around',
    },
    bookButton: {
        backgroundColor: '#5EBC67',
        paddingVertical: 10,
        paddingHorizontal: 20,
        borderRadius: 5,
        marginHorizontal: 5,
    },
    bookButtonText: {
        color: 'white',
        fontWeight: 'bold',
        textAlign: 'center',
        //fontFamily: 'Aldrich',
    },
    seatIdentifierContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-around',
    },
    identifierItem: {
        alignItems: 'center',
    },
    seatIdentifierText: {
        fontSize: 8,
    },
    availableSeat: {
        backgroundColor: 'white',
        borderWidth: 1,
        borderColor: 'black',
        maxHeight: 10,
        minHeight: 10,
        maxWidth: 10,
        minWidth: 10,
        marginTop: 10,
    },
    unavailableSeat: {
        backgroundColor: 'lightgray',
        borderWidth: 1,
        borderColor: 'black',
        maxHeight: 10,
        minHeight: 10,
        maxWidth: 10,
        minWidth: 10,
        marginTop: 10,
    },
    femaleOnlySeat: {
        backgroundColor: 'white',
        borderWidth: 2,
        borderColor: '#FFC0CB',
        maxHeight: 10,
        minHeight: 10,
        maxWidth: 10,
        minWidth: 10,
        marginTop: 10,
    },
    maleOnlySeat: {
        backgroundColor: 'white',
        borderWidth: 2,
        borderColor: '#6495ED',
        maxHeight: 10,
        minHeight: 10,
        maxWidth: 10,
        minWidth: 10,
        marginTop: 10,
    },
    selectedSeat: {
        backgroundColor: '#2E8B57',
        borderWidth: 1,
        borderColor: 'black',
        maxHeight: 10,
        minHeight: 10,
        maxWidth: 10,
        minWidth: 10,
        marginTop: 10,
    },
    modalContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    closeButton: {
        marginTop: 20,
        padding: 10,
        backgroundColor: 'white',
    },
    levelHeaderText: {
        //fontFamily: 'Aldrich'
    },
    levelHeaderContainer: {
        flex: 1,
        flexDirection: 'row',
    },
    selectedSeatContainer: {
        backgroundColor: '#2E8B57',
    },
    availableMaleSeatContainer: {
        borderColor: '#6495ED',
        borderWidth: 3,
    },
    availableFemaleSeatContainer: {
        borderColor: '#FFC0CB',
        borderWidth: 3,
    },
    unavailableSeatContainer: {
        backgroundColor: 'lightgray',
    },
    bookedMaleSeatContainer: {
        backgroundColor: '#6495ED',
        borderColor: '#6495ED',
    },
    bookedFeMaleSeatContainer: {
        backgroundColor: '#FFC0CB',
        borderColor: '#FFC0CB',
    },
    costContainer: {
        marginTop: 10,
        padding: 10,
        backgroundColor: '#BC675E',
        borderWidth: 1,
        borderColor: 'gray',
        borderRadius: 5,
    },
    costItem: {
        marginVertical: 5,
    },
    costText: {
        fontSize: 14,
        //fontFamily: 'Aldrich',
        color: 'white'
    },
    totalCost: {
        marginTop: 10,
        borderTopWidth: 1,
        borderTopColor: 'white',
        paddingTop: 10,
    },
    costSeatNo: {
        fontWeight: 'bold',
        //fontFamily: 'Aldrich', 
        color: 'white'
    },
    costHeaderText: {
        fontWeight: 'bold',
        //fontFamily: 'Aldrich', 
        color: 'white'
    },
    button: {
        backgroundColor: '#ff6347',
        paddingVertical: 10,
        paddingHorizontal: 20,
        borderRadius: 5,
    },
    buttonText: {
        color: '#fff',
        fontSize: 16,
        fontWeight: 'bold',
    },
    importantInfoContainer: {
        padding: 10,
        backgroundColor: 'white',
        borderRadius: 5,
        margin: 10,
    },
    importantInfoHeader: {
        fontSize: 16,
        fontWeight: 'bold',
        color: '#cc0000',
        marginBottom: 5,
    },
    seatInfo: {
        fontSize: 14,
        color: '#cc0000',
    },

});

export default SeatArrangement;
