import React, { useState, useEffect } from 'react';
import { View, Text, TextInput, Button, Alert, StyleSheet, ScrollView, CheckBox , TouchableOpacity} from 'react-native';
import axios from 'axios'; // Import axios if not already imported
import ScreenLayout from './ScreenLayout';
import { useRoute } from '@react-navigation/native';

const FlightSearch= ({navigation}) =>{

    return (
        <ScreenLayout navigation={navigation}>
            <Text>Coming soon... Stay Tuned for updates</Text>
        </ScreenLayout>
    )
}

export default FlightSearch;