import React from 'react';
import { TouchableOpacity, Text, StyleSheet, View } from 'react-native';
import { MaterialCommunityIcons } from '@expo/vector-icons';

const Seat = React.memo(({ seat, onSeatClick, isSelected }) => {
    if (seat.seatType === 'FREESPACE' || seat.seatType === 'RESTROOM') {
        return (
            <View style={[
                styles.freeSpace,
                seat.orientation === 1 && {
                    width: styles.freeSpace.height,
                    height: styles.freeSpace.width
                }
            ]}>
                {seat.seatType === 'RESTROOM' && (
                    <Text style={styles.restroomSymbol}>{'\u{1F6BB}'}</Text>
                )}
            </View>
        );
    }

    const isSleeper = (seat) => {
        switch (seat.seatType) {        
            case 'SLEEPER': return true;
            case 'SINGLE_LOWER_SLEEPER': return true;
            case 'SINGLE_UPPER_SLEEPER': return true;
            case 'LOWER_SLEEPER': return true;
            case 'UPPER_SLEEPER': return true;
            default: return false;
        }
    };

    const isAvailable = seat.seatStatus.includes('AVAILABLE');
    const isOccupiedByMale = seat.seatStatus === 'BOOKED_MALE';
    const isOccupiedByFemale = seat.seatStatus === 'BOOKED_FEMALE';
    const isUnavailable = !isAvailable && !isOccupiedByMale && !isOccupiedByFemale;
    
    // Get base seat dimensions based on type and orientation
    const getSeatDimensions = () => {
        const isHorizontal = seat.orientation === 1;
        const isSleeperSeat = isSleeper(seat);
        
        if (isHorizontal) {
            return isSleeperSeat ? {
                width: 84,
                height: 40
            } : {
                width: 40,
                height: 40
            };
        } else {
            return isSleeperSeat ? {
                width: 40,
                height: 84
            } : {
                width: 40,
                height: 40
            };
        }
    };

    const dimensions = getSeatDimensions();

    return (
        <TouchableOpacity
            onPress={() => isAvailable && onSeatClick(seat)}
            style={[
                styles.seat,
                { width: dimensions.width, height: dimensions.height },
                isSleeper(seat) ? styles.sleeperSeat : styles.regularSeat,
                isUnavailable && styles.unavailableSeat,
                isOccupiedByMale && styles.occupiedMaleSeat,
                isOccupiedByFemale && styles.occupiedFemaleSeat,
                isSelected && styles.selectedSeat,
                seat.seatStatus === 'AVAILABLE_MALE' && styles.maleSeat,
                seat.seatStatus === 'AVAILABLE_FEMALE' && styles.femaleSeat,
            ]}
            disabled={!isAvailable}
        >
            {seat.seatRate?.discountedBaseFare && seat.seatRate.discountedBaseFare < seat.seatRate.baseFare && (
                <View style={styles.offerBadge}>
                    <Text style={styles.offerText}>OFF</Text>
                </View>
            )}
            
            {(isOccupiedByMale || isOccupiedByFemale) && (
                <View style={styles.occupiedIndicator}>
                    <MaterialCommunityIcons 
                        name={isOccupiedByMale ? "gender-male" : "gender-female"} 
                        size={14} 
                        color="#fff" 
                    />
                </View>
            )}

            <Text style={[
                styles.seatNumber,
                ((!isAvailable && !isOccupiedByMale && !isOccupiedByFemale) || isSelected) && styles.seatNumberLight,
                (isOccupiedByMale || isOccupiedByFemale) && styles.seatNumberOccupied
            ]}>
                {seat.seatNo}
            </Text>
            
            {isAvailable && (
                <Text style={[
                    styles.price,
                ]}>
                    ₹{Math.floor(seat.seatRate?.discountedBaseFare || seat.seatRate?.baseFare)}
                </Text>
            )}
        </TouchableOpacity>
    );
});

const styles = StyleSheet.create({
    seat: {
        margin: 2,
        borderRadius: 4,
        alignItems: 'center',
        justifyContent: 'flex-start',
        position: 'relative',
        elevation: 2,
        shadowColor: "#000",
        shadowOffset: { width: 0, height: 1 },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,
        alignSelf: 'flex-start',
        flexGrow: 0,
        flexShrink: 0,
        width: 40
    },
    regularSeat: {
        backgroundColor: '#fff',
        borderWidth: 1,
        borderColor: '#666',
    },
    sleeperSeat: {
        backgroundColor: '#fff',
        borderWidth: 1,
        borderColor: '#666',
    },
    freeSpace: {
        width: 40,
        height: 40,
        margin: 2,
        alignItems: 'center',
        justifyContent: 'flex-start',
        alignSelf: 'flex-start',
        flexGrow: 0,
        flexShrink: 0
    },
    unavailableSeat: {
        backgroundColor: '#e0e0e0',
        borderColor: '#bdbdbd',
    },
    occupiedMaleSeat: {
        backgroundColor: '#90CAF9',
        borderColor: '#2196F3',
        borderWidth: 2,
    },
    occupiedFemaleSeat: {
        backgroundColor: '#F48FB1',
        borderColor: '#E91E63',
        borderWidth: 2,
    },
    selectedSeat: {
        backgroundColor: '#4CAF50',
        borderColor: '#388E3C',
    },
    maleSeat: {
        borderColor: '#2196F3',
        borderWidth: 2,
    },
    femaleSeat: {
        borderColor: '#E91E63',
        borderWidth: 2,
    },
    seatNumber: {
        fontSize: 12,
        color: '#333',
        fontWeight: '500',
        marginTop: 4
    },
    seatNumberLight: {
        color: '#fff',
    },
    seatNumberOccupied: {
        color: '#fff',
        fontSize: 11,
    },
    price: {
        fontSize: 10,
        color: '#666',
        marginTop: 2,
        position: 'absolute',
        bottom: 4
    },
    offerBadge: {
        position: 'absolute',
        top: -5,
        right: -5,
        backgroundColor: '#FF5722',
        borderRadius: 10,
        padding: 2,
        minWidth: 20,
        alignItems: 'center',
        zIndex: 1,
    },
    offerText: {
        color: '#fff',
        fontSize: 8,
        fontWeight: 'bold',
    },
    occupiedIndicator: {
        position: 'absolute',
        top: 2,
        right: 2,
        backgroundColor: 'transparent',
    },
    restroomSymbol: {
        fontSize: 16,
        color: '#666',
    }
});

export default Seat;