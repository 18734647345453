import React, { useState, useEffect, useRef } from 'react';
import { View, Text, TouchableOpacity, StyleSheet, ScrollView } from 'react-native';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { addDays, format } from 'date-fns';

const BusTripSummary = ({ bookSummary, onDateChange }) => {
    const [dates, setDates] = useState([]);
    const [selectedDate, setSelectedDate] = useState(bookSummary.travelDate);
    const scrollViewRef = useRef(null);

    useEffect(() => {
        const generateDates = () => {
            const dateArray = [];
            const today = new Date();
            
            for (let i = 0; i < 30; i++) {
                dateArray.push(addDays(today, i));
            }
            setDates(dateArray);
        };

        generateDates();
    }, []);

    const handleDateSelect = (date) => {
        setSelectedDate(date);
        onDateChange(date);
    };

    return (
        <View style={styles.container}>
            <View style={styles.summaryContainer}>
                <View style={styles.locationContainer}>
                    <Text style={styles.locationText}>{bookSummary.origin}</Text>
                </View>
                
                <MaterialCommunityIcons name="arrow-right" size={20} color="#5EBC67" />
                
                <View style={styles.dateScrollWrapper}>
                    <ScrollView 
                        ref={scrollViewRef}
                        horizontal
                        showsHorizontalScrollIndicator={false}
                        style={styles.dateScrollView}
                        contentContainerStyle={styles.dateScrollContent}
                    >
                        {dates.map((date, index) => (
                            <TouchableOpacity 
                                key={index}
                                style={[
                                    styles.dateButton,
                                    format(date, 'yyyy-MM-dd') === format(selectedDate, 'yyyy-MM-dd') && styles.selectedDateButton
                                ]}
                                onPress={() => handleDateSelect(date)}
                            >
                                <Text style={[styles.monthText, format(date, 'yyyy-MM-dd') === format(selectedDate, 'yyyy-MM-dd') && styles.selectedText]}>
                                    {format(date, 'MMM')}
                                </Text>
                                <Text style={[styles.dayText, format(date, 'yyyy-MM-dd') === format(selectedDate, 'yyyy-MM-dd') && styles.selectedText]}>
                                    {format(date, 'd')}
                                </Text>
                            </TouchableOpacity>
                        ))}
                    </ScrollView>
                </View>
                
                <MaterialCommunityIcons name="arrow-right" size={20} color="#5EBC67" />
                
                <View style={styles.locationContainer}>
                    <Text style={styles.locationText}>{bookSummary.destination}</Text>
                </View>
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        padding: 8,
        width: '95%',
        marginHorizontal: '2.5%',
    },
    summaryContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: '#FFFFFF',
        borderRadius: 8,
        padding: 12,
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 1 },
        shadowOpacity: 0.1,
        shadowRadius: 2,
        elevation: 2,
    },
    locationContainer: {
        flex: 1.5,
    },
    locationText: {
        fontSize: 14,
        color: '#111827',
        fontWeight: '600',
        textAlign: 'center',
    },
    dateScrollWrapper: {
        flex: 3,
        marginHorizontal: 8,
    },
    dateScrollView: {
        flexGrow: 0,
    },
    dateScrollContent: {
        alignItems: 'center',
    },
    dateButton: {
        width: 45,
        height: 45,
        marginHorizontal: 3,
        borderRadius: 6,
        backgroundColor: '#F3F4F6',
        alignItems: 'center',
        justifyContent: 'center',
        borderWidth: 1,
        borderColor: '#E5E7EB',
    },
    selectedDateButton: {
        backgroundColor: '#5EBC67',
        borderColor: '#5EBC67',
    },
    monthText: {
        fontSize: 10,
        color: '#6B7280',
        fontWeight: '500',
    },
    dayText: {
        fontSize: 14,
        color: '#111827',
        fontWeight: '600',
    },
    selectedText: {
        color: '#FFFFFF',
    },
});

export default BusTripSummary;