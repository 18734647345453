import React, { useState, useEffect } from 'react';
import { View, Text, TextInput, Button, Alert, StyleSheet, ScrollView, TouchableOpacity, ImageBackground } from 'react-native';
import axios from 'axios'; // Import axios if not already imported
import ScreenLayout from './ScreenLayout';
import { useRoute } from '@react-navigation/native';
import Constants from 'expo-constants';
import * as Print from 'expo-print';

const API_URL = Constants.expoConfig.extra.apiUrl;
const API_TOKEN = Constants.expoConfig.extra.apiToken;

const ViewReservation = ({ navigation }) => {
    const route = useRoute();
    const { pnr: initialPnr } = route.params || {};
    const [pnr, setPnr] = useState(initialPnr || '');
    const [reservationDetails, setReservationDetails] = useState(null);

    useEffect(() => {
        if (initialPnr) {
            fetchReservationDetails();
        }
    }, [initialPnr]);

    const formatTime = (timeArray) => {
        const arrivalTime = new Date(timeArray[0], timeArray[1] - 1, timeArray[2], timeArray[3], timeArray[4]);
        return arrivalTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    };

    const convertTo12HourFormat = (dateTime) => {
        const date = new Date(dateTime.replace(' ', 'T'));

        // Extract date components
        const day = date.getDate();
        const month = date.getMonth() + 1; // Months are zero-based
        const year = date.getFullYear();

        // Extract time components
        let hours = date.getHours();
        const minutes = date.getMinutes();
        const ampm = hours >= 12 ? 'PM' : 'AM';

        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        const minutesStr = minutes < 10 ? '0' + minutes : minutes;

        const formattedDate = `${day}/${month}/${year}`;
        const formattedTime = `${hours}:${minutesStr} ${ampm}`;

        return `${formattedDate} ${formattedTime}`;
    };

    const fetchReservationDetails = async () => {
        try {
            const response = await axios.get(`${API_URL}/bus-reservation/${pnr}`, {
                headers: {
                    'x-api-token': API_TOKEN
                }
            });
            const payload = response.data;
            setReservationDetails(payload);
        } catch (error) {
            console.error('Error fetching reservation:', error);
            Alert.alert('Error', 'Failed to fetch reservation details. Please try again.');
        }
    };

    const handlePrint = async () => {
        try {
            const response = await axios.get(API_URL + '/bus-reservation/print/' + reservationDetails.confirmationCode , {
                headers: {
                    'x-api-token': API_TOKEN
                }
            });
            await Print.printAsync({
                html: response.data
            });
        } catch (error) {
            console.error(error);
        }
    };
    


    const manageReservation = () => {
        navigation.navigate('ManageReservation', { pnr });
    }

    return (
        <ScreenLayout navigation={navigation}>
            <ScrollView contentContainerStyle={styles.container}>
                <Text style={styles.title}>View Reservation</Text>
                <TextInput
                    style={styles.input}
                    placeholder="Enter PNR"
                    value={pnr}
                    onChangeText={setPnr}
                />
                <View style={styles.buttonContainer}>
                    <TouchableOpacity onPress={fetchReservationDetails} style={styles.retrieveButton} >
                        <Text style={styles.buttonText}>Retrieve Reservation</Text>
                    </TouchableOpacity>
                </View>
                {reservationDetails && (
                    <View style={styles.detailsContainer}>
                        <Text style={styles.detailsTitle}>Reservation Details:</Text>
                        <Text style={styles.detailsText}>BYGBUS PNR: {reservationDetails.transactionPNR}</Text>
                        <Text style={styles.detailsText}>Operator PNR: {reservationDetails.operatorPNR}</Text>
                        <Text style={styles.detailsText}>Transaction Confirmation: {reservationDetails.transactionId}</Text>
                        {reservationDetails.status === "BOOKED" && (
                            <Text style={[{ color: 'green', fontWeight: 'bold' }, styles.detailsText]}>Confirmed</Text>
                        )}

                        {reservationDetails.status === "CANCELLED" && (
                            <Text style={[{ color: 'red', fontWeight: 'bold' }, styles.detailsText]}>Cancelled</Text>
                        )}

                        {reservationDetails.status != "CANCELLED" && reservationDetails.status != "BOOKED" && (
                           <Text style={styles.detailsText}>Status: {reservationDetails.status}</Text>
                        )}

                        <View style={styles.section}>
                            <Text style={styles.sectionHeader}>Travel/Operator</Text>
                            <Text style={styles.detailText}>Operator: {reservationDetails.busAvailability.operator} ({reservationDetails.busAvailability.bus.name})</Text>
                            <Text style={styles.detailsText}>Service Number : {reservationDetails.busAvailability.serviceIdentifier}</Text>
                            <Text style={styles.detailText}>From City: {reservationDetails.origin}</Text>
                            <Text style={styles.detailText}>To City: {reservationDetails.destination}</Text>
                            <Text style={styles.detailText}>On: {reservationDetails.travelDate}</Text>
                            <Text style={styles.detailText}>Departure: {formatTime(reservationDetails.busAvailability.departureTime)}</Text>
                            <Text style={styles.detailText}>Arrival: {formatTime(reservationDetails.busAvailability.arrivalTime)}</Text>
                        </View>

                        <View style={styles.section}>
                            <Text style={styles.sectionHeader}>Contact Details</Text>
                            <Text style={styles.detailText}>Email: {reservationDetails.contactEmail}</Text>
                            <Text style={styles.detailText}>Phone: {reservationDetails.contactNumber}</Text>
                        </View>

                        <View style={styles.section}>
                            <Text style={styles.sectionHeader}>Passenger Details</Text>
                            <View style={styles.passengersContainer}>
                                {reservationDetails.passengersInfo.map((passenger, index) => (
                                    <View key={index} style={styles.passengerContainer}>
                                        <Text style={styles.passengerHeader}>Passenger {index + 1}</Text>
                                        <Text style={styles.detailText}>Seat: {passenger.seat.seatNo}</Text>
                                        <Text style={styles.detailText}>First Name: {passenger.firstName}</Text>
                                        <Text style={styles.detailText}>Last Name: {passenger.lastName}</Text>
                                        <Text style={styles.detailText}>Age: {passenger.age}</Text>
                                        <Text style={styles.detailText}>Gender: {passenger.gender}</Text>
                                        {passenger.seat.seatStatus === "BOOKED" && (
                                            <Text style={{ color: 'green', fontWeight: 'bold' }}>Booked</Text>
                                        )}

                                        {passenger.seat.seatStatus === "CANCELLED" && (
                                            <Text style={{ color: 'red', fontWeight: 'bold' }}>Cancelled</Text>
                                        )}
                                    </View>
                                ))}
                            </View>
                        </View>

                        <View style={styles.section}>
                            <Text style={styles.sectionHeader}>Boarding/DropOff</Text>
                            <View style={styles.rowContainer}>

                                <View style={styles.pointContainer}>
                                    <Text style={styles.subHeader}>Boarding Point</Text>
                                    <Text style={styles.detailText}>Boarding At: {reservationDetails.boardingPoint.pointName}</Text>
                                    <Text style={styles.detailText}>Landmark: {reservationDetails.boardingPoint.landMark}</Text>
                                    <Text style={styles.detailText}>Contact : {reservationDetails.boardingPoint.contactNumber}</Text>
                                    <Text style={styles.detailText}>
                                        Arrival Time: {new Date(
                                            reservationDetails.boardingPoint.arrivalTime[0],
                                            reservationDetails.boardingPoint.arrivalTime[1] - 1,
                                            reservationDetails.boardingPoint.arrivalTime[2],
                                            reservationDetails.boardingPoint.arrivalTime[3],
                                            reservationDetails.boardingPoint.arrivalTime[4]
                                        ).toLocaleTimeString([], {
                                            hour: '2-digit',
                                            minute: '2-digit',
                                        })}
                                    </Text>
                                </View>

                                <View style={styles.pointContainer}>
                                    <Text style={styles.subHeader}>Drop-Off Point</Text>
                                    <Text style={styles.detailText}>Drop-Off At: {reservationDetails.droppingPoint.pointName}</Text>
                                    <Text style={styles.detailText}>Landmark: {reservationDetails.droppingPoint.landMark}</Text>
                                    <Text style={styles.detailText}>Contact : {reservationDetails.droppingPoint.contactNumber}</Text>
                                    <Text style={styles.detailText}>
                                        Arrival Time: {new Date(
                                            reservationDetails.droppingPoint.arrivalTime[0],
                                            reservationDetails.droppingPoint.arrivalTime[1] - 1,
                                            reservationDetails.droppingPoint.arrivalTime[2],
                                            reservationDetails.droppingPoint.arrivalTime[3],
                                            reservationDetails.droppingPoint.arrivalTime[4]
                                        ).toLocaleTimeString([], {
                                            hour: '2-digit',
                                            minute: '2-digit',
                                        })}
                                    </Text>
                                </View>
                            </View>
                        </View>

                        <View style={styles.section}>
                            <Text style={styles.sectionHeader}>Cancellation Policy</Text>
                            {reservationDetails.busAvailability.cancellationPolicy && reservationDetails.busAvailability.cancellationPolicy.cancellationPenalty.map((cancelPenalty, index) => (
                                <View key={index} style={styles.penaltyContainer}>
                                    <Text style={styles.detailText}>
                                        Between {cancelPenalty.from ? convertTo12HourFormat(cancelPenalty.from) : 'Departure'} and {cancelPenalty.to ? convertTo12HourFormat(cancelPenalty.to) : 'Booked Date/time'}:
                                        {cancelPenalty.refundable ?
                                            ` ${cancelPenalty.chargeType === 'AMOUNT' ? '₹' : ''}${cancelPenalty.cancellationCharge}${cancelPenalty.chargeType === 'PERCENTAGE' ? '%' : ''} Charged`
                                            : ' Non Refundable'}
                                    </Text>
                                </View>
                            ))}
                        </View>

                        <View style={styles.section}>
                            <Text style={styles.sectionHeader}>Cost Breakdown</Text>
                            {reservationDetails.seats.map((seat, index) => (
                                <View key={index} style={styles.costContainer}>
                                    <Text style={styles.detailText}>Seat {seat.seatNo}: (Base ₹{seat.seatRate.discountedBaseFare} + Tax ₹{seat.seatRate.totalTax}) ₹{seat.seatRate.totalCost}</Text>
                                </View>
                            ))}
                        </View>

                        <View style={styles.buttonContainer}>
                            <TouchableOpacity onPress={manageReservation} style={styles.manageButton} >
                                <Text style={styles.buttonText}>Manage Reservation</Text>
                            </TouchableOpacity>
                            <TouchableOpacity style={styles.manageButton} onPress={handlePrint}>
                    <Text style={styles.buttonText}>Print</Text>
                </TouchableOpacity>
                        </View>
                    </View>

                )}
            </ScrollView>
        </ScreenLayout>
    );
}

const styles = StyleSheet.create({
    container: {
        flexGrow: 1,
        padding: 20,
        backgroundColor: 'white',
        alignItems: 'center',
        width: '90%',
        alignSelf: 'center',
    },
    title: {
        fontSize: 24,
        fontWeight: 'bold',
        marginBottom: 20,
        textAlign: 'center',
    },
    input: {
        height: 40,
        width: '100%',
        borderColor: 'gray',
        borderWidth: 1,
        marginBottom: 20,
        paddingHorizontal: 10,
        borderRadius: 5,
    },
    buttonContainer: {
        flexDirection: 'row',
        marginTop: 10,
        marginBottom: 20,
        justifyContent: 'space-between'
    },
    detailsContainer: {
        marginTop: 20,
        width: '100%',
    },
    detailsTitle: {
        fontSize: 20,
        fontWeight: 'bold',
        marginBottom: 10,
        textAlign: 'center',
    },
    detailsText: {
        marginBottom: 10,
        fontSize: 16,
    },
    section: {
        marginBottom: 20,
        padding: 10,
        backgroundColor: '#f9f9f9',
        borderRadius: 5,
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.1,
        shadowRadius: 5,
        elevation: 2,
    },
    sectionHeader: {
        fontSize: 18,
        fontWeight: 'bold',
        marginBottom: 10,
        borderBottomWidth: 1,
        borderBottomColor: 'lightgray',
        paddingBottom: 5,
    },
    subHeader: {
        fontSize: 16,
        fontWeight: 'bold',
        marginTop: 10,
        // fontFamily: 'Aldrich',
    },
    pointContainer: {
        marginHorizontal: 20,
        marginBottom: 10,
    },
    detailText: {
        fontSize: 16,
        marginBottom: 5,
    },
    passengersContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
    passengerContainer: {
        marginBottom: 10,
        marginHorizontal: 5,
    },
    passengerHeader: {
        fontSize: 16,
        fontWeight: 'bold',
        marginBottom: 5,
    },
    costContainer: {
        marginBottom: 5,
    },
    penaltyContainer: {
        marginBottom: 10,
    },
    retrieveButton: {
        paddingVertical: 10,
        paddingHorizontal: 20,
        borderRadius: 5,
        alignItems: 'center',
        marginVertical: 10,
        backgroundColor: '#5EBC67',
    },
    manageButton: {
        paddingVertical: 10,
        paddingHorizontal: 20,
        borderRadius: 5,
        alignItems: 'center',
        marginVertical: 10,
        backgroundColor: 'red',
    },
    buttonText: {
        fontSize: 16,
        color: 'white',
    },
    background: {
        flex: 1,
        resizeMode: 'cover',
        justifyContent: 'center',
    },
    rowContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: '100%',
    },
});

export default ViewReservation;
