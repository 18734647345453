import React, { useState, useEffect, useCallback } from 'react';
import { View, Text, TouchableOpacity, Button, Platform, StyleSheet, ScrollView, CheckBox, ActivityIndicator, Alert } from 'react-native';
import axios from 'axios';
import PrivacyPolicyModal from '../modals/PrivacyPolicyModal';
import TermsAndConditionsModal from '../modals/TermsAndConditionsModal';
import CancellationRefundPolicyModal from '../modals/CancellationRefundPolicyModal';
import { format } from 'date-fns';
import { useUser } from './UserContext';
import Constants from 'expo-constants';

const API_URL = Constants.expoConfig.extra.apiUrl;
const API_TOKEN = Constants.expoConfig.extra.apiToken;

const BookBookingReview = ({ initialBookSummary, handlePaymentSuccess, onEdit, handleBookSummaryChange }) => {
    const [bookSummary, setBookSummary] = useState(initialBookSummary);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState('Failed to confirm seat. Please try again');
    const { user } = useUser();
    const [loading, setLoading] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [termsModalVisible, setTermsModalVisible] = useState(false);
    const [privacyModalVisible, setPrivacyModalVisible] = useState(false);
    const [CancellationRefundPolicyModalVisible, setCancellationRefundPolicyModalVisible] = useState(false);

    useEffect(() => {
        setBookSummary(initialBookSummary);
    }, [initialBookSummary]);

    useEffect(() => {
        if (Platform.OS === 'web') {
            const script = document.createElement('script');
            script.src = 'https://checkout.razorpay.com/v1/checkout.js';
            script.async = true;
            script.onload = () => {
                console.log('Razorpay script loaded');
            };
            script.onerror = () => {
                console.error('Failed to load Razorpay script');
            };
            document.body.appendChild(script);
        }
    }, []);

    const convertToPaise = (amountInRupees) => {
        return Math.round(parseFloat(amountInRupees) * 100);
    }

    const convertToRupees = (amountInPaise) => {
        return (amountInPaise / 100).toFixed(2);
    }

    const formatTime = (timeArray) => {
        const arrivalTime = new Date(timeArray[0], timeArray[1] - 1, timeArray[2], timeArray[3], timeArray[4]);
        return arrivalTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    };

    const openTermsModal = () => setTermsModalVisible(true);
    const openPrivacyModal = () => setPrivacyModalVisible(true);
    const openCancellationRefundPolicyModal = () => setCancellationRefundPolicyModalVisible(true);

    const closeTermsModal = () => setTermsModalVisible(false);
    const closePrivacyModal = () => setPrivacyModalVisible(false);
    const closeCancellationRefundPolicyModal = () => setCancellationRefundPolicyModalVisible(false);

    const convertTo12HourFormat = (dateTime) => {
        const date = new Date(dateTime.replace(' ', 'T'));

        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();

        let hours = date.getHours();
        const minutes = date.getMinutes();
        const ampm = hours >= 12 ? 'PM' : 'AM';

        hours = hours % 12;
        hours = hours ? hours : 12;
        const minutesStr = minutes < 10 ? '0' + minutes : minutes;

        const formattedDate = `${day}/${month}/${year}`;
        const formattedTime = `${hours}:${minutesStr} ${ampm}`;

        return `${formattedDate} ${formattedTime}`;
    };

    const handleSeatBlock = async () => {
        setLoading(true);
        setShowErrorMessage(false);
        try {
            const formattedDate = format(bookSummary.travelDate, 'yyyy-MM-dd');
            const requestBody = {
                contactNumber: bookSummary.contactPhone,
                contactEmail: bookSummary.contactEmail,
                tripIdentifier: bookSummary.busAvailability.tripIdentifier,
                origin: bookSummary.origin,
                destination: bookSummary.destination,
                travelDate: formattedDate,
                boardingPoint: bookSummary.boardingPoint,
                droppingPoint: bookSummary.dropOffPoint,
                seats: bookSummary.seats,
                passengersInfo: bookSummary.passengers,
                busAvailability: bookSummary.busAvailability
            };

            const response = await axios.post(API_URL + '/block-seats', requestBody, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-token': API_TOKEN
                }
            });

            console.log(response.data);

            const updatedBookSummary = { 
                ...bookSummary, 
                confirmationCode: response.data.transactionPNR, 
                ticketCode: response.data.ticketCode, 
                orderId: response.data.orderId, 
                orderStatus: response.data.orderStatus, 
                totalCost: convertToRupees(response.data.totalCost) 
            };
            setBookSummary(updatedBookSummary);
            handleBookSummaryChange(updatedBookSummary);

            handlePayment(updatedBookSummary);

        } catch (error) {
            console.error(error);
            setErrorMessage('Failed to confirm seat. Please try again');
            setShowErrorMessage(true);
        } finally {
            setLoading(false);
        }
    };

    const handleSuccess = useCallback(async (response, currentBookSummary) => {
        const paymentId = response.razorpay_payment_id;
        setLoading(true);
        try {
            const formattedDate = format(currentBookSummary.travelDate, 'yyyy-MM-dd');
            const requestBody = {
                transactionId: paymentId,
                transactionPNR: currentBookSummary.confirmationCode,
                ticketCode: currentBookSummary.ticketCode,
                contactNumber: currentBookSummary.contactPhone,
                contactEmail: currentBookSummary.contactEmail,
                tripIdentifier: currentBookSummary.busAvailability.tripIdentifier,
                origin: currentBookSummary.origin,
                destination: currentBookSummary.destination,
                travelDate: formattedDate,
                boardingPoint: currentBookSummary.boardingPoint,
                droppingPoint: currentBookSummary.dropOffPoint,
                seats: currentBookSummary.seats,
                passengersInfo: currentBookSummary.passengers,
                busAvailability: currentBookSummary.busAvailability,
                userId: user ? user.userId : '',
                pos: {
                    type: 'WEB'
                }
            };

            console.log('Request Body:', requestBody);

            const response = await axios.post(API_URL + '/confirm-seats', requestBody, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-token': API_TOKEN
                }
            });

            console.log('Response Status:', response.status);
            console.log('Response Data:', response.data);

            const updatedBookSummary = {
                ...currentBookSummary,
                confirmationCode: response.data.confirmationCode,
                operatorPNR: response.data.operatorPNR,
                paymentId: paymentId
            };
            setBookSummary(updatedBookSummary);
            handleBookSummaryChange(updatedBookSummary);

            handlePaymentSuccess(response);
        } catch (error) {
            console.error('Error:', error);
            setShowErrorMessage(true);
            setErrorMessage('Failed to save confirmation. Please contact our customer support for refund, if payment was processed');

            if (error.response) {
                console.error('Error Response Data:', error.response.data);
            }
        } finally {
            setLoading(false);
        }
    }, [user, handleBookSummaryChange, handlePaymentSuccess]);

    const handlePayment = useCallback((currentBookSummary) => {
        const options = {
            key: Constants.expoConfig.extra.razorPayKey,
            amount: convertToPaise(currentBookSummary.totalCost),
            currency: 'INR',
            name: 'BYGBUS',
            description: 'Ticket Booking',
            image: 'https://bygbus.com/static/media/bygbus-high-resolution-logo-cropped.a987a185c40e2809bc64.png',
            order_id: currentBookSummary.orderId,
            handler: (response) => handleSuccess(response, currentBookSummary),
            prefill: {
                email: currentBookSummary.contactEmail,
                contact: currentBookSummary.contactPhone
            },
            notes: {
                address: 'BYGBUS Office'
            },
            theme: {
                color: '#5EBC67'
            }
        };

        if (Platform.OS === 'web') {
            if (window.Razorpay) {
                const rzp1 = new window.Razorpay(options);
                rzp1.on('payment.failed', function (response) {
                    Alert.alert('Payment Failure', response.error.description);
                });
                rzp1.open();
            } else {
                Alert.alert('Error', 'Razorpay SDK not loaded');
            }
        } else {
            Alert.alert('Web only feature', 'Payment gateway is available only on web.');
        }
    }, [handleSuccess]);

    const renderPolicyLinks = () => (
        <View style={styles.policyContainer}>
            <TouchableOpacity onPress={openTermsModal}>
                <Text style={styles.policyLink}>Terms and Conditions</Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={openPrivacyModal}>
                <Text style={styles.policyLink}>Privacy Policy</Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={openCancellationRefundPolicyModal}>
                <Text style={styles.policyLink}>Cancellation Refund Policy</Text>
            </TouchableOpacity>
        </View>
    );

    return (
        <ScrollView contentContainerStyle={styles.container}>
            <View style={styles.reviewContainer}>
                <Text style={styles.headerText}>Booking Review</Text>
                {showErrorMessage && (
                    <View style={styles.errorContainer}>
                        <Text style={styles.errorText}>
                            {errorMessage}
                        </Text>
                    </View>
                )}

                <View style={styles.section}>
                    <Text style={styles.sectionHeader}>Travel/Operator</Text>
                    <Text style={styles.detailText}>From City: {bookSummary.origin}</Text>
                    <Text style={styles.detailText}>To City: {bookSummary.destination}</Text>
                    <Text style={styles.detailText}>On: {bookSummary.travelDate.toDateString()}</Text>
                    <Text style={styles.detailText}>Operator: {bookSummary.busAvailability.operator} ({bookSummary.busAvailability.bus.name})</Text>
                    <Text style={styles.detailText}>Departure: {formatTime(bookSummary.busAvailability.departureTime)}</Text>
                    <Text style={styles.detailText}>Arrival: {formatTime(bookSummary.busAvailability.arrivalTime)}</Text>
                </View>

                <View style={styles.section}>
                    <Text style={styles.sectionHeader}>Contact Details</Text>
                    <Text style={styles.detailText}>Email: {bookSummary.contactEmail}</Text>
                    <Text style={styles.detailText}>Phone: {bookSummary.contactPhone}</Text>
                </View>

                <View style={styles.section}>
                    <Text style={styles.sectionHeader}>Passenger Details</Text>
                    <View style={styles.passengersContainer}>
                        {bookSummary.passengers.map((passenger, index) => (
                            <View key={index} style={styles.passengerContainer}>
                                <Text style={styles.passengerHeader}>Passenger {index + 1}</Text>
                                <Text style={styles.detailText}>Seat: {passenger.seat.seatNo}</Text>
                                <Text style={styles.detailText}>First Name: {passenger.firstName}</Text>
                                <Text style={styles.detailText}>Last Name: {passenger.lastName}</Text>
                                <Text style={styles.detailText}>Age: {passenger.age}</Text>
                                <Text style={styles.detailText}>Gender: {passenger.gender}</Text>
                            </View>
                        ))}
                    </View>
                </View>

                <View style={styles.section}>
                    <Text style={styles.sectionHeader}>Boarding Point</Text>
                    <Text style={styles.detailText}>Point Name: {bookSummary.boardingPoint.pointName}</Text>
                    <Text style={styles.detailText}>Landmark: {bookSummary.boardingPoint.landMark}</Text>
                    <Text style={styles.detailText}>
                        Arrival Time: {formatTime(bookSummary.boardingPoint.arrivalTime)}
                    </Text>
                </View>

                <View style={styles.section}>
                    <Text style={styles.sectionHeader}>Drop-Off Point</Text>
                    <Text style={styles.detailText}>Point Name: {bookSummary.dropOffPoint.pointName}</Text>
                    <Text style={styles.detailText}>Landmark: {bookSummary.dropOffPoint.landMark}</Text>
                    <Text style={styles.detailText}>
                        Arrival Time: {formatTime(bookSummary.dropOffPoint.arrivalTime)}
                    </Text>
                </View>

                <View style={styles.section}>
                    <Text style={styles.sectionHeader}>Cancellation Policy</Text>
                    {bookSummary.busAvailability.cancellationPolicy && bookSummary.busAvailability.cancellationPolicy.cancellationPenalty.map((cancelPenalty, index) => (
                        <View key={index} style={styles.penaltyContainer}>
                            <Text style={styles.detailText}>
                                Between {cancelPenalty.from ? convertTo12HourFormat(cancelPenalty.from) : 'Departure'} and {cancelPenalty.to ? convertTo12HourFormat(cancelPenalty.to) : 'Booked Date/time'}: {cancelPenalty.refundable ?
                                    `${cancelPenalty.chargeType === 'AMOUNT' ? '₹' : ''} ${cancelPenalty.cancellationCharge} ${cancelPenalty.chargeType === 'PERCENTAGE' ? '%' : ''} Charged`
                                    : 'Non Refundable'}
                            </Text>
                        </View>
                    ))}
                </View>

                <View style={styles.section}>
                    <Text style={styles.sectionHeader}>Cost Breakdown</Text>
                    {bookSummary.seats.map((seat, index) => (
                        <View key={index} style={styles.costContainer}>
                            <Text style={styles.detailText}>Seat {seat.seatNo}: ₹{seat.seatRate.totalCost} ( Base ₹ {seat.seatRate.baseFare.toFixed(2)} + Tax ₹ {seat.seatRate.totalTax.toFixed(2)})</Text>
                        </View>
                    ))}
                    <View style={styles.totalCostContainer}>
                        <Text style={styles.totalCostText}>Total Cost: ₹{bookSummary.totalCost}</Text>
                    </View>
                </View>

                <View style={styles.section}>
                    <View style={styles.reviewCheckBox}>
                        <CheckBox value={isChecked} onValueChange={setIsChecked} />
                        <Text style={styles.termsText}>I agree to the</Text>
                    </View>
                    {renderPolicyLinks()}
                </View>

                <View style={styles.buttonContainer}>
                    <TouchableOpacity style={styles.editButton} onPress={onEdit}>
                        <Text style={styles.buttonText}>Edit Booking</Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                        style={[styles.confirmButton, !isChecked && styles.disabledButton]}
                        onPress={handleSeatBlock}
                        disabled={!isChecked}
                    >
                        {loading ? (
                            <ActivityIndicator size="small" color="#fff" />
                        ) : (
                            <Text style={styles.buttonText}>Pay ₹ {bookSummary.totalCost}</Text>
                        )}
                    </TouchableOpacity>
                </View>
            </View>

            <TermsAndConditionsModal isVisible={termsModalVisible} closeModal={closeTermsModal} />
            <PrivacyPolicyModal isVisible={privacyModalVisible} closeModal={closePrivacyModal} />
            <CancellationRefundPolicyModal isVisible={CancellationRefundPolicyModalVisible} closeModal={closeCancellationRefundPolicyModal} />
        </ScrollView>
    );
};

const styles = StyleSheet.create({
    container: {
        flexGrow: 1,
        padding: 20,
    },
    reviewContainer: {
        backgroundColor: '#fff',
        borderRadius: 10,
        padding: 20,
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.2,
        shadowRadius: 5,
        elevation: 3,
    },
    headerText: {
        fontSize: 24,
        fontWeight: 'bold',
        marginBottom: 20,
        textAlign: 'center',
    },
    reviewCheckBox: {
        flexDirection: 'row'
    },
    section: {
        marginBottom: 20,
    },
    sectionHeader: {
        fontSize: 18,
        fontWeight: 'bold',
        marginBottom: 10,
        borderBottomWidth: 1,
        borderBottomColor: '#ddd',
        paddingBottom: 5,
    },
    detailText: {
        fontSize: 16,
        marginBottom: 5,
    },
    passengersContainer: {
        marginTop: 10,
    },
    passengerContainer: {
        marginBottom: 15,
        padding: 10,
        borderRadius: 5,
        backgroundColor: '#f0f0f0',
    },
    passengerHeader: {
        fontSize: 16,
        fontWeight: 'bold',
        marginBottom: 5,
    },
    penaltyContainer: {
        marginBottom: 5,
    },
    costContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 5,
    },
    totalCostContainer: {
        borderTopWidth: 1,
        borderTopColor: '#ddd',
        paddingTop: 10,
        marginTop: 10,
    },
    totalCostText: {
        fontSize: 18,
        fontWeight: 'bold',
        color: '#333',
    },
    policyContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginVertical: 10,
    },
    policyLink: {
        color: '#1e90ff',
        textDecorationLine: 'underline',
        marginHorizontal: 5,
    },
    termsText: {
        fontSize: 16,
        marginHorizontal: 5,
    },
    buttonContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 20,
    },
    editButton: {
        backgroundColor: '#ffa500',
        padding: 15,
        borderRadius: 5,
    },
    confirmButton: {
        backgroundColor: '#28a745',
        padding: 15,
        borderRadius: 5,
    },
    disabledButton: {
        backgroundColor: '#ccc',
    },
    buttonText: {
        color: '#fff',
        fontSize: 16,
        fontWeight: 'bold',
        textAlign: 'center',
    },
    payButton: {
        backgroundColor: '#5EBC67',
    },
    errorContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        padding: 20,
    },
    errorText: {
        fontSize: 18,
        fontWeight: 'bold',
        color: 'red',
        textAlign: 'center',
    },
});

export default BookBookingReview;