import React, { useState, useEffect } from 'react';
import { ScrollView, View, StyleSheet, Text, TextInput, TouchableOpacity, Platform, Alert } from 'react-native';
import { WebView as NativeWebView } from 'react-native-webview';
import WebWebView from 'react-native-web-webview';
import Constants from 'expo-constants';
import ScreenLayout from './ScreenLayout';
import axios from 'axios';
import { useRoute } from '@react-navigation/native';

const API_URL = Constants.expoConfig.extra.apiUrl;
const API_TOKEN = Constants.expoConfig.extra.apiToken;

const WebView = Platform.OS === 'web' ? WebWebView : NativeWebView;

const TrackBus = ({ navigation }) => {
    const route = useRoute();
    const { pnr: initialPnr } = route.params || {};
    const [pnr, setPnr] = useState(initialPnr || '');
    const [reservationDetails, setReservationDetails] = useState(null);
    const [trackUrl, setTrackUrl] = useState('');
    const [showTrackView, setShowTrackView] = useState(false);

    useEffect(() => {
        if (initialPnr) {
            fetchReservationDetails();
        }
    }, [initialPnr]);

    const fetchReservationDetails = async () => {
        try {
            const response = await axios.get(`${API_URL}/bus-reservation/${pnr}`, {
                headers: {
                    'x-api-token': API_TOKEN
                }
            });
            const payload = response.data;

            setReservationDetails(payload);
            setTrackUrl('https://m.trackbus.in/?p=' + payload.operatorPNR + '&z=r3bits&zone=r3bits&n=' + payload.busAvailability.operatorCode);
            setShowTrackView(true);
        } catch (error) {
            console.error('Error fetching reservation:', error);
            Alert.alert('Error', 'Failed to fetch reservation details. Please try again.');
        }
    };

    return (
        <ScreenLayout navigation={navigation}>
            <ScrollView contentContainerStyle={styles.scrollContainer}>
                <View style={styles.container}>
                    <Text style={styles.title}>Track Bus</Text>
                    <TextInput
                        style={styles.input}
                        placeholder="Enter PNR"
                        value={pnr}
                        onChangeText={setPnr}
                    />

                    <View style={styles.buttonContainer}>
                        <TouchableOpacity onPress={fetchReservationDetails} style={styles.button}>
                            <Text style={styles.buttonText}>Track Bus</Text>
                        </TouchableOpacity>
                    </View>

                    {showTrackView && (
                        <View style={styles.webviewContainer}>
                            <WebView
                                source={{ uri: trackUrl }}
                                style={styles.webview}
                            />
                        </View>
                    )}
                </View>
            </ScrollView>
        </ScreenLayout>
    );
};

const styles = StyleSheet.create({
    scrollContainer: {
        flexGrow: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    container: {
        width: '100%',
        padding: 20,
    },
    title: {
        fontSize: 24,
        fontWeight: 'bold',
        marginBottom: 20,
        textAlign: 'center',
    },
    input: {
        height: 40,
        borderColor: 'gray',
        borderWidth: 1,
        marginBottom: 20,
        paddingHorizontal: 10,
        borderRadius: 5,
    },
    buttonContainer: {
        marginBottom: 20,
        alignItems: 'center',
    },
    button: {
        backgroundColor: '#007BFF',
        paddingVertical: 10,
        paddingHorizontal: 20,
        borderRadius: 5,
    },
    buttonText: {
        fontSize: 16,
        color: 'white',
    },
    webviewContainer: {
        width: '100%',
        height: 800,
        marginTop: 20,
    },
    webview: {
        flex: 1,
    },
});

export default TrackBus;
